import { Map, View } from 'ol';
import TileLayer from 'ol/layer/Tile';
import OSM from 'ol/source/OSM';
import Group from 'ol/layer/Group';
import Stroke from 'ol/style/Stroke';
import GeoJSON from 'ol/format/GeoJSON';
import Style from 'ol/style/Style';
import XYZ from 'ol/source/XYZ';
import Vector from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import ImageLayer from 'ol/layer/Image';
import ImageWMS from 'ol/source/ImageWMS';
import TileWMS from 'ol/source/TileWMS';
import Text from 'ol/style/Text';
import Fill from 'ol/style/Fill';



////////// styles
const CountriesStyle = new Style({
    fill: new Stroke({
        color: "transparent",
    }),
    stroke: new Stroke({
        color: "black",
        width: 2,
    }),
});

const CountiesStyle = new Style({
    fill: new Stroke({
        color: "transparent",
    }),
    stroke: new Stroke({
        color: "#6c757d",
        width: 2,
    }),
});

const DistricsStyle = new Style({
    fill: new Stroke({
        color: "transparent",
    }),
    stroke: new Stroke({
        color: "#6c757d",
        width: 1,
    }),
});



var RasterLayer = new Group({
    title: "Indicators",
    layers: [],
});




const Overlays = new Group({
    title: "Boundaries",
    layers: [
        new Vector({
            title: "Districts",
            info: "Districts as smallest available administrative unit.",
            visible: false,
            source: new VectorSource({
                url: "https://landsurf.geo.uni-halle.de/geoserver/landsurf/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=landsurf%3Aafr_admin_2_wa&outputFormat=application%2Fjson",
                format: new GeoJSON(),
            }),
            style: DistricsStyle,
        }),
        new Vector({
            title: "Counties",
            info: "Counties inside the countries.",
            visible: false,
            source: new VectorSource({
                url: "https://landsurf.geo.uni-halle.de/geoserver/landsurf/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=landsurf%3Aafr_admin_1_wa&outputFormat=application%2Fjson",
                format: new GeoJSON(),
            }),
            style: CountiesStyle,
        }),
        new Vector({
            title: "Countries",
            info: "West African countries.",
            visible: true,
            source: new VectorSource({
                url: "https://landsurf.geo.uni-halle.de/geoserver/landsurf/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=landsurf%3Aafr_admin_0_wa&outputFormat=application%2Fjson",
                format: new GeoJSON(),
            }),
            style: CountriesStyle,
        }),
    ],
});



const Basemaps = new Group({
    title: "Base Maps",
    layers: [
        // ESRI Satellite Map
        new TileLayer({
            title: "ESRI Satellite",
            info: "ESRI Satellite Basemap, available at https://www.arcgis.com/home/item.html?id=10df2279f9684e4a9f6a7f08febac2a9.",
            baseLayer: true,
            visible: false,
            source: new XYZ({
                attributions: [
                    "Powered by Esri",
                    "Source: Esri, DigitalGlobe, GeoEye, Earthstar Geographics, CNES/Airbus DS, USDA, USGS, AeroGRID, IGN, and the GIS User Community",
                ],
                attributionsCollapsible: false,
                url: "https://services.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}",
                maxZoom: 23,
                crossOrigin: "",
            }),
        }),
        // ESRI Basemap
        new TileLayer({
            title: "ESRI Topographic Basemap",
            info: "ESRI Topographic Basemap, available at https://www.arcgis.com/home/item.html?id=7dc6cea0b1764a1f9af2e679f642f0f5.",
            baseLayer: true,
            visible: false,
            source: new XYZ({
                attributions:
                    'Tiles © <a href="https://services.arcgisonline.com/ArcGIS/' +
                    'rest/services/World_Topo_Map/MapServer">ArcGIS</a>',
                url:
                    "https://server.arcgisonline.com/ArcGIS/rest/services/" +
                    "World_Topo_Map/MapServer/tile/{z}/{y}/{x}",
            }),
            crossOrigin: "",
        }),
        // OSM
        new TileLayer({
            title: "OSM",
            info: "OpenStreetMap, available at https://www.openstreetmap.org/#map=6/12.030/2.249.",
            baseLayer: true,
            visible: true,
            source: new OSM(),
            crossOrigin: "",
        }),
    ],
});


var Cities = new ImageLayer({
    title: "Cities",
    visible: false,
    source: new ImageWMS({
        url: "https://landsurf.geo.uni-halle.de/geoserver/landsurf/wms",
        params: {
            VERSION: "1.1.1",
            STYLES: "",
            LAYERS: "landsurf:cities_v2",
            exceptions: "application/vnd.ogc.se_inimage",
            VENDORPARAMS: 'label:on'
        },
        ratio: 1,
        serverType: "geoserver",
        crossOrigin: "",
    }),
})


const crs = "EPSG:4326";

const view = new View({
    projection: crs,
    center: [-0.702519, 15.165076], //Coordinates of center
    zoom: 5.8, //zoom level of map
});

var map = new Map({
    target: "map",
    layers: [
        Basemaps,
        RasterLayer,
        Overlays,
        Cities
    ],
    view: view,
    controls: [],
});


export { Overlays, RasterLayer, map, view, CountriesStyle, CountiesStyle, DistricsStyle, Cities };