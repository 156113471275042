import TileLayer from 'ol/layer/Tile';
import TileWMS from 'ol/source/TileWMS';
import Group from 'ol/layer/Group';
import Overlay from 'ol/Overlay';
import { toStringHDMS } from 'ol/coordinate';
import { Map, View } from 'ol';
import bootstrap from 'bootstrap'
import $ from "jquery";

// layerswitcher
import LayerSwitcher from "ol-ext/control/LayerSwitcher"


// custom functions, variables
import { RasterLayer, Cities, map } from './js/layers';

// print layout
import { jsPDF } from "jspdf";

// date range slider librarys
import * as noUiSlider from 'nouislider';
import 'nouislider/dist/nouislider.css';

import wNumb from 'wnumb';






///////////////////////////// EXTERNAL JSON ///////////////////////////////
// load external json with units for legend display via ajax
var legend_units = (function () {
  var json = null;
  $.ajax({
    async: false,
    global: false,
    url: "./json/legend_units.json",
    dataType: "json",
    success: function (data) {
      json = data;
    },
  });
  return json;
})();


var crops_translate = (function () {
  var json = null;
  $.ajax({
    async: false,
    global: false,
    url: "./json/crops.json",
    dataType: "json",
    success: function (data) {
      json = data;
    },
  });
  return json;
})();


// load external json with long names for indicator abbrevations
var indicator_json = (function () {
  var json = null;
  $.ajax({
    async: false,
    global: false,
    url: "./json/indicators.json",
    dataType: "json",
    success: function (data) {
      json = data;
    },
  });
  return json;
})();

var indicator_en = (function () {
  var json = null;
  $.ajax({
    async: false,
    global: false,
    url: "./json/indicators_en.json",
    dataType: "json",
    success: function (data) {
      json = data;
    },
  });
  return json;
})();

var indicator_fr = (function () {
  var json = null;
  $.ajax({
    async: false,
    global: false,
    url: "./json/indicators_fr.json",
    dataType: "json",
    success: function (data) {
      json = data;
    },
  });
  return json;
})();

var indicator_class_json = (function () {
  var json = null;
  $.ajax({
    async: false,
    global: false,
    url: "./json/indicator_class.json",
    dataType: "json",
    success: function (data) {
      json = data;
    },
  });
  return json;
})();

//////////////////////////////////////////////////////////////////////////


////////////// Object that will hold the multiple instances of layers that will be loaded
var Layers = {};


function resetRasterLayer() {
  RasterLayer.getLayers().clear();
}


function loadScript(url, id, callback) {
  let script = document.createElement('script');
  script.type = 'module';
  script.src = url;
  script.id = id;

  // Optional: only run the callback once the script loads
  script.onload = () => {
    if (callback) {
      callback();
    }
  };

  document.head.appendChild(script);
}




// cities at points with labels should only be visible when 
// rasterlayer is visible, because otherwise it might intervene with 
// the basemap (also add cities when using imagery basemap)
function setCityVisibility() {

  var rasterVis = false;

  RasterLayer.getLayers().forEach(function (layer) {
    //console.log(layer);

    // if layer and layergroup are visible, then raster is visible
    // if layergroup is not visible but layer is visible -> raster is also not visible
    if (layer.values_.visible === true && RasterLayer.values_.visible === true) {
      rasterVis = true;
    }
  })

  if (rasterVis === true) {
    Cities.setVisible(true);
  } else {
    Cities.setVisible(false);
  }

}


// CITIES LAYER
// when last layer is removed from the map, cities also need to set invisible
document.addEventListener('click', function (event) {

  // if trash button is clicked
  if (event.target.classList.contains('layerTrash') || event.target.closest('.layerTrash')) {
    //console.log('TRASH BUTTON CLICKED');

    // if this was the last layer (where there was only one layer left in group)
    if (RasterLayer.getLayers().values_.length == 1) {
      //console.log("last layer");

      // set cities unvisible
      Cities.setVisible(false);

    }

  }

}, true); // Use capture phase (true)



// when a new layer is loaded, make every other layer invisible
// important beacause some rater layers (like ir) are not present at every pixel
// -> other layers behind would "peek through the holes"
function setLayersVisibility(activeLayer) {
  RasterLayer.getLayers().forEach(function (layer) {
    //console.log(layer);

    if (layer.values_.titleOrg != activeLayer.title) {
      layer.setVisible(false);
    }
  })
}


// loops Layers object and returns active / active layer
// 
function getActiveLayer(Layers) {
  for (let key in Layers) {
    if (Layers[key].active) {
      return Layers[key];
    }
  }
}

function getImgDimensions(url) {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = function () {
      resolve({ width: img.width, height: img.height });
    };
    img.onerror = function () {
      reject(new Error("Failed to load image"));
    };
    img.src = url;
  });
}

// function that changes the displayed chart analysis possibilities based
// on selected layer class (different chart types for climate, crop, season, drought)
function changeSelectionDisplay(indicator_class) {
  switch (indicator_class) {
    case "climate":
      //console.log('climate selected');
      //checkIndicatorType();
      $("#cropContainer, #cropStageContainer, #cropTypeContainer, #droughtTypeContainer, #seasonTypeContainer, #ndviMonthContainer, #selectNdviDateContainer").hide();
      $("#indicatorTypeContainer, #selectDateContainer").slideDown();
      break;
    case "drought":
      //console.log('drought selected');
      $("#cropContainer, #cropStageContainer, #cropTypeContainer, #seasonTypeContainer, #ndviMonthContainer, #selectNdviDateContainer").hide();
      $("#droughtTypeContainer, #indicatorTypeContainer, #selectDateContainer").slideDown();
      break;
    case "crop":
      //console.log('crop selected');
      $('#seasonTypeContainer, #droughtTypeContainer, #ndviMonthContainer, #selectNdviDateContainer').hide();
      $("#cropContainer, #cropStageContainer, #cropTypeContainer, #indicatorTypeContainer, #selectDateContainer").slideDown();
      break;
    case "season":
      //console.log('season selected');
      $("#cropContainer, #cropStageContainer, #cropTypeContainer, #droughtTypeContainer, #ndviMonthContainer, #selectNdviDateContainer").hide();
      $('#seasonTypeContainer, #indicatorTypeContainer, #selectDateContainer').slideDown();
      break;
    case "remote":
      //console.log('remote sensing selected');
      $("#cropContainer, #cropStageContainer, #cropTypeContainer, #seasonTypeContainer, #droughtTypeContainer, #indicatorTypeContainer, #typeRecentContainer, #selectDateContainer, #projectionModelContainer, #scenarioContainer, #typeProjectionContainer").hide();
      $('#ndviMonthContainer, #selectNdviDateContainer').slideDown();
      break;
  }
}


function changeChartDisplay(indicator_class, indicator_type) {
  //console.log("changing chart display");
  switch (indicator_class) {
    case "climate":
      //console.log('climate selected - changing charts');
      if (indicator_type == 'recent') {
        $('#cropChartsSection, #droughtChartsSection, #rainySeasonChartsSection, #compareChartButton, #ndviChartsSection').hide();
        $('#cardSectionCharts, #timeseriesChartButton').show();
      } else if (indicator_type == 'projection') {
        $('#cropChartsSection, #droughtChartsSection, #rainySeasonChartsSection, #ndviChartsSection').hide();
        $('#cardSectionCharts, #timeseriesChartButton, #compareChartButton').show();
      }
      break;
    case "drought":
      //console.log('drought selected - changing charts');
      $('#rainySeasonChartsSection, #cropChartsSection, #cardSectionCharts, #ndviChartsSection').hide();
      $('#droughtChartsSection').show();
      break;
    case "crop":
      //console.log('crop selected - changing charts');
      $('#droughtChartsSection, #cardSectionCharts, #rainySeasonChartsSection, #ndviChartsSection').hide();
      $('#cropChartsSection').show();
      break;
    case "season":
      //console.log('season selected - changing charts');
      $('#droughtChartsSection, #cardSectionCharts, #ndviChartsSection, #cropChartsSection').hide();
      $('#rainySeasonChartsSection').show();
      break;
    case "remote":
      //console.log("remote selected - changing charts");
      $('#droughtChartsSection, #cardSectionCharts, #rainySeasonChartsSection, #cropChartsSection').hide();
      $('#ndviChartsSection').show();
  }

}





//#######################################################################################################################################################//
//////////////////////////////////////////////////////////////////////               ///////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////// LAYER CLASSES ///////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////               //////////////////////////////////////////////////////////////////////
//#######################################################################################################################################################//

class Layer {
  constructor(indicator, year, indicatorType, typeRecent = "", typeProjection = "", model = "", scenario = "", active = false, addSignal = false) {
    this.indicator = indicator;
    this.indicator_long_en = indicator_en[this.indicator];
    this.indicator_long_fr = indicator_fr[this.indicator];
    this.year = year;
    this.type = indicatorType;
    this.typeRecent = typeRecent;
    this.typeRecent_long = legend_units[typeRecent];
    this.typeProjection = typeProjection;
    this.model = model;
    this.scenario = scenario;
    // true, if it is the active layer == topmost visible layer
    // that is used for analysis
    this.active = active;
    // if signal should be added to trend layer
    this.addSignal = addSignal;


    // initializing methods
    this.setTypeProjectionLong();
    this.generateID(10);



  }

  //////////////////////////////// CLASS FUNCTIONS ///////////////////////////////////
  /////////
  // function that sets this layer as active = true and all other layers ind Layer object as active = false
  setActive(active, Layers) {
    // if active = true, set all other layers false
    if (active) {
      for (let key in Layers) {
        if (Layers[key] !== this) {
          Layers[key].active = false;
        }
      }
    }
    // last, set this layer
    this.active = active;
  }

  //////////
  // create typeProjectionLong for display e.g. in Legend
  setTypeProjectionLong() {
    switch (this.type) {
      case 'recent':
        this.typeProjectionLong_en = 'absolute values';
        this.typeProjectionLong_fr = 'valeurs absolues';
        break;
      case 'projection':
        switch (this.typeProjection) {
          case 'abs':
            this.typeProjectionLong_en = 'absolute values';
            this.typeProjectionLong_fr = 'valeurs absolues';
            break;
          case 'diff':
            this.typeProjectionLong_en = 'difference to reference period';
            this.typeProjectionLong_fr = 'différence avec la période de référence';
            break;
          case 'trend':
            this.typeProjectionLong_en = 'trend';
            this.typeProjectionLong_fr = 'tendance';
            break;
          case 'signal':
            this.typeProjectionLong_en = 'signal';
            this.typeProjectionLong_fr = 'signal';
            break;
        }
    }
  }

  /////////
  // generate a random alphanumeric id for every layer
  generateID(length) {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    this.id = result;
  }

  /////////
  // generate new wms tilelayer from geoserver
  getWMSLayer() {
    // new tile wms layer from geoserver
    var newLayer = new TileLayer({
      title: this.titleReadable,
      titleOrg: this.title,
      info: indicator_en[this.indicator] + ' - ' + this.typeProjectionLong_en + ': ' + this.titleReadable,
      visible: true,
      source: new TileWMS({
        url: "https://landsurf.geo.uni-halle.de/geoserver/landsurf/wms",
        params: {
          VERSION: "1.1.1",
          STYLES: "",
          LAYERS: "landsurf:" + this.titleNoYear,
          exceptions: "application/vnd.ogc.se_inimage",
          TIME: this.year,
          TILED: true
        },
        ratio: 1,
        serverType: "geoserver",
        crossOrigin: "",
      }),
    });

    var newLayerSource = newLayer.getSource();
    this.layerSource = newLayerSource;

    return newLayer;

  }

  /////////
  // get url for legend img from geoserver
  getLegendURL() {
    var layer = "landsurf:" + this.titleNoYear;
    return ("https://landsurf.geo.uni-halle.de/geoserver/wms?REQUEST=GetLegendGraphic&VERSION=1.0.0&FORMAT=image/png&WIDTH=20&HEIGHT=20&LAYER=" + layer);
  }

  /////////
  // get url for legend img from geoserver for signal layer that always gets displayed together with trend layer
  getLegendURLSignal() {
    var layer = "landsurf:" + this.titleNoYear.replace('trend', 'signal');
    return ("https://landsurf.geo.uni-halle.de/geoserver/wms?REQUEST=GetLegendGraphic&VERSION=1.0.0&FORMAT=image/png&WIDTH=2274&HEIGHT=1089&LAYER=" + layer);
  }


  /////////
  // add legend to card
  addLegend() {

    // legend for trend and signal are exceptions -> can be displayed together
    if (this.addSignal) {
      var appendstr =
        '<li><div id=legendImgDiv><p class="legend-title"><b>' +
        this.indicator +
        "<br>" +
        indicator_json[this.indicator] +
        "<br>" +
        this.typeProjectionLong_en +
        "<br>" +
        this.getUnit() +
        '<br></b></p><img class="legend-img" src="' +
        this.getLegendURL() +
        '" /></div></li>' +
        '<li><div id=legendImgDivSignal><p id=signalLegendTitle><b>Signal</b></p><img class="legend-img-signal" src="' +
        this.getLegendURLSignal() +
        '" /></div></li>';

      // get width and height of legend and save it
      this.legendWidth = $('.legend-img').width();
      this.legendHeight = $('.legend-img').height();

      // add to legend card
      $('.legend-list').empty();
      $(".legend-list").append(appendstr);

      // if anything else, standard legend, standard layer
    } else {
      // create html string
      var appendstr =
        '<li><div id=legendImgDiv><p class="legend-title"><b>' +
        this.indicator +
        "<br>" +
        indicator_json[this.indicator] +
        "<br>" +
        this.typeProjectionLong_en +
        "<br>" +
        this.getUnit() +
        '<br></b></p><img class="legend-img" src="' +
        this.getLegendURL() +
        '" /></div></li>';

      // get width and height of legend and save it
      this.legendWidth = $('.legend-img').width();
      this.legendHeight = $('.legend-img').height();

      // add to legend card
      $('.legend-list').empty();
      $(".legend-list").append(appendstr);
    }

  }

  /////////
  // get dimensions of the legend img, so the legend container and 
  // legend box for print map can be styled accordingly
  // legend is placed in display=none element -> cannot get width and height
  // -> have to load img async into hidden object, then get width and height
  getLegendImgDim() {
    return getImgDimensions(this.getLegendURL());
  }


  /////////
  // get unit for this layer
  getUnit() {
    var layer_unit = "";
    switch (this.typeProjection) {
      case 'trend':
        layer_unit = legend_units[this.indicator + "_trend"];
        break;

      default:
        layer_unit = legend_units[this.indicator];
        break;
    }

    return layer_unit;
  }

  /////////
  // get formatted Title string for charts
  getChartTitle(multi = false) {
    var chartTitle = [];
    var titleIndicator = this.indicator + ' - ' + this.indicator_long_en + ' ' + this.getUnit();

    var titleDefinition = this.typeProjectionLong_en + ' ' + this.typeRecent_long;

    if (this.type == 'recent') {
      titleDefinition = 'historic ' + this.typeProjectionLong_en + ' ' + this.typeRecent_long;
    } else if (this.type == 'projection') {
      if (multi) {
        titleDefinition = 'projected ' + this.typeProjectionLong_en + ', ' + legend_units[this.model];
      } else {
        titleDefinition = 'projected ' + this.typeProjectionLong_en + ', ' + legend_units[this.model] + ' ' + this.scenario;
      }
    }

    chartTitle[0] = titleIndicator;
    chartTitle[1] = titleDefinition;
    return chartTitle;
  }


  /////////
  // get formatted Title string for print layout
  getPrintMapTitle() {
    // title is different for every indicator class
    var result = [];
    var yearTitle = '';
    var indicatorType = '';
    var printMapTitle = '';
    var printMapTitle2 = '';
    var printMapTitle3 = '';

    // if projection, year must be changed to a timespan
    if (this.type == "projection") {
      var yearLower = Number(this.year) - 15;
      var yearUpper = Number(this.year) + 15;
      yearTitle = String(yearLower) + ' - ' + String(yearUpper);
      indicatorType = this.typeProjectionLong_en + ': ' + this.model + ' ' + this.scenario;
    } else {
      yearTitle = this.year;
      indicatorType = this.typeProjectionLong_en + ' ' + this.typeRecent;
    }

    if (this.class == 'crop') {
      //console.log("CROP MAP TITLE");

      printMapTitle = this.indicator + ' - ' + this.indicator_long_en + ' ' + this.getUnit();
      printMapTitle2 = this.crop + ' - ' + this.cropType + ' - ' + this.cropStage;
      printMapTitle3 = indicatorType + ' in ' + yearTitle;

    } else if (this.class == 'climate') {
      //console.log("CLIMATE MAP TITLE");

      printMapTitle = this.indicator + ' - ' + this.indicator_long_en + ' ' + this.getUnit();
      printMapTitle2 = indicatorType + ' in ' + yearTitle;

    } else if (this.class == 'drought') {
      //console.log("DROUGHT MAP TITLE");

      printMapTitle = this.indicator + ' - ' + this.indicator_long_en + ' ' + this.getUnit();
      printMapTitle2 = this.droughtTypeLong;
      printMapTitle3 = indicatorType + ' in ' + yearTitle;
    } else if (this.class == 'season') {
      //console.log("SEASON MAP TITLE");

      printMapTitle = this.indicator + ' - ' + this.indicator_long_en + ' ' + this.getUnit();
      printMapTitle2 = this.seasonTypeLong;
      printMapTitle3 = indicatorType + ' in ' + yearTitle;

    } else if (this.class == 'remote') {
      //console.log("REMOTE MAP TITLE");

      printMapTitle = this.indicator + ' - ' + this.indicator_long_en + ' ' + this.getUnit();
      printMapTitle2 = indicator_json[this.month] + ' ' + yearTitle;
      //printMapTitle3 = yearTitle;
    }

    result[0] = printMapTitle;
    result[1] = printMapTitle2;
    result[2] = printMapTitle3;

    //console.log(result);
    return result;
  }

}


////////////////////////// CLIMATE SUBCLASS
class ClimateLayer extends Layer {
  //// characteristics
  constructor(indicator, year, indicatorType, typeRecent, typeProjection, model, scenario) {
    super(indicator, year, indicatorType, typeRecent, typeProjection, model, scenario);
    this.class = "climate";

    this.getTitle();

  }


  getTitle() {
    switch (this.type) {
      case "recent":
        this.title = this.indicator + "_" + this.type + "_" + this.typeRecent + "_" + this.year;
        this.titleNoYear = this.indicator + "_" + this.type + "_" + this.typeRecent;
        this.titleReadable = this.indicator + " " + this.type + " " + this.typeRecent + " " + this.year;
        break;
      case "projection":
        this.title = this.indicator + "_" + this.type + "_" + this.typeProjection + "_" + this.model + "_" + this.scenario + "_" + this.year;
        this.titleNoYear = this.indicator + "_" + this.type + "_" + this.typeProjection + "_" + this.model + "_" + this.scenario;
        this.titleReadable = this.indicator + " " + this.type + " " + this.typeProjection + " " + this.model + " " + this.scenario + " " + this.year;
        break;
    }
  }
}

////////////////////////// DROUGHT SUBCLASS
class DroughtLayer extends Layer {
  //// characteristics
  constructor(indicator, year, indicatorType, typeRecent, typeProjection, model, scenario, droughtType) {
    super(indicator, year, indicatorType, typeRecent, typeProjection, model, scenario);
    this.class = "drought";
    this.droughtType = droughtType;
    this.droughtTypeLong = indicator_en[droughtType];

    this.getTitle();

  }

  getTitle() {
    switch (this.type) {
      case "recent":
        this.title = this.indicator + "_" + this.droughtType + "_" + this.type + "_" + this.typeRecent + "_" + this.year;
        this.titleNoYear = this.indicator + "_" + this.droughtType + "_" + this.type + "_" + this.typeRecent;
        this.titleReadable = this.indicator + " " + this.droughtType + " " + this.type + " " + this.typeRecent + " " + this.year;
        break;
      case "projection":
        this.title = this.indicator + "_" + this.droughtType + "_" + this.type + "_" + this.typeProjection + "_" + this.model + "_" + this.scenario + "_" + this.year;
        this.titleNoYear = this.indicator + "_" + this.droughtType + "_" + this.type + "_" + this.typeProjection + "_" + this.model + "_" + this.scenario;
        this.titleReadable = this.indicator + " " + this.droughtType + " " + this.type + " " + this.typeProjection + " " + this.model + " " + this.scenario + " " + this.year;
        break;
    }
  }
}

////////////////////////// RAINY SEASON SUBCLASS
class SeasonLayer extends Layer {
  //// characteristics
  constructor(indicator, year, indicatorType, typeRecent, typeProjection, model, scenario, seasonType) {
    super(indicator, year, indicatorType, typeRecent, typeProjection, model, scenario);
    this.class = "season";
    this.seasonType = seasonType;
    this.seasonTypeLong = indicator_en[seasonType];

    this.getTitle();

  }

  getTitle() {
    switch (this.type) {
      case "recent":
        this.title = this.indicator + "_" + this.seasonType + "_" + this.type + "_" + this.typeRecent + "_" + this.year;
        this.titleNoYear = this.indicator + "_" + this.seasonType + "_" + this.type + "_" + this.typeRecent;
        this.titleReadable = this.indicator + " " + this.seasonType + " " + this.type + " " + this.typeRecent + " " + this.year;
        break;
      case "projection":
        this.title = this.indicator + "_" + this.seasonType + "_" + this.type + "_" + this.typeProjection + "_" + this.model + "_" + this.scenario + "_" + this.year;
        this.titleNoYear = this.indicator + "_" + this.seasonType + "_" + this.type + "_" + this.typeProjection + "_" + this.model + "_" + this.scenario;
        this.titleReadable = this.indicator + " " + this.seasonType + " " + this.type + " " + this.typeProjection + " " + this.model + " " + this.scenario + " " + this.year;
        break;
    }
  }
}

////////////////////////// CROP SUBCLASS
class CropLayer extends Layer {
  //// characteristics
  constructor(indicator, year, indicatorType, typeRecent, typeProjection, model, scenario, crop, cropType, cropStage) {
    super(indicator, year, indicatorType, typeRecent, typeProjection, model, scenario);
    this.class = "crop"
    this.crop = crop;
    this.cropOrg = crops_translate[crop];
    this.cropType = cropType;
    this.cropStage = cropStage;

    this.getTitle();

  }

  getTitle() {
    switch (this.type) {
      case "recent":
        this.title = this.indicator + "_" + this.crop + "_" + this.cropType + "_" + this.cropStage + "_" + this.type + "_" + this.typeRecent + "_" + this.year;
        this.titleNoYear = this.indicator + "_" + this.crop + "_" + this.cropType + "_" + this.cropStage + "_" + this.type + "_" + this.typeRecent;
        this.titleReadable = this.indicator + " " + this.crop + " " + this.cropType + " " + this.cropStage + " " + this.type + " " + this.typeRecent + " " + this.year;
        break;
      case "projection":
        this.title = this.indicator + "_" + this.crop + "_" + this.cropType + "_" + this.cropStage + "_" + this.type + "_" + this.typeProjection + "_" + this.model + "_" + this.scenario + "_" + this.year;
        this.titleNoYear = this.indicator + "_" + this.crop + "_" + this.cropType + "_" + this.cropStage + "_" + this.type + "_" + this.typeProjection + "_" + this.model + "_" + this.scenario;
        this.titleReadable = this.indicator + " " + this.crop + " " + this.cropType + " " + this.cropStage + " " + this.type + " " + this.typeProjection + " " + this.model + " " + this.scenario + " " + this.year;
        break;
    }
  }
}


////////////////////////// REMOTE SENSING SUBCLASS
class RemoteLayer extends Layer {
  //// characteristics
  constructor(indicator, year, indicatorType, typeRecent, typeProjection, model, scenario, month) {
    super(indicator, year, indicatorType, typeRecent, typeProjection, model, scenario);
    this.class = "remote";
    this.month = month;

    this.getTitle();

  }

  getTitle() {
    this.title = this.indicator + "_" + this.month + "_" + this.year;
    this.titleNoYear = this.indicator;
    this.titleReadable = this.indicator + " " + this.month + " " + this.year;
  }

  /////////
  // generate new wms tilelayer from geoserver
  // overwrite parent method becaus time is different (month added to year too)
  getWMSLayer() {
    // new tile wms layer from geoserver
    var newLayer = new TileLayer({
      title: this.titleReadable,
      titleOrg: this.title,
      info: indicator_en[this.indicator] + ' - ' + this.typeProjectionLong_en + ': ' + this.titleReadable,
      visible: true,
      source: new TileWMS({
        url: "https://landsurf.geo.uni-halle.de/geoserver/landsurf/wms",
        params: {
          VERSION: "1.1.1",
          STYLES: "",
          LAYERS: "landsurf:" + this.titleNoYear,
          exceptions: "application/vnd.ogc.se_inimage",
          TIME: this.year + "-" + this.month + "-01",
          TILED: true
        },
        ratio: 1,
        serverType: "geoserver",
        crossOrigin: "",
      }),
    });

    var newLayerSource = newLayer.getSource();
    this.layerSource = newLayerSource;

    return newLayer;

  }


}


var trend_values = [];


function checkInitialSelectionState() {
  let type = $("#indicatorTypeInput").val();

  if (type == 'projection') {
    $('#projSelect').removeAttr('selected');
    $('#obsSelect').attr('selected', 'selected');
  }
}



// popup needs display:block at beginning to be initialized when page is loaded
$('.ol-popup').css('display', 'block');

var slider;


$(function () {


  checkInitialSelectionState();


  ////////////////////////// DATE RANGE SLIDER FOR NDVI CHARTS
  slider = document.getElementById('rangeSlider');

  noUiSlider.create(slider, {
    start: [1990, 2000],
    connect: true,
    connect: true,
    range: {
      'min': [1982],
      'max': [2020]
    },
    step: 1,
    range: {
      'min': 1982,
      'max': 2020
    },
    pips: {
      mode: 'range',
      density: 10,
    },
    tooltips: true,
    format: wNumb({
      decimals: 0
    }),
  });



  //console.log(slider.noUiSlider.get());




  // now it can be switched back to display:none
  $('.ol-popup').css('display', 'none');


  $('#signalCardContainer').css('display', 'none');



  //####################################################################################################################//
  ///////////////////////////////////////////////// INITIALIZE LAYERSWITCHER ////////////////////////////////////////////

  // var toc = document.getElementById('layerSwitcherContainer');
  // LayerSwitcher.renderPanel(map, toc, { reverse: true });
  var target = $('#layerSwitcherContainer').get(0);
  //console.log(target);
  // Add a layer switcher outside the map
  var switcher = new LayerSwitcher({
    target: target,
    show_progress: false,
    extent: false,
    trash: true,
    reordering: false,
  });


  // Add a new ? button to the list that is used as info button for indicator layers
  // and displays the full readable layer name
  //
  // also remove removeLayer button (trash) from all layers except single indicators
  // so they cannot be removed from the map
  //
  // also tried to modify it directly in the source of layerswitcher or overwrite
  // the specific layerswitcher functions but i couldnt get that to work so now this workaround

  // everytime list of layers changes, loop all
  switcher.on('drawlist', function (e) {

    ///// also set display of cities to none -> shouldnt be visible for users
    var layerImageElements = $('#layers').find('.ol-layer-image');
    $(layerImageElements).hide();


    //console.log(e);
    // get layer and title of layer
    var layer = e.layer;
    var title = layer.values_.title

    // do not add '?' at top level layers
    if (['Boundaries', 'Indicators', 'Base Maps', 'Cities'].some(item => title.includes(item))) {

      // find all layerTrash buttons from the top level layers
      // only take the first one ([0]) -> is the one associated with top level layer / layer group
      // remove these, others (from single indicator layers) will stay
      $(e.li).find('.ol-layerswitcher-buttons .layerTrash')[0].remove();

      return null;

    } else {
      // add '?' button, on click, toggle info modal with info stored in openlayers layer
      $('<div>').text('?').on('click', function () {
        $('#layerInfoModalBody').text(layer.get('info'));
        $('#layerInfoModal').modal('toggle');
      })
        .appendTo($('> .ol-layerswitcher-buttons', e.li));
    }



  });

  // add layerswitcher to map
  map.addControl(switcher);

  // Get options values
  if ($("#opb").prop("checked")) $('body').addClass('hideOpacity');
  if ($("#percent").prop("checked")) $('body').addClass('showPercent');
  if ($("#dils").prop("checked")) displayInLayerSwitcher(true);


  // var items = document.querySelectorAll('#layers .ol-layer-image');
  // //console.log(items);

  // for (var i = 0; i < items.length; ++i) {
  //   //console.log(items[i]);
  // }

  $('#layers').on('layerSwitcherShown', function () {
    //console.log("LAYRESWITCHER DAs")
    const layerImageElements = $(this).find('.ol-layer-image'); // `this` refers to the #layers element

    // Do something with layerImageElements
    layerImageElements.each(function () {
      //console.log($(this).text()); // Example: Log the text content of each element
    });
  });


  //####################################################################################################################//
  ////////////////////////////////////// LEGEND CHANGE ON LAYERSWITCHER CLICK ///////////////////////////////////////////
  ///////////////////////////////////////////// ON REMOVE LAYER CLICK ///////////////////////////////////////////////////

  /////// also check legend every time something in layerswitcher is checked
  // change legend if currently visible layer changes -> change currently active layer
  // on change, so that you get the visibility after the change / input is done
  // check for removing layer on 'x' click -> remove layer also from Layers object

  $('#layersCardContainer').on('click', function () {
    // everything 500ms after on layers card is clicked. because it takes a bit to expand
    // and the contents of layerswitcher are created during runtime
    setTimeout(function () {
      // if layers card is expanded
      if ($('#layers').hasClass('show')) {

        //////////////////////////////// LEGEND CHANGE LOGIC /////////////////////////////////////
        // get label of layer in layerswitcher --> this is where the user clicks when 
        // he is selecting / deselecting a layer
        var layerLabel = $('#layerSwitcherContainer').find('label');

        // on click, loop RasterLayer object that holds all current indicators
        $(layerLabel).on('click', function (event) {
          //console.log(layerLabel);
          //console.log(event);
          //console.log($(this));
          var lyr_title_org = $(this).text().replace(/\s+/g, "_");;
          //console.log(lyr_title_org);
          //console.log(Layers[lyr_title_org]);

          RasterLayer.getLayers().forEach(function (layer) {

            // detect last visible layer -> will be the currently visible layer
            if (layer["values_"]["visible"]) {
              var lyr_title = layer.values_.titleOrg;
              //console.log(lyr_title);
              // get equivalent layer from Layers object
              let activeLayer = Layers[lyr_title];

              // add legend of corresponding layer
              activeLayer.addLegend();

              // change active layer in Layers Object
              activeLayer.setActive(true, Layers);
              //console.log(Layers);

              changeChartDisplay(activeLayer.class, activeLayer.type);


            }
          })
          // set city visibility, so e.g. not visible when no raster layer is present
          setCityVisibility();
        })
      }

    }, 500);


  })






  //#######################################################################################################################################################//
  //////////////////////////////////////////////////////////////////////               ///////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////// SELECT INDICATOR MODAL  ///////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////               //////////////////////////////////////////////////////////////////////
  //#######################################################################################################################################################//

  // SEE indicator_table.js





  //###########################################################################################################//
  /////////////////////////////////////////////////// DATE SLIDER ///////////////////////////////////////////////

  // adjust dateslider values based on type of data (recen, projected)
  // get current value of dateslider
  var dateSlideVal = parseInt($("#dateSlider").val());

  // set year display <p> to initial value and adjust timespan accordingly
  $("#yearValue").html("Year: " + dateSlideVal);
  $("#timespanValue").html("Timespan: " + (dateSlideVal - 15) + " - " + (dateSlideVal + 15));

  // adjust value if slider is moved
  $("#dateSlider").on("change", function () {
    $("#yearValue").html("Year: " + $(this).val());
    var timespan_start = (parseInt($(this).val()) - 15);
    var timespan_end = (parseInt($(this).val()) + 15);
    $("#timespanValue").html("Timespan: " + timespan_start + " - " + timespan_end);
  })

  // also adjsut when indicator type (projection / recent) is changed, as then date scale also changes
  $("#indicatorTypeInput").on("change", function () {
    $("#yearValue").html("Year: " + $("#dateSlider").val());
    var timespan_start = (parseInt($("#dateSlider").val()) - 15);
    var timespan_end = (parseInt($("#dateSlider").val()) + 15);
    $("#timespanValue").html("Timespan: " + timespan_start + " - " + timespan_end);
  })

  //###########################################################################################################//
  /////////////////////////////////////////////////// NDVI DATE SLIDER ///////////////////////////////////////////////
  var ndviDateSlideVal = parseInt($("#ndviDateSlider").val());
  $("#ndviYearValue").html("Year: " + ndviDateSlideVal);
  // adjust value if slider is moved
  $("#ndviDateSlider").on("change", function () {
    $("#ndviYearValue").html("Year: " + $(this).val());
  })




  //#######################################################################################################################################################//
  //////////////////////////////////////////////////////////////////////               ///////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////   LOAD LAYER  ///////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////               //////////////////////////////////////////////////////////////////////
  //#######################################################################################################################################################//

  // action when clicking load layer button
  $("#loadLayerButton").on("click", function () {

    // set flag, if layer is created
    // later on check if it was successful created, if not break out of click function completely
    let layerCreationSuccessful = true;

    // test if checkbox to also show signal for trend is checked
    var createSignal = false;

    // if signal checkbox is checked and also visible (can be e.g. checked and invisble when choosing dif after choosing and checking trend)
    // then set createSignal 0 true
    if ($('#signalChecked').is(':checked') && $('#signalChecked').is(':visible')) {
      //console.log("Signal ja");
      createSignal = true;
    } else {
      //console.log("Signal nein");
      createSignal = false;
    }

    // get indicator from input text that was created earlier on when indicator selection modal was closed
    let indicator_parts = $("#indicatorSelectInput").val().split('-');

    ////// layer class attributes
    let indicator = indicator_parts[0].trim();

    // differentiate between remote sensing and every other layer type 
    //console.log(indicator);

    var year_ndvi = $('#ndviDateSlider').val();
    var month = $('#ndviMonthInput').val();
    var year = $("#dateSlider").val();
    var type = $("#indicatorTypeInput").val();
    // recent
    var typeRecent = $("#typeRecentInput").val();
    // projection
    var typeProjection = $("#typeProjectionInput").val();
    var model = $("#projectionModelInput").val();
    var scenario = $("#scenarioInput").val()
    // crops
    var crop = $("#cropInput").val();
    var cropType = $("#cropTypeInput").val();
    var cropStage = $("#cropStageInput").val();
    // drought
    var droughtType = $("#droughtTypeInput").val();
    // season
    var seasonType = $('#seasonTypeInput').val();

    // if (indicator == 'ndvi') {
    //   var year = $('#ndviDateSlider').val();
    //   var month = $('#ndviMonthInput').val();
    // } else {
    //   var year = $("#dateSlider").val();
    //   var type = $("#indicatorTypeInput").val();
    //   // recent
    //   var typeRecent = $("#typeRecentInput").val();
    //   // projection
    //   var typeProjection = $("#typeProjectionInput").val();
    //   var model = $("#projectionModelInput").val();
    //   var scenario = $("#scenarioInput").val()
    //   // crops
    //   var crop = $("#cropInput").val();
    //   var cropType = $("#cropTypeInput").val();
    //   var cropStage = $("#cropStageInput").val();
    //   // drought
    //   var droughtType = $("#droughtTypeInput").val();
    //   // season
    //   var seasonType = $('#seasonTypeInput').val();
    // }




    // determine, if indicator to be loaded is climate, drought or crop
    // and then add the corresponding class
    // distinct, if signal should be added to a trend layer first

    if (createSignal) {
      switch (indicator_class_json[indicator]) {
        // if layer is selected from climate table, its climate or drought
        case "climate":
          var newLayerInstance = new ClimateLayer(indicator, year, type, typeRecent, typeProjection, model, scenario);
          var newLayerInstanceSignal = new ClimateLayer(indicator, year, type, typeRecent, 'signal', model, scenario);
          newLayerInstance.addSignal = true;
          newLayerInstanceSignal.addSignal = true;
          break;
        case "drought":
          var newLayerInstance = new DroughtLayer(indicator, year, type, typeRecent, typeProjection, model, scenario, droughtType);
          var newLayerInstanceSignal = new DroughtLayer(indicator, year, type, typeRecent, 'signal', model, scenario, droughtType);
          newLayerInstance.addSignal = true;
          newLayerInstanceSignal.addSignal = true;
          break;
        case "season":
          var newLayerInstance = new SeasonLayer(indicator, year, type, typeRecent, typeProjection, model, scenario, seasonType);
          var newLayerInstanceSignal = new SeasonLayer(indicator, year, type, typeRecent, 'signal', model, scenario, seasonType);
          newLayerInstance.addSignal = true;
          newLayerInstanceSignal.addSignal = true;
          break;
        case "crop":
          var newLayerInstance = new CropLayer(indicator, year, type, typeRecent, typeProjection, model, scenario, crop, cropType, cropStage);
          var newLayerInstanceSignal = new CropLayer(indicator, year, type, typeRecent, 'signal', model, scenario, crop, cropType, cropStage);
          newLayerInstance.addSignal = true;
          newLayerInstanceSignal.addSignal = true;
          break;
      }
    } else {
      switch (indicator_class_json[indicator]) {
        // if layer is selected from climate table, its climate or drought
        case "climate":
          var newLayerInstance = new ClimateLayer(indicator, year, type, typeRecent, typeProjection, model, scenario);
          break;
        case "drought":
          var newLayerInstance = new DroughtLayer(indicator, year, type, typeRecent, typeProjection, model, scenario, droughtType);
          break;
        case "season":
          var newLayerInstance = new SeasonLayer(indicator, year, type, typeRecent, typeProjection, model, scenario, seasonType);
          break;
        case "crop":
          var newLayerInstance = new CropLayer(indicator, year, type, typeRecent, typeProjection, model, scenario, crop, cropType, cropStage);
          break;
        case "remote":
          var newLayerInstance = new RemoteLayer(indicator, year_ndvi, "recent", "", "abs", "", "", month);
      }
    }


    // test if new Layer already exists (if title is already a key in Layers)
    function hasLayer(title) {
      return Object.hasOwnProperty.call(Layers, title);
    }

    function createLayer(newLayer, newLayerSignal = "") {
      // if layer does not exist, add to object
      if (!hasLayer(newLayer.title)) {
        // differentiate between trend and all other layers
        // when trend is loaded, and checkbox for signal is checked, also signal layer will be loaded on top
        if (newLayer.title.includes("trend")) {
          if (createSignal) {
            newLayer.setActive(true, Layers);
            Layers[newLayer.title] = newLayer;
            Layers[newLayerSignal.title] = newLayerSignal;
            layerCreationSuccessful = true;
            return Layers;
            // checkbox not checked
          } else {
            newLayer.setActive(true, Layers);
            Layers[newLayer.title] = newLayer;
            layerCreationSuccessful = true;
            return Layers;
          }

        } else {
          // set new layer active = true and all others false, because new layer will be visible on top --> active layer
          newLayer.setActive(true, Layers);
          Layers[newLayer.title] = newLayer;
          // set creation successful to true
          layerCreationSuccessful = true;
          return Layers;
        }


        // if layer already exists, thorw error and do not add
      } else {
        // set creation successful to false
        //console.log(newLayer.title);
        //console.log(Layers[newLayer.title])
        // remove layer from Layers array
        delete Layers[newLayer.title];

        var removeLayer;
        // remove layer from RasterLayer Group
        // loop all existing layers and get the one that gets replaced
        RasterLayer.getLayers().forEach(function (layer) {
          //console.log(layer);

          // if layer from group has same title as new one
          // its the same layer -> remove it
          if (layer.values_.title == newLayer.title) {
            //console.log("this one gets removed");
            removeLayer = layer;
          }
        })

        RasterLayer.getLayers().remove(removeLayer);

        try {
          removeLayer.dispose();
        } catch (error) {
          //console.log('already disposed');
        }



        // add layer new to map
        // set new layer active = true and all others false, because new layer will be visible on top --> active layer
        newLayer.setActive(true, Layers);
        // add new layer to Layers object
        Layers[newLayer.title] = newLayer;
        // set creation successful to true
        layerCreationSuccessful = true;
        return Layers;


        // layerCreationSuccessful = false;
        // return null;
      }
    }

    // create new layer and write to Layer object, but only if it does not exist
    if (createSignal) {
      //console.log(newLayerInstance);
      //console.log(newLayerInstanceSignal);
      createLayer(newLayerInstance, newLayerInstanceSignal);
    } else {
      //console.log(newLayerInstance);
      createLayer(newLayerInstance);
    }


    // test if the new layer was created, or not (e.g. if it already existed)
    // if it was not created successfully, skip following code so new layer from geoserver is not loaded
    if (layerCreationSuccessful) {

      // if trend layer was requested, also add signal as layer
      if (createSignal) {
        //console.log("creation successful");
        //console.log("trend layer + signal layer");

        //$('#signalCardContainer').css('display', 'table');

        // add new openlayers Layer to RasterLayer Array
        var newLayerInstanceOrg = newLayerInstance.getWMSLayer();
        var newLayerInstanceSignalOrg = newLayerInstanceSignal.getWMSLayer();

        RasterLayer.getLayers().push(newLayerInstanceOrg);
        RasterLayer.getLayers().push(newLayerInstanceSignalOrg);


        // add Legend for new Layer
        newLayerInstance.addLegend();
        //newLayerInstance.addLegend();

        //console.log(RasterLayer.getLayersArray());



        // else, just add single layer
      } else {
        //console.log("creation successful");
        // add new openlayers Layer to RasterLayer Array
        let newLayer = newLayerInstance.getWMSLayer();
        //console.log(newLayer);
        RasterLayer.getLayers().push(newLayer);
        // add Legend for new Layer
        newLayerInstance.addLegend();

        //console.log(RasterLayer.getLayersArray());
      }

      // expand legend card
      $('#legendLink').removeClass('inactive collapsed');
      $('#legendLink').addClass('clicked');
      $('#legendLink').attr('aria-expanded', 'true');
      $('#properties').addClass('show');
      // document.getElementById("legendCard").style.borderRadius = "0.25rem 0.25rem 0 0";
      $("#legendCard").attr('style', "border-radius: 0.25rem 0.25rem 0 0 !important");

      // hide layer overview card 
      $('#layerLink').attr('inactive');
      $('#layerLink').attr('collapsed');
      $('#layerLink').removeClass('clicked');
      $('#layerLink').attr('aria-expanded', 'false');
      $('#layers').removeClass('show');



      //// change analysis card dependend on active layer
      let activeLayer = getActiveLayer(Layers);
      //console.log(activeLayer);

      changeChartDisplay(activeLayer.class, activeLayer.type);


      // switch (activeLayer.class) {
      //   case "climate":
      //     $('#cropChartsSection').hide();
      //     $('#cardSectionCharts').show();
      //     break;
      //   case "drought":
      //     $('#cropChartsSection').hide();
      //     $('#cardSectionCharts').show();
      //     break;
      //   case "crop":
      //     $('#cropChartsSection').show();
      //     $('#cardSectionCharts').hide();
      //     break;
      // }

    }

    setCityVisibility();
    setLayersVisibility(getActiveLayer(Layers));
  });




  //#######################################################################################################################################################//
  //////////////////////////////////////////////////////////////////////              ///////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////// PRINT LAYOUT ///////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////              //////////////////////////////////////////////////////////////////////
  //#######################################################################################################################################################//

  const dims = {
    a0: [1189, 841],
    a1: [841, 594],
    a2: [594, 420],
    a3: [420, 297],
    a4: [297, 210],
    a5: [210, 148],
  };



  // Create a function that is called when the "printCardContainer" element is clicked
  $('#printCardContainer').on("click", function () {

    // get currently active layer
    var activeLayer = getActiveLayer(Layers);
    //console.log(activeLayer);

    // if there is no active layer, no print map creation
    if (!activeLayer) {
      //console.log('no active layer detected');
      alert('No indicator visible to print!');

    } else {

      // open wait modal
      $('#waitPrintMapModal').modal('toggle');

      //var layersVis = getVisibleLayers();

      // Define the default values for the paper size (A4) and resolution (150 dpi)
      const format = 'a4';
      const resolution = 150;

      // Get the dimensions of the selected paper size
      const dim = dims[format];

      // Calculate the width and height of the map in pixels
      const width = Math.round((dim[0] * resolution) / 20);
      const height = Math.round((dim[1] * resolution) / 20);



      // Get the current size and view resolution of the map
      const size = map.getSize();
      const viewResolution = map.getView().getResolution();

      // Before rendering the map to a canvas, we need to temporarily zoom out to fit the entire map into the desired print size.
      // This is because OpenLayers doesn't properly handle rendering to a canvas with a different size than the current map size.
      // Once the map is rendered to a canvas, we can reset the original map size and resolution.

      // Set the print size to the desired dimensions
      const printSize = [width, height];
      map.setSize(printSize);


      // Calculate the scaling factor to adjust the map's view to fit the print size
      const scaling = Math.min(width / size[0], height / size[1]) + 0.5;

      //console.log("printSize: " + printSize);
      //console.log("width: " + width);
      //console.log("height: " + height);
      //console.log("size: " + size);
      //console.log("viewResolution: " + viewResolution);
      //console.log("scaling: " + scaling);

      // Set the map's view resolution to the scaling factor to ensure the entire map is visible
      map.getView().setResolution(viewResolution / scaling);

      // Once the map is properly sized and scaled, we can render it to a canvas element
      map.once('rendercomplete', function () {


        // async function returns a promise
        async function generatePDF() {
          try {


            // get dimensions of legend img from active layer legend source from geoserver
            // await -> can only be used in async functions
            // -> function pauses execution and waits for resolved promise before continuing
            const dimensions = await activeLayer.getLegendImgDim();
            var legendImgWidth = dimensions.width;
            var legendImgHeight = dimensions.height;



            // Create a new canvas element with the desired dimensions
            const mapCanvas = document.createElement('canvas');
            mapCanvas.width = width;
            mapCanvas.height = height;

            // Get the context of the canvas
            const mapContext = mapCanvas.getContext('2d');

            // Draw all the visible layers onto the canvas
            Array.prototype.forEach.call(
              document.querySelectorAll('.ol-layer canvas'),
              function (canvas) {
                if (canvas.width > 0) {
                  // Get the opacity and transform properties of the layer's canvas
                  const opacity = canvas.parentNode.style.opacity;
                  const transform = canvas.style.transform;

                  // Apply the opacity and transform to the export context
                  // mapContext is the context of the export canvas.
                  // globalAlpha is a property of the context that determines the opacity of the subsequent drawing operations.
                  // opacity is the opacity value of the layer's canvas content.
                  // === '': Checks if the opacity value is empty, which indicates that the layer has no opacity set.
                  // 1: If the opacity is empty, set the globalAlpha to 1, which means fully opaque.
                  // Number(opacity): Convert the opacity value to a number, ensuring compatibility with the setTransform() function.
                  // In summary, this code ensures that the opacity of the layer's canvas content is applied correctly to the exported map image. 
                  // If the layer has no opacity set, it makes the exported content fully opaque. Otherwise, it applies the specified opacity value.
                  mapContext.globalAlpha = opacity === '' ? 1 : Number(opacity);


                  // Parse the transform matrix from the style
                  // In summary, this code extracts the transform matrix from the layer's CSS transform property, 
                  // ensuring that the layer's visual appearance is preserved in the exported map image. 
                  // The extracted matrix is then applied to the export canvas using the setTransform() function
                  const matrix = transform.match(/^matrix\(([^\(]*)\)$/)[1].split(',').map(Number);

                  // Set the transform on the export context
                  CanvasRenderingContext2D.prototype.setTransform.apply(mapContext, matrix);

                  // Draw the layer's canvas onto the export canvas
                  mapContext.drawImage(canvas, 0, 0);
                }
              }
            );

            // Reset the opacity and transform to their original values
            mapContext.globalAlpha = 1;
            mapContext.setTransform(1, 0, 0, 1, 0, 0);

            // Create a new PDF document
            const pdf = new jsPDF('landscape', undefined, format);
            const margin = 3;

            // ------------------------------------- MAIN MAP -------------------------------------------//
            // Add the exported map image to the PDF document
            // add map as img
            pdf.addImage(
              mapCanvas.toDataURL('image/jpeg'),
              'JPEG',
              margin,
              margin,
              dim[0] - margin * 2,
              dim[1] - margin * 2
            );
            // add rectangle with border as border for img
            pdf.setDrawColor('#293956');
            pdf.rect(
              margin,
              margin,
              dim[0] - margin * 2,
              dim[1] - margin * 2,
              'D'
            );


            // ------------------------------------- LEGEND -------------------------------------------//
            // if there is a visible layer --> get legend


            pdf.setFillColor('#ffffff'); // Set the fill color
            pdf.setDrawColor('#293956');
            var scaleRectX = 4;
            var scaleRectY = 4;


            var legend_src = activeLayer.getLegendURL();



            pdf.rect(
              margin,
              dim[1] - (legendImgHeight / 4.5) - scaleRectY - margin,
              (legendImgWidth / 4.5) + scaleRectX,
              (legendImgHeight / 4.5) + scaleRectY,
              'FD'
            ); // Draw the rectangle with fill

            pdf.addImage(
              legend_src,
              'PNG',
              scaleRectX / 2 + margin,
              dim[1] - (legendImgHeight / 4.5) - (scaleRectY / 2) - margin,
              (legendImgWidth / 4.5),
              (legendImgHeight / 4.5)
            );




            // ------------------------------------- TITLE -------------------------------------------//
            var textX = dim[0] / 2;
            var textY = 7;

            pdf.setFontSize(14);
            // Get the font size
            var fontSize = pdf.getFontSize();

            // Calculate the text height
            var textHeight = fontSize * 0.352777778; // Convert points to millimeters

            pdf.setFillColor('#f8f9fa'); // Set the fill color
            pdf.setDrawColor('#293956');

            var scaleRectX = 6;
            var scaleRectY = 2;

            // if crop or drought, then three lines -> different text height and more titles
            if (activeLayer.class == 'crop' || activeLayer.class == 'drought' || activeLayer.class == 'season') {
              //console.log('CROP OR DROUGHT TITLE');
              var mapTitle = activeLayer.getPrintMapTitle()[0];
              var mapTitle2 = activeLayer.getPrintMapTitle()[1];
              var mapTitle3 = activeLayer.getPrintMapTitle()[2];

              //console.log(mapTitle);
              //console.log(mapTitle2);
              //console.log(mapTitle3);

              var textWidth = pdf.getStringUnitWidth(mapTitle) * fontSize / pdf.internal.scaleFactor;
              var textWidth2 = pdf.getStringUnitWidth(mapTitle2) * fontSize / pdf.internal.scaleFactor;
              var textWidth3 = pdf.getStringUnitWidth(mapTitle3) * fontSize / pdf.internal.scaleFactor;
              var maxWidth = Math.max(textWidth, textWidth2, textWidth3);



              pdf.rect(
                textX - (maxWidth / 2) - (scaleRectX / 2),
                textY - (0.8 * textHeight) - (scaleRectY / 2) + margin,
                maxWidth + scaleRectX,
                (textHeight * 3) + scaleRectY,
                'FD'
              ); // Draw the rectangle with fill

              pdf.text(
                textX,
                textY + margin,
                mapTitle,
                { align: 'center' }
              );

              pdf.text(
                textX,
                textY + margin + textHeight,
                mapTitle2,
                { align: 'center' }
              );

              pdf.text(
                textX,
                textY + margin + textHeight * 2,
                mapTitle3,
                { align: 'center' }
              );

            } else if (activeLayer.class == 'climate' || activeLayer.class == 'remote') {
              //console.log('CLIMATE OR REMOTE TITLE');
              var mapTitle = activeLayer.getPrintMapTitle()[0];
              var mapTitle2 = activeLayer.getPrintMapTitle()[1];
              // Calculate the width of the text for both textes and then 
              // take the wider one to calculate get maxwidth for both textes
              var textWidth = pdf.getStringUnitWidth(mapTitle) * fontSize / pdf.internal.scaleFactor;
              var textWidth2 = pdf.getStringUnitWidth(mapTitle2) * fontSize / pdf.internal.scaleFactor;
              var maxWidth = Math.max(textWidth, textWidth2);

              //console.log(mapTitle);
              //console.log(mapTitle2);

              pdf.rect(
                textX - (maxWidth / 2) - (scaleRectX / 2),
                textY - (0.8 * textHeight) - (scaleRectY / 2) + margin,
                maxWidth + scaleRectX,
                (textHeight * 2) + scaleRectY,
                'FD'
              ); // Draw the rectangle with fill

              pdf.text(
                textX,
                textY + margin,
                mapTitle,
                { align: 'center' }
              );

              pdf.text(
                textX,
                textY + margin + textHeight,
                mapTitle2,
                { align: 'center' }
              );

            } else {
              //console.log('unexpected condition');
            }




            // ------------------------------------- FOOTER -------------------------------------------//
            const currentDate = new Date();
            const formattedDate = currentDate.toISOString().slice(0, 10);

            var footerText = 'Date: ' + formattedDate + '\n' + "WASCAL WRAP 2.0 -LANDSURF Project" +
              '\n' + "Basemap: © OpenStreetMap Contributors, CC-BY-SA 2.0";
            pdf.setFontSize(10);
            fontSize = pdf.getFontSize()
            textHeight = (fontSize * 0.352777778) * 3;
            textWidth = pdf.getStringUnitWidth(footerText) * fontSize / pdf.internal.scaleFactor - 75;

            var rectBottomRightW = textWidth;
            var rectBottomRightH = textHeight + margin * 2;
            var rectBottomRightX = dim[0] - margin - rectBottomRightW;
            var rectBottomRightY = dim[1] - (margin) - rectBottomRightH;

            pdf.setFillColor('#f8f9fa'); // Set the fill color
            pdf.setDrawColor('#293956');
            pdf.rect(
              rectBottomRightX,
              rectBottomRightY,
              rectBottomRightW,
              rectBottomRightH,
              'FD'
            ); // Draw the rectangle with fill



            pdf.text(
              rectBottomRightX + 2,
              rectBottomRightY + rectBottomRightH - textHeight,
              footerText,
              { align: 'left' }
            );


            // Save the PDF document to a file named "map.pdf"
            pdf.save('map.pdf');

            // Reset the original map size and resolution
            map.setSize(size);
            map.getView().setResolution(viewResolution);

            // Disable the export button to prevent multiple PDF generation
            //exportButton.disabled = true;

            // Set the cursor back to normal after the PDF generation is complete
            document.body.style.cursor = 'auto';



          } catch (error) {
            alert(error);

          }
        }

        generatePDF(); // Call the function to start PDF generation
        // close wait modal
        $('#waitPrintMapModal').modal('toggle');
      });
    }


  });


});




export { loadScript, changeSelectionDisplay, indicator_class_json, Layers, getActiveLayer, slider };



//////////////////////////////////////////////////////////////////////////////////////////////////////