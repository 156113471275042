import $, { type } from "jquery";
import JSZip from 'jszip';
import { saveAs } from 'file-saver';

// date range slider librarys
import * as noUiSlider from 'nouislider';
import 'nouislider/dist/nouislider.css';
import wNumb from 'wnumb';


$(function () {

  // toggle modal on click on card
  $("#downloadCard").on("click", function () {
    $('#downloadDataModal').modal('toggle');
  })

  //////////////////////////////////////// DOWNLOAD MODAL UI LOGIC ////////////////////////////////////

  $('#downloadClimateTypeForm').on('input', function () {
    //console.log("input climate")

    var type_checked = $('.form-check-input-climate-type:checked').map(function () {
      return this.value;
    }).get();

    if (type_checked.includes('recent')) {
      $('#download-climate-form-recent').show()
    } else {
      $('#download-climate-form-recent').hide()
    }

    if (type_checked.includes('projection')) {
      $('.download-climate-form-projection').show();
      $('#downloadClimateScenarioForm').show();
      $('#downloadClimateModelForm').show();
      $('#downloadFormClimateTypeProjection').show();
    } else {
      $('.download-climate-form-projection').hide();
      $('#downloadClimateScenarioForm').hide();
      $('#downloadClimateModelForm').hide();
      $('#downloadFormClimateTypeProjection').hide();
    }

  })

  $('#downloadCropTypeForm').on('input', function () {
    //console.log("input crop")

    var type_checked = $('.form-check-input-crop-type:checked').map(function () {
      //console.log(type_checked);
      return this.value;
    }).get();

    if (type_checked.includes('recent')) {
      $('#download-crop-form-recent').show()
    } else {
      $('#download-crop-form-recent').hide()
    }

    if (type_checked.includes('projection')) {
      $('.download-crop-form-projection').show();
      $('#downloadCropScenarioForm').show();
      $('#downloadCropModelForm').show();
      $('#downloadFormCropTypeProjection').show();
    } else {
      $('.download-crop-form-projection').hide();
      $('#downloadCropScenarioForm').hide();
      $('#downloadCropModelForm').hide();
      $('#downloadFormCropTypeProjection').hide();
    }

  })

  var indicators_full = [];

  var climateIndicators_checked, climateType_checked, climateTypeRecent_checked, climateTypeProjection_checked, climateModel_checked, climateScenario_checked, climateDataType_checked = [];

  var cropIndicators_checked, cropType_checked, cropTypeRecent_checked, cropTypeProjection_checked, cropModel_checked, cropScenario_checked, crop_checked, crop_type_checked, crop_stage_checked, cropDataType_checked = [];

  var remoteIndicators_checked, remoteDataType_checked = [];



  $('#tab041').on('input', function () {

    climateIndicators_checked = $('.form-check-input-climate-index:checked').map(function () {
      return this.value;
    }).get();
    climateType_checked = $('.form-check-input-climate-type:checked').map(function () {
      return this.value;
    }).get();
    climateTypeRecent_checked = $('.form-check-input-climate-type-recent:checked').map(function () {
      return this.value;
    }).get();
    climateTypeProjection_checked = $('.form-check-input-climate-type-projection:checked').map(function () {
      return this.value;
    }).get();
    climateModel_checked = $('.form-check-input-climate-model:checked').map(function () {
      return this.value;
    }).get();
    climateScenario_checked = $('.form-check-input-climate-scenario:checked').map(function () {
      return this.value;
    }).get();
    climateDataType_checked = $('.form-check-input-climate-datatype:checked').map(function () {
      return this.value;
    }).get();


    //console.log("data checked:")
    //console.log(climateIndicators_checked);
    //console.log(climateType_checked);
    //console.log(climateTypeRecent_checked);
    //console.log(climateTypeProjection_checked);
    //console.log(climateModel_checked);
    //console.log(climateScenario_checked);
    //console.log(climateDataType_checked);

  })


  $('#tab051').on('input', function () {

    cropIndicators_checked = $('.form-check-input-crop-index:checked').map(function () {
      return this.value;
    }).get();
    cropType_checked = $('.form-check-input-crop-type:checked').map(function () {
      return this.value;
    }).get();
    cropTypeRecent_checked = $('.form-check-input-crop-type-recent:checked').map(function () {
      return this.value;
    }).get();
    cropTypeProjection_checked = $('.form-check-input-crop-type-projection:checked').map(function () {
      return this.value;
    }).get();
    cropModel_checked = $('.form-check-input-crop-model:checked').map(function () {
      return this.value;
    }).get();
    cropScenario_checked = $('.form-check-input-crop-scenario:checked').map(function () {
      return this.value;
    }).get();
    crop_type_checked = $('.form-check-input-crop-type-LS:checked').map(function () {
      return this.value;
    }).get();
    crop_stage_checked = $('.form-check-input-crop-stage:checked').map(function () {
      return this.value;
    }).get();
    crop_checked = $('.form-check-input-crop:checked').map(function () {
      return this.value;
    }).get();

    cropDataType_checked = $('.form-check-input-crop-datatype:checked').map(function () {
      return this.value;
    }).get();


    //console.log("data checked:")
    //console.log(cropIndicators_checked);
    //console.log(cropType_checked);
    //console.log(cropTypeRecent_checked);
    //console.log(cropTypeProjection_checked);
    //console.log(cropModel_checked);
    //console.log(cropScenario_checked);
    //console.log(crop_checked);
    //console.log(crop_type_checked);
    //console.log(crop_stage_checked);
    //console.log(cropDataType_checked);

  })


  var sliderDownload;
  sliderDownload = document.getElementById('rangeSliderDownload');

  noUiSlider.create(sliderDownload, {
    start: [2000, 2005],
    // limit: 5,
    connect: true,
    range: {
      'min': [1982],
      'max': [2020]
    },
    step: 1,
    range: {
      'min': 1982,
      'max': 2020
    },
    pips: {
      mode: 'range',
      density: 10,
    },
    tooltips: true,
    format: wNumb({
      decimals: 0
    }),
  });


  var startYear = 2000;
  var endYear = 2005;
  var yearSequence = [2000, 2001, 2002, 2003, 2004, 2005]

  sliderDownload.noUiSlider.on('change', function () {
    startYear = sliderDownload.noUiSlider.get()[0]
    endYear = sliderDownload.noUiSlider.get()[1]
    yearSequence = Array.from({ length: Number(endYear) - Number(startYear) + 1 }, (_, index) => Number(startYear) + index);

    //console.log(startYear);
    //console.log(endYear);
    //console.log(yearSequence);
  })


  $('#tab061').on('input', function () {

    remoteIndicators_checked = $('.form-check-input-remote-index:checked').map(function () {
      return this.value;
    }).get();
    remoteDataType_checked = $('.form-check-input-remote-datatype:checked').map(function () {
      return this.value;
    }).get();



    //console.log("data checked:")
    //console.log(remoteIndicators_checked);
    //console.log(remoteDataType_checked);
  })


  var fileSizeClimate = 15;
  var fileSizeRemote = 250;
  var filesizeSignalTrend = 0.25;
  var estFileSize;
  var fileSizePass;
  var maxFileSize = 1000;





  $('#downloadIndicesButton').on('click', function () {

    //console.log("download button clicked");

    var tab_active = $('.fieldset-tab.show').attr('id');
    //console.log(tab_active);

    //open modal that lets user wait until everything is zipped
    // $('#downloadDataModal').css('background-color', '#000000');

    switch (tab_active) {
      /////////////////////////////////////////-------------------------------------------------/////////////////////////////
      // ------------------------------------------------------ CLIMATE DOWNLOAD -----------------------------------------//
      case 'tab041':
        for (let i = 0; i < climateIndicators_checked.length; i++) {

          //console.log("IST EIN CLIMATE");
          var indicator = climateIndicators_checked[i];
          //console.log(indicator);

          /////////// RECENT /////////////
          if (climateType_checked.includes('recent')) {
            //console.log("RECENT");

            for (let a = 0; a < climateDataType_checked.length; a++) {
              for (let g = 0; g < climateIndicators_checked.length; g++) {
                indicator + "_1981-2010_era5land.tif"
                var indicator_path = 'absolute/' + climateIndicators_checked[g] + "_1981-2010_era5land" + "." + climateDataType_checked[a];
                //console.log(indicator_path);
                indicators_full.push(indicator_path);
              }
            }
          }
          ////////// PROJECTION /////////////
          if (climateType_checked.includes('projection')) {
            //console.log("PROJECTION");

            if (climateTypeProjection_checked.includes('abs')) {
              //console.log('absolute data');

              for (let a = 0; a < climateDataType_checked.length; a++) {
                for (let b = 0; b < climateScenario_checked.length; b++) {
                  for (let c = 0; c < climateModel_checked.length; c++) {
                    for (let g = 0; g < climateIndicators_checked.length; g++) {
                      var indicator_path = 'absolute/' + climateIndicators_checked[g] + "_1981-2100_" + climateModel_checked[c] + "_ensmean_" + climateScenario_checked[b] + "_run." + climateDataType_checked[a];
                      //console.log(indicator_path);
                      indicators_full.push(indicator_path);
                    }
                  }
                }
              }

            } if (climateTypeProjection_checked.includes('diff')) {
              //console.log('difference data');

              for (let a = 0; a < climateDataType_checked.length; a++) {
                for (let b = 0; b < climateScenario_checked.length; b++) {
                  for (let c = 0; c < climateModel_checked.length; c++) {
                    for (let g = 0; g < climateIndicators_checked.length; g++) {
                      var indicator_path = 'difference/' + climateIndicators_checked[g] + "_1981-2100_" + climateModel_checked[c] + "_ensmean_" + climateScenario_checked[b] + "_diff2hist_run." + climateDataType_checked[a];
                      //console.log(indicator_path);
                      indicators_full.push(indicator_path);
                    }
                  }
                }
              }


            } if (climateTypeProjection_checked.includes('sigtrend')) {
              //console.log('trend data');

              for (let a = 0; a < climateDataType_checked.length; a++) {
                for (let b = 0; b < climateScenario_checked.length; b++) {
                  for (let c = 0; c < climateModel_checked.length; c++) {
                    for (let g = 0; g < climateIndicators_checked.length; g++) {
                      var indicator_path = 'trend/' + climateIndicators_checked[g] + "_1981-2100_" + climateModel_checked[c] + "_ensmean_" + climateScenario_checked[b] + "_sigtrend." + climateDataType_checked[a];
                      //console.log(indicator_path);
                      indicators_full.push(indicator_path);
                    }
                  }
                }
              }

            } if (climateTypeProjection_checked.includes('signal')) {
              //console.log('signal data');

              for (let a = 0; a < climateDataType_checked.length; a++) {
                for (let b = 0; b < climateScenario_checked.length; b++) {
                  for (let c = 0; c < climateModel_checked.length; c++) {
                    for (let g = 0; g < climateIndicators_checked.length; g++) {
                      var indicator_path = 'signal/' + climateIndicators_checked[g] + "_1981-2100_" + climateModel_checked[c] + "_ensmean_" + climateScenario_checked[b] + "_signal." + climateDataType_checked[a];
                      //console.log(indicator_path);
                      indicators_full.push(indicator_path);
                    }
                  }
                }
              }

            }


          }
        }

        // estimate file size
        var numFiles = indicators_full.length;
        estFileSize = 0;
        estFileSize = numFiles * fileSizeClimate;

        //console.log(numFiles);
        //console.log(estFileSize);

        if (estFileSize > maxFileSize) {
          fileSizePass = false;
          alert('Too many selected files!\nMaximum total file size:' + maxFileSize / 1000 + 'GB\nSize of your selected Files:' + estFileSize / 1000 + ' GB');
        } else {
          fileSizePass = true;
        }

        break;


      /////////////////////////////////////////-------------------------------------------------/////////////////////////////
      // ------------------------------------------------------ CROP DOWNLOAD -----------------------------------------//
      case 'tab051':
        for (let i = 0; i < cropIndicators_checked.length; i++) {

          //console.log("IST EIN crop");
          var indicator = cropIndicators_checked[i];
          //console.log(indicator);

          /////////// RECENT /////////////
          if (cropType_checked.includes('recent')) {
            //console.log("RECENT");

            for (let a = 0; a < cropDataType_checked.length; a++) {
              for (let d = 0; d < crop_stage_checked.length; d++) {
                for (let e = 0; e < crop_type_checked.length; e++) {
                  for (let f = 0; f < crop_checked.length; f++) {
                    for (let g = 0; g < cropIndicators_checked.length; g++) {
                      var indicator_path = 'absolute/' + cropIndicators_checked[g] + "_" + crop_checked[f] + "_" + crop_type_checked[e] + "_" + crop_stage_checked[d] + "_1981-2010_era5land." + cropDataType_checked[a];
                      //console.log(indicator_path);
                      indicators_full.push(indicator_path);

                    }
                  }
                }
              }
            }

          }

          ////////// PROJECTION /////////////
          if (cropType_checked.includes('projection')) {
            //console.log("PROJECTION");

            if (cropTypeProjection_checked.includes('abs')) {
              //console.log('absolute data');

              for (let a = 0; a < cropDataType_checked.length; a++) {
                for (let b = 0; b < cropScenario_checked.length; b++) {
                  for (let c = 0; c < cropModel_checked.length; c++) {
                    for (let d = 0; d < crop_stage_checked.length; d++) {
                      for (let e = 0; e < crop_type_checked.length; e++) {
                        for (let f = 0; f < crop_checked.length; f++) {
                          for (let g = 0; g < cropIndicators_checked.length; g++) {
                            var indicator_path = 'absolute/' + cropIndicators_checked[g] + "_" + crop_checked[f] + "_" + crop_type_checked[e] + "_" + crop_stage_checked[d] + "_1981-2100_" + cropModel_checked[c] + "_ensmean_" + cropScenario_checked[b] + "_run." + cropDataType_checked[a];
                            //console.log(indicator_path);
                            indicators_full.push(indicator_path);
                          }
                        }
                      }
                    }
                  }
                }
              }


            } if (cropTypeProjection_checked.includes('diff')) {
              //console.log('difference data');

              for (let a = 0; a < cropDataType_checked.length; a++) {
                for (let b = 0; b < cropScenario_checked.length; b++) {
                  for (let c = 0; c < cropModel_checked.length; c++) {
                    for (let d = 0; d < crop_stage_checked.length; d++) {
                      for (let e = 0; e < crop_type_checked.length; e++) {
                        for (let f = 0; f < crop_checked.length; f++) {
                          for (let g = 0; g < cropIndicators_checked.length; g++) {
                            var indicator_path = 'difference/' + cropIndicators_checked[g] + "_" + crop_checked[f] + "_" + crop_type_checked[e] + "_" + crop_stage_checked[d] + "_1981-2100_" + cropModel_checked[c] + "_ensmean_" + cropScenario_checked[b] + "_diff2hist_run." + cropDataType_checked[a];
                            //console.log(indicator_path);
                            indicators_full.push(indicator_path);
                          }
                        }
                      }
                    }
                  }
                }
              }


            } if (cropTypeProjection_checked.includes('sigtrend')) {
              //console.log('trend data');

              for (let a = 0; a < cropDataType_checked.length; a++) {
                for (let b = 0; b < cropScenario_checked.length; b++) {
                  for (let c = 0; c < cropModel_checked.length; c++) {
                    for (let d = 0; d < crop_stage_checked.length; d++) {
                      for (let e = 0; e < crop_type_checked.length; e++) {
                        for (let f = 0; f < crop_checked.length; f++) {
                          for (let g = 0; g < cropIndicators_checked.length; g++) {
                            var indicator_path = 'trend/' + cropIndicators_checked[g] + "_" + crop_checked[f] + "_" + crop_type_checked[e] + "_" + crop_stage_checked[d] + "_1981-2100_" + cropModel_checked[c] + "_ensmean_" + cropScenario_checked[b] + "_sigtrend." + cropDataType_checked[a];
                            //console.log(indicator_path);
                            indicators_full.push(indicator_path);
                          }
                        }
                      }
                    }
                  }
                }
              }

            } if (cropTypeProjection_checked.includes('signal')) {
              //console.log('signal data');

              for (let a = 0; a < cropDataType_checked.length; a++) {
                for (let b = 0; b < cropScenario_checked.length; b++) {
                  for (let c = 0; c < cropModel_checked.length; c++) {
                    for (let d = 0; d < crop_stage_checked.length; d++) {
                      for (let e = 0; e < crop_type_checked.length; e++) {
                        for (let f = 0; f < crop_checked.length; f++) {
                          for (let g = 0; g < cropIndicators_checked.length; g++) {
                            var indicator_path = 'signal/' + cropIndicators_checked[g] + "_" + crop_checked[f] + "_" + crop_type_checked[e] + "_" + crop_stage_checked[d] + "_1981-2100_" + cropModel_checked[c] + "_ensmean_" + cropScenario_checked[b] + "_signal." + cropDataType_checked[a];
                            //console.log(indicator_path);
                            indicators_full.push(indicator_path);
                          }
                        }
                      }
                    }
                  }
                }
              }

            }


          }
        }

        // estimate file size
        var numFiles = indicators_full.length;
        estFileSize = 0;
        estFileSize = numFiles * fileSizeClimate;

        //console.log(numFiles);
        //console.log(estFileSize);

        if (estFileSize > maxFileSize) {
          fileSizePass = false;
          alert('Too many selected files!\nMaximum total file size:' + maxFileSize / 1000 + 'GB\nSize of your selected Files:' + estFileSize / 1000 + ' GB');
        } else {
          fileSizePass = true;
        }

        break;


      /////////////////////////////////////////-------------------------------------------------/////////////////////////////
      // ------------------------------------------------------ REMOTE SENSING DOWNLOAD -----------------------------------------//
      case 'tab061':

        //console.log('Remote indices Tab');

        for (let i = 0; i < remoteIndicators_checked.length; i++) {
          for (let a = 0; a < remoteDataType_checked.length; a++) {
            for (let j = 0; j < yearSequence.length; j++) {
              var indicator_path = 'remoteSensing/' + remoteIndicators_checked[i] + '/' + remoteIndicators_checked[i] + '_' + yearSequence[j] + "." + remoteDataType_checked[a];
              //console.log(indicator_path);
              indicators_full.push(indicator_path);
            }
          }
        }

        // estimate file size
        var numFiles = indicators_full.length;
        estFileSize = 0;
        estFileSize = numFiles * fileSizeRemote;

        //console.log(numFiles);
        //console.log(estFileSize);

        if (estFileSize > maxFileSize) {
          fileSizePass = false;
          alert('Too many selected files!\nMaximum total file size:' + maxFileSize / 1000 + 'GB\nSize of your selected Files:' + estFileSize / 1000 + ' GB');
        } else {
          fileSizePass = true;
        }

        break;
    }








    //console.log("GESAMT:");
    //console.log(indicators_full);

    var fileURLs = indicators_full.map(i => '/indicators/' + i);

    //console.log(fileURLs);

    //////////////// CHECK IF VALID ///////////////////
    // logic, that checks if necessary checkboxes are checked

    if (fileSizePass) {
      if (fileURLs.length == 0) {

        alert("Something went wrong selecting the data. Maybe not all neccessary checkboxes are selected?");

        indicators_full = [];
        fileURLs = [];


      } else {

        $('#zippingModal').modal('toggle');
        var zip = new JSZip();
        var count = 0;


        var loadFile = function (url) {
          return new Promise(function (resolve, reject) {
            var xhr = new XMLHttpRequest();
            xhr.open('GET', url, true);
            xhr.responseType = "blob";

            xhr.onload = function () {
              if (xhr.status == 200) {
                var blob_url = URL.createObjectURL(xhr.response);
                var fileName = url.substring(url.lastIndexOf('/') + 1);
                zip.file(fileName, xhr.response, { binary: true }); // add downloaded file to zip
                //console.log(zip);
                resolve();
              } else {
                reject("Failed to download file " + url);
              }
            };

            xhr.onerror = function () {
              reject("Network error");
            };

            xhr.send();
          });
        };

        var promises = fileURLs.map(loadFile);

        Promise.all(promises)
          .then(function () {
            zip.generateAsync({ type: "blob" })
              .then(function (content) {
                saveAs(content, "landsurf_indicator_download.zip");
                setTimeout(() => {
                  $('#zippingModal').modal('toggle');
                }, 1000);
              });
          })
          .catch(function (error) {
            console.error(error);
          });

        // $('#downloadDataModal').css('background-color', '#ffffff');
        indicators_full = [];
        fileURLs = [];

      }
    }

  })

});