// analysis.js
import { } from '../main';
import $ from 'jquery';
import { Overlays, CountriesStyle, CountiesStyle, DistricsStyle, map } from './layers';

import Style from 'ol/style/Style';
import Fill from 'ol/style/Fill';
import Text from 'ol/style/Text';
import Stroke from 'ol/style/Stroke';


//#########################################################//
//////////////// SELECT LAYER BUTTON LOGIC /////////////////

var region_selected = [];
// layers
var admin_lyrs = ["Countries", "Counties", "Districts"]
// array that holds the currently visible administrative boundaries
var admin_vis = { Countries: false, Counties: false, Districts: false };
// array that holds, which layer was choosen to select region
var admin_selected = [];
// name of selected region
// including region, subregion and so on
var selected = [];
// region but just admin0, 1 or 2 to later put in post request
var region_selected_single = [];



//##################################################################################//
//////////////////////// SELECT REGION ON MAP CLICK /////////////////////////////////

map.on("singleclick", function (e) {

  // if select region button is active, enable feature selection
  if ($("#selectRegionButton").attr("class").includes("active")) {
    //console.log("select Region active, selecting...");


    // loop feature layers at clicked pixel location
    map.forEachFeatureAtPixel(e.pixel, function (feat, layer) {

      // check which layer was selected when pressing start selecting button
      // only that layer can be used to click on after that
      //console.log("#########################");
      //console.log("layer from which to select: " + admin_selected);

      // test if clicked feature belongs to previously selected layer
      var lyr = layer["values_"]["title"];

      // if selected layer (admin_selected) is equal to clicked layer,
      // layer will be colored and stored in array
      if (admin_selected == lyr) {
        //console.log("stimmt überein");
        //console.log(lyr);
        //console.log(feat);
        //console.log(feat["values_"]["ADM0_NAME"]);

        /// create style for clicked feature
        let highlightStyle = new Style({
          fill: new Fill({
            color: "#C64B7740",
          }),
          stroke: new Stroke({
            color: "#306BD5",
            width: 3,
          }),
          text: new Text({
            font: '20px sans-serif',
            text: feat["values_"]["ADM0_NAME"],
            fill: new Fill({
              color: [255, 255, 255, 1],
            }),
          }),
        });


        // indexof tests if element is already in array
        // gives -1 if element is not in array
        const selIndex = selected.indexOf(feat);

        // if element not in array (-1) then push to selected and set style
        if (selIndex < 0) {
          // differentiate, if layer is adm0 (country), adm1 (county), or adm2 (districs)
          switch (lyr) {
            case "Countries":
              region_selected.push(feat["values_"]["ADM0_NAME"]);
              region_selected_single.push(feat["values_"]["ADM0_NAME"]);
              selected.push(feat);
              break;
            case "Counties":
              region_selected.push(feat["values_"]["ADM0_NAME"] + " " + feat["values_"]["ADM1_NAME"]);
              region_selected_single.push(feat["values_"]["ADM1_NAME"]);
              selected.push(feat);
              break;
            case "Districts":
              region_selected.push(feat["values_"]["ADM0_NAME"] + " " + feat["values_"]["ADM1_NAME"] + " " + feat["values_"]["ADM2_NAME"]);
              region_selected_single.push(feat["values_"]["ADM2_NAME"]);
              selected.push(feat);
              break;
          }
          // set style of selected feature
          feat.setStyle(highlightStyle);

          // else if element already in array, delete from array and unset style
        } else {
          region_selected.splice(selIndex, 1);
          region_selected_single.splice(selIndex, 1);
          selected.splice(selIndex, 1);
          feat.setStyle(undefined);
        }

        // set selected to display in paragraph
        $("#selectedLayerName").text(region_selected);

      } else {
        //console.log("keine Übereinstimmung");
      }
    });

    // if button passive, do nothing
  } else if ($("#selectRegionButton").attr("class").includes("passive")) {
    //console.log("select Region button passive, nothing happend");
  }

  // print all currently selected layers
  //console.log(region_selected);

});





$("#selectRegionButton").on("click", function () {
  //console.log("CLICK CLICK ON BUTTON!!!!!!!!!!!!!!")


  if ($('#popupButton').attr("class").includes('active')) {
    //console.log("SELECT Point ACTVE")
    alert("Stop selecting a point popup before selecting a region by clicking on the \"Investigate Layers on Map\" Button above");
  } else {

    // test if passive or active and change respectively
    if ($(this).attr("class").includes("passive")) {
      //console.log("WAS PASSIVE");
      $(this).removeClass("passive");
      $(this).addClass("active");

      if (current_language == "en") {
        $(this).html('Stop Selecting <i class="fa-solid fa-map-location-dot" id="regionIcon"></i>');
      } else if (current_language == "fr") {
        $(this).html('Arrêter Sélection <i class="fa-solid fa-map-location-dot" id="regionIcon"></i>');
      }


      ///// at first, test if boundaries group is actually visible, if not, skip
      if (Overlays.values_.visible === false) {
        $('#CountriesDiv, #CountiesDiv, #DistrictsDiv, #modalRegionSaveButton').hide();
        $('#noAdminVisibleDiv').show();
        $('#selectRegionModal').modal('toggle');
      } else {


        // clear selected layers
        // set style standard for all
        Overlays.getLayers().forEach(function (lyr) {
          switch (lyr["values_"]["title"]) {
            case "Countries":
              //console.log("COUNTRIES")
              //console.log(lyr);
              lyr.getSource().forEachFeature(function (feat) {
                feat.setStyle(CountriesStyle);
              })
              break;
            case "Counties":
              lyr.setStyle(CountiesStyle);
              break;
            case "Districs":
              lyr.setStyle(DistricsStyle);
              break;

            default:
              break;
          }
        });

        // clear set layers
        admin_selected = [];
        selected = [];
        region_selected = [];
        region_selected_single = [];

        // set selected text to blank
        $("#selectedLayerName").text("");


        // logic to look up which admin layers are active and then
        // ask user from which admin layer he wants to select
        //console.log(Overlays.getLayers()["array_"]);


        // loop vector admin layers and get title and visibility
        Overlays.getLayers()["array_"].forEach(function (lyr) {
          var title = lyr["values_"]["title"];
          var vis = lyr["values_"]["visible"];
          admin_vis[title] = vis;
        });


        //console.log(admin_vis);
        // {Countries: true, Counties: false, Districts: false}
        // Counties:false
        // Countries: true
        // Districts: false
        // [[Prototype]]: Object

        // test if every admin layer is not visible
        const allFalse = Object.values(admin_vis).every(value => value === false);

        //console.log(allFalse);  // Output: false

        if (allFalse === true) {
          $('#CountriesDiv, #CountiesDiv, #DistrictsDiv, #modalRegionSaveButton').hide();
          $('#noAdminVisibleDiv').show();
        } else {
          $('#CountriesDiv, #CountiesDiv, #DistrictsDiv, #modalRegionSaveButton').show();
          $('#noAdminVisibleDiv').hide();
        }




        // set according checkboxes in selectRegionModal to disabled,
        // if layer is not visible
        admin_lyrs.forEach(function (lyr) {
          //console.log(lyr);
          //console.log(admin_vis[lyr]);
          if (admin_vis[lyr] === true) {
            //console.log(lyr + " checked");
            $("#" + lyr).removeAttr("disabled");
          } else {
            // if layer is not visible, disable and uncheck radio button
            //console.log(lyr + " unchecked");
            $("#" + lyr).attr("disabled", true);
            $("#" + lyr).removeAttr("checked");
          }
        });


        $('#selectRegionModal').modal('toggle');
      }


    } else if ($(this).attr("class").includes("active")) {
      //console.log("WAS ACTIVE");
      $(this).removeClass("active");
      $(this).addClass("passive");
      $(this).html('Select Region <i class="fa-solid fa-map-location-dot" id="regionIcon"></i>');

      // remove all styles so no layer is selected anymore
      // clear selected layers
      // set style standard for all
      Overlays.getLayers().forEach(function (lyr) {
        switch (lyr["values_"]["title"]) {
          case "Countries":
            //console.log("COUNTRIES")
            //console.log(lyr);
            lyr.getSource().forEachFeature(function (feat) {
              feat.setStyle(CountriesStyle);
            })
            break;
          case "Counties":
            lyr.getSource().forEachFeature(function (feat) {
              feat.setStyle(CountiesStyle);
            })
            break;
          case "Districs":
            lyr.getSource().forEachFeature(function (feat) {
              feat.setStyle(DistricsStyle);
            })
            break;

          default:
            break;
        }
      });
    }

  }

});


// action when clicking on save changes button from select region modal
$("#modalRegionSaveButton").on("click", function () {
  admin_selected = [];
  //console.log("clicked save");

  admin_lyrs.forEach(function (lyr) {
    //console.log(lyr + " : " + $("#" + lyr).is(":checked"));
    if ($("#" + lyr).is(":checked")) {
      admin_selected = lyr;
    }
  });

  //console.log("selected layer is: " + admin_selected);

});





export { region_selected, admin_selected, selected, region_selected_single };