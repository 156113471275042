// analysis.js
import $ from 'jquery';
import { Layers, getActiveLayer, slider } from '../main';
import { region_selected, region_selected_single, admin_selected } from './selectRegion';
import Overlay from 'ol/Overlay';
import { toStringHDMS } from 'ol/coordinate';
import { map, view } from './layers';
//chart.js
import { Chart, registerables } from 'chart.js';

// date range slider librarys
import * as noUiSlider from 'nouislider';
import 'nouislider/dist/nouislider.css';

// date manipulation library
import { DateTime } from "luxon";


//var api_url = "http://landsurf.geo.uni-halle.de:8000";
var api_url = "https://landsurf.geo.uni-halle.de";


function generateDateLabels(startYear, endYear, timezone = 'local') {
    const labels = [];
    const startDate = DateTime.local(startYear, 1);
    const endDate = DateTime.local(endYear, 12);
    let currentDate = startDate;

    while (currentDate <= endDate) {
        labels.push(currentDate.toFormat('yyyy-MM'));
        currentDate = currentDate.plus({ months: 1 });
    }

    return labels;
}

function generateID(length) {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

// popup
var trend_values;

// range array generator
const range = (start, stop, step = 1) => Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + (i * step));

function getDatasetLabels(layer) {
    var labels;

    if (layer.type == 'recent') {
        labels = range(1981, 2010);
    } if (layer.type == 'projection') {
        switch (layer.model) {
            case 'rcm': //354 / 4
                labels = range(1981, 2098);
                break;
            case 'gcm':
                base = 120;
                labels = range(1981, 2100);
                break;
        }
    }

    return labels;
};

function getDatasetBreaks(layer, num_datasets = 0) {
    var base;
    var breaks;

    /////////// RECENT
    if (layer.type == 'recent') {
        breaks = [0, 30, 60, 90, 120]
        /////////// PROJECTION
    } else if (layer.type == 'projection') {
        if (layer.class == 'crop') {
            switch (layer.model) {
                case 'rcm': //354 / 4
                    base = 118;
                    break;
                case 'gcm':
                    base = 120;
                    break;
            }
        } else {
            switch (layer.model) {
                case 'rcm': //354 / 4
                    base = 119;
                    break;
                case 'gcm':
                    base = 120;
                    break;
            }
        }


        ///// absolute
        if (layer.typeProjection == 'abs') {
            breaks = [];
            breaks[0] = [0, 1 * base, 2 * base, 3 * base, 4 * base, 5 * base, 6 * base, 7 * base, 8 * base];
            breaks[1] = [num_datasets * base, (num_datasets + 1) * base, (num_datasets + 2) * base, (num_datasets + 3) * base, (num_datasets + 4) * base, (num_datasets + 5) * base, (num_datasets + 6) * base, (num_datasets + 7) * base, (num_datasets + 8) * base];
            breaks[2] = [(2 * num_datasets) * base, ((2 * (num_datasets)) + 1) * base, ((2 * (num_datasets)) + 2) * base, ((2 * (num_datasets)) + 3) * base, ((2 * (num_datasets)) + 4) * base, ((2 * (num_datasets)) + 5) * base, ((2 * (num_datasets)) + 6) * base, ((2 * (num_datasets)) + 7) * base, ((2 * (num_datasets)) + 8) * base];
            // difference
        } else if (layer.typeProjection == 'diff') {
            breaks = [0, base * 1, base * 2, base * 3, base * 4, base * 5, base * 6, base * 7, base * 8, base * 9];
        }
    }




    return breaks;
}



//////// function to get border color dependend on stage
function getDroughtBorderColor(drought, scenario, type, datatype) {

    if (datatype == 'recent') {
        switch (drought) {
            case '03':
                return '#3469C8'
            case '06':
                return '#0BA637'
            case '09':
                return '#C6C630'
            case '12':
                return '#D38600'
        }
    } else if (datatype == 'absolute') {
        if (type == 'mean') {
            if (scenario == 'rcp26') {
                switch (drought) {
                    case '03':
                        return '#3469C8'
                    case '06':
                        return '#0BA637'
                    case '09':
                        return '#C6C630'
                    case '12':
                        return '#D38600'
                }
            } else if (scenario == 'rcp85') {
                switch (drought) {
                    case '03':
                        return '#4f1eb6'
                    case '06':
                        return '#2a5b07'
                    case '09':
                        return '#979e48'
                    case '12':
                        return '#855604'
                }
            }
        } else if (type == 'min' || type == 'max') {
            if (scenario == 'rcp26') {
                switch (drought) {
                    case '03':
                        return '#b3c7ea80'
                    case '06':
                        return '#a4deb480'
                    case '09':
                        return '#eaeab180'
                    case '12':
                        return '#efd29f80'
                }
            } else if (scenario == 'rcp85') {
                switch (drought) {
                    case '03':
                        return '#bdabe480'
                    case '06':
                        return '#afc1a280'
                    case '09':
                        return '#d8dbba80'
                    case '12':
                        return '#d1c0a180'
                }
            }
        }
    } else if (datatype == 'difference') {
        if (scenario == 'rcp26') {
            switch (drought) {
                case '03':
                    return '#3469C8'
                case '06':
                    return '#0BA637'
                case '09':
                    return '#C6C630'
                case '12':
                    return '#D38600'
            }
        } else if (scenario == 'rcp85') {
            switch (drought) {
                case '03':
                    return '#4f1eb6'
                case '06':
                    return '#2a5b07'
                case '09':
                    return '#979e48'
                case '12':
                    return '#855604'
            }
        }
    }
}


function getSeasonBorderColor(season, scenario, type, datatype) {
    /////////// recent
    if (datatype == 'recent') {
        switch (season) {
            case 'ons':
                return '#00b433'
            case 'ces':
                return '#0061c5'
        }
        ///////////// absolute
    } else if (datatype == 'absolute') {
        if (type == 'mean') {
            if (scenario == 'rcp26') {
                switch (season) {
                    case 'ons':
                        return '#00b433'
                    case 'ces':
                        return '#0061c5'
                }
            } else if (scenario == 'rcp85') {
                switch (season) {
                    case 'ons':
                        return '#43803b'
                    case 'ces':
                        return '#437f95'
                }
            }
        } else if (type == 'min' || type == 'max') {
            if (scenario == 'rcp26') {
                switch (season) {
                    case 'ons':
                        return '#00b43360'
                    case 'ces':
                        return '#0061c560'
                }
            } else if (scenario == 'rcp85') {
                switch (season) {
                    case 'ons':
                        return '#43803b60'
                    case 'ces':
                        return '#437f9560'
                }
            }
        }
        ///////////// difference
    } else if (datatype == 'difference') {
        if (scenario == 'rcp26') {
            switch (season) {
                case 'ons':
                    return '#00b433'
                case 'ces':
                    return '#0061c5'
            }
        } else if (scenario == 'rcp85') {
            switch (season) {
                case 'ons':
                    return '#43803b'
                case 'ces':
                    return '#437f95'
            }
        }
    }
}



//////// function to get border color dependend on stage
function getCropBorderColorAbsolute(num, type) {

    if (type == 'mean') {
        switch (num) {
            case 0:
                return '#36A2EB';
            case 1:
                return '#FF6384';
            case 2:
                return '#FFCF5D';
            case 3:
                return '#34AD37';
        }
    } if (type == 'min' || type == 'max') {
        switch (num) {
            case 0:
                return '#BCE4FF85';
            case 1:
                return '#FFC4D185';
            case 2:
                return '#FCE6B285';
            case 3:
                return '#C3FCC485';
        }
    }

}



//////// function to get border color dependend on stage
function getCropBorderColor(crop, scenario, type, datatype) {

    /////////////// RECENT /////////////////
    if (datatype == 'recent') {
        switch (crop) {
            case 'Barley_Oats_Wheat_S':
                return '#fcdd19'
            case 'Maize_grain_S':
                return '#ff9232'
            case 'Maize_sweet_S':
                return '#ff1737'
            case 'Millet_S':
                return '#06ef30'
            case 'Sorghum_S':
                return '#177cff'
            case 'Soybean_S':
                return '#ba26ff'

            case 'Barley_Oats_Wheat_L':
                return '#b5a120'
            case 'Maize_grain_L':
                return '#a85409'
            case 'Maize_sweet_L':
                return '#9b0b1f'
            case 'Millet_L':
                return '#098d20'
            case 'Sorghum_L':
                return '#225aa2'
            case 'Soybean_L':
                return '#700b9f'
        }
        /////////////// PROJECTION /////////////////
    } else if (datatype == 'difference') {
        if (scenario == 'rcp26') {
            switch (crop) {
                case 'Barley_Oats_Wheat_S':
                    return '#fcdd19'
                case 'Maize_grain_S':
                    return '#ff9232'
                case 'Maize_sweet_S':
                    return '#ff1737'
                case 'Millet_S':
                    return '#06ef30'
                case 'Sorghum_S':
                    return '#177cff'
                case 'Soybean_S':
                    return '#ba26ff'

                case 'Barley_Oats_Wheat_L':
                    return '#b5a120'
                case 'Maize_grain_L':
                    return '#a85409'
                case 'Maize_sweet_L':
                    return '#9b0b1f'
                case 'Millet_L':
                    return '#098d20'
                case 'Sorghum_L':
                    return '#225aa2'
                case 'Soybean_L':
                    return '#700b9f'
            }
        } else if (scenario == 'rcp85') {
            switch (crop) {
                case 'Barley_Oats_Wheat_S':
                    return '#F6E50150'
                case 'Maize_grain_S':
                    return '#E87F0050'
                case 'Maize_sweet_S':
                    return '#C9391650'
                case 'Millet_S':
                    return '#019BA750'
                case 'Sorghum_S':
                    return '#089C0050'
                case 'Soybean_S':
                    return '#CB02C850'

                case 'Barley_Oats_Wheat_L':
                    return '#A89D0050'
                case 'Maize_grain_L':
                    return '#9C560050'
                case 'Maize_sweet_L':
                    return '#7D240E50'
                case 'Millet_L':
                    return '#01535950'
                case 'Sorghum_L':
                    return '#044F0050'
                case 'Soybean_L':
                    return '#6E016C50'
            }
        }
    }

}





function createCanvas() {
    // Create the canvas element
    var canvas = document.createElement('canvas');
    canvas.id = "chartCanvas_" + generateID(5);
    canvas.className = "chart-canvas";

    var canvasDiv = document.createElement('div');
    canvasDiv.className = 'chart-and-button-container';

    // Add the canvas to your container div
    var chartContainer = document.getElementById('panelChartWrapper');
    chartContainer.appendChild(canvasDiv);
    canvasDiv.appendChild(canvas);


    //console.log(canvas);
    // Get the context of the newly created canvas
    return canvas;
}


function createCanvasPopup() {

    try {
        // destroy old canvas
        $('.chart-canvas-popup').remove();
    } catch (error) {

    }


    // Create the canvas element
    var canvas = document.createElement('canvas');
    canvas.id = "chartCanvas_" + generateID(5);
    canvas.className = "chart-canvas-popup";

    var canvasDiv = document.createElement('div');
    canvasDiv.className = 'chart-and-button-container-popup';

    // Add the canvas to your container div
    var chartContainer = document.getElementById('panelChartWrapperPopup');
    chartContainer.appendChild(canvasDiv);
    canvasDiv.appendChild(canvas);


    //console.log(canvas);
    // Get the context of the newly created canvas
    return canvas;
}


function createAndPositionButton(context, buttonId, iconClass, type) {

    $('#destroyChartButtonPopup, #downloadChartButtonPopup').remove();

    // Create button element 
    const button = document.createElement('BUTTON');
    button.id = buttonId;

    switch (type) {
        case 'panel':
            button.classList.add('chart-button');
            break;
        case 'popup':
            button.classList.add('chart-button-popup');
            break;
    }

    if (buttonId == 'destroyChartButton' || buttonId == 'destroyChartButtonPopup') {
        button.classList.add('destroy-chart-button');
    }


    button.style.position = 'relative';

    // Add icon
    const icon = document.createElement('i');
    icon.id = buttonId + "Icon";
    // Split the icon class and add them individually:
    const iconClasses = iconClass.split(" "); // Split by spaces
    iconClasses.forEach(className => icon.classList.add(className));
    //icon.classList.add(iconClass);
    button.appendChild(icon);

    // Attach button as child to chart canvas wrapper  
    const chartWrapper = context.canvas.parentNode;
    chartWrapper.appendChild(button);

    //console.log(button);
    //console.log(icon);
    //console.log(chartWrapper);


    switch (type) {
        case 'panel':
            // // Adjust positions for different buttons as needed 
            if (buttonId === 'destroyChartButton') {
                button.style.bottom = '345px';
            } else if (buttonId === 'downloadChartButton') {
                button.style.bottom = '368px';
                button.style.right = '32px';
            }
            break;

        case 'popup':

            break;
    }


}


function createTimeseriesConf() {

    let timeseries_conf = {
        type: 'line',
        data: "",
        options: {
            responsive: true,
            scales: {
                y: ""
            },
            plugins: {
                title: {
                    display: true,
                    text: "",
                },
                legend: {
                    display: true,
                    labels: {
                        filter: function (legendItem, chartData) {
                            //console.log(legendItem.text);
                            if (legendItem.text.includes('mean')) {
                                //console.log(legendItem)
                                return legendItem.text;
                            }
                            //return legendItem.text === "mean"; // Only show "mean" in the legend
                        }
                    }
                },
                tooltip: {
                    enabled: true
                }
            },
            elements: {
                point: {
                    hitRadius: 10,
                    hoverRadius: 10,
                    borderWidth: 0,
                    radius: 0
                }
            },
            // callback function on resize
            onResize: (context) => {
                // get buttons
                createAndPositionButton(context, 'destroyChartButton', 'fa-solid fa-xmark', 'panel');
                createAndPositionButton(context, 'downloadChartButton', 'fa-solid fa-download', 'panel');


            },

        }
    }

    return timeseries_conf;
}


function createTimeseriesConfPopup() {

    let timeseries_conf_popup = {
        type: 'line',
        data: "",
        options: {
            responsive: true,
            scales: {
                // y: ""
            },
            plugins: {
                title: {
                    display: true,
                    text: "",
                },
                legend: {
                    display: true,
                },
            },
            elements: {
                point: {
                    hitRadius: 10,
                    hoverRadius: 10,
                    borderWidth: 0,
                    radius: 0
                }
            },
            // callback function on resize
            onResize: (context) => {
                // get buttons
                createAndPositionButton(context, 'destroyChartButtonPopup', 'fa-solid fa-xmark', 'popup');
                createAndPositionButton(context, 'downloadChartButtonPopup', 'fa-solid fa-download', 'popup');
            },

        }
    }

    return timeseries_conf_popup;
}




//#####################################################################################################################//
/////////////////////////////////////////////////// TIMESERIES /////////////////////////////////////////////////////////

$("#timeseriesChartButton").on("click", function () {

    // get currently active layer
    let activeLayer = getActiveLayer(Layers);
    //console.log(activeLayer);

    if (!activeLayer) {
        alert("No indicator selected / visible.");
        return;
    }


    if (region_selected_single == "") {
        alert("No region was selected");
        return;
    }



    // open collapsible footer where chart is displayed
    $("#loaderContainer").show();
    $('#collapseOne').collapse("show");


    //console.log("AYAYAYAY!!!!!!!!!!");
    //console.log(admin_selected);
    //console.log(region_selected_single);

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////// TREND  ////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////
    var api = api_url + "/climate";


    var post_data = 'indicator=' + activeLayer.titleNoYear + "&indicator_short=" + activeLayer.indicator + "&type=" + activeLayer.type + "&typeRecent=" + activeLayer.typeRecent + "&typeProjection=" + activeLayer.typeProjection + "&model=" + activeLayer.model + "&scenario=" + activeLayer.scenario + "&region=" + region_selected_single + "&admin=" + admin_selected + "&x=0&y=0&plotType=panel";

    //console.log(post_data);
    // create post request with ajax
    // give selected layer, selected region and selected administration type as data with it
    $.ajax({
        type: 'POST',
        dataType: 'json',
        data: post_data,
        url: api,
        //username: 'user',
        //password: 'pass',
        crossDomain: true,
        xhrFields: {
            //withCredentials: true,
        },
    })
        // when post request is done
        .done(function (data) {
            //console.log('OUTPUT:');
            //console.log(data);
            // range generator

            var ctx = createCanvas();



            Chart.register(...registerables);


            ////////////////////////////////////////////////////////////////////////////////////////////////////////
            ///////////////// RECENT /////////////////////////////
            // different charts depending on type of data
            // if recent, just mean
            // if projection, split dataset in 3 (mean, min, max) to display bandwidth
            if (activeLayer.type == "recent") {
                //console.log("recent");

                var data_len = data.length
                //console.log(data_len);

                var data_mean = data
                //console.log(data_mean);

                // create year labels for plot
                var labels = range(1981, 1981 + data_len - 1);
                //console.log(labels);


                var chart_data = {
                    labels: labels,
                    datasets: [{
                        label: "mean",
                        data: data_mean,
                        fill: false,
                        borderColor: '#3469C8',
                        tension: 0.1,
                    }]
                };





                ////////////////////////////////////////////////////////////////////////////////////////////////////////
                ///////////////// PROJECTION /////////////////////////////
            } if (activeLayer.type == "projection") {
                //console.log("projection");

                ///////////////////////////////////////////////////////////////////////////////////////////////////////
                ///////////////// ABSOLUTE //////////////////////////////
                if (activeLayer.typeProjection == 'abs') {

                    //console.log("ABSOLUTE PLOT");
                    // get length of data for display
                    // min max and mean in one vector -> first mean, then min, then max
                    // have to subtract -2 from the data length, because at the end are two values (signal, trend)
                    // that are not directly shown in the diagram
                    var data_len = (data.length) / 3;
                    var data_len_all = data.length;
                    //console.log(data_len_all);
                    //console.log(data_len);

                    // get mean data
                    var data_mean = data.slice(0, data_len);
                    var data_min = data.slice(data_len, data_len + data_len);
                    var data_max = data.slice(data_len + data_len, data_len_all);

                    //console.log(data_mean);
                    //console.log(data_min);
                    //console.log(data_max);


                    // create year labels for plot
                    var labels = range(1981, 1981 + data_len - 1);
                    //console.log(labels);




                    var chart_data = {
                        labels: labels,
                        datasets: [{
                            label: "mean",
                            data: data_mean,
                            fill: false,
                            borderColor: '#3469C8',
                            tension: 0.1,
                        },
                        {
                            label: "ensmin",
                            data: data_min,
                            backgroundColor: '#b3c7ea80',
                            borderColor: '#b3c7ea80',
                            fill: false,
                            tension: 0.1,
                        },
                        {
                            label: "ensmax",
                            data: data_max,
                            backgroundColor: '#b3c7ea80',
                            borderColor: '#b3c7ea80',
                            fill: '-1',
                            tension: 0.1,
                        },
                        ]
                    };

                    var y_axis_config = {
                        beginAtZero: true
                    }

                }

                ////////////////////////////////////////////////////////////////////////////////////////////////////////
                ///////////////// DIFFERENCE /////////////////////////////
                if (activeLayer.typeProjection == 'diff') {
                    //console.log("DIFFERENCE PLOT");


                    // get length of data for display
                    // min max and mean in one vector -> first mean, then min, then max
                    // have to subtract -2 from the data length, because at the end are two values (signal, trend)
                    // that are not directly shown in the diagram
                    var data_len = data.length;
                    var data_len_all = data.length;
                    //console.log(data_len_all);
                    //console.log(data_len);

                    // get mean data
                    var data_mean = data.slice(0, data_len);

                    // create year labels for plot
                    var labels = range(1981, 1981 + data_len - 1);
                    //console.log(labels);

                    // get min and max values so you can create min and max values for the y scale
                    var data_min_val = Math.floor(Math.min(...data_mean));
                    var data_max_val = Math.ceil(Math.max(...data_mean));

                    //console.log(data_min_val);
                    //console.log(data_max_val);

                    // y scale should be same on both (plus and minus) sides
                    // so first decide which of the both values absolute is larger
                    var data_max_abs = Math.max(...[Math.abs(data_min_val), Math.abs(data_max_val)]);
                    //console.log(data_max_abs);

                    var chart_data = {
                        labels: labels,
                        datasets: [{
                            label: "ensmean",
                            data: data_mean,
                            fill: false,
                            borderColor: '#3469C8',
                            tension: 0.1,
                        },
                        ]
                    };

                    var y_axis_config = {
                        max: data_max_abs,
                        min: data_max_abs * -1
                    }


                }

            }


            var timeseries_conf = createTimeseriesConf();

            //chart needs to be global, so it can be accessed when clicking create chart button
            // so it can be destroyed if it already exists
            timeseries_conf.data = chart_data;
            //timeseries_conf.options.plugins.title.text = activeLayer.titleReadable + " " + activeLayer.getUnit() + ", " + region_selected;
            timeseries_conf.options.plugins.title.text = [activeLayer.getChartTitle()[0], activeLayer.getChartTitle()[1] + ", " + region_selected];
            timeseries_conf.options.scales.y = y_axis_config;

            window.timeseries_chart = new Chart(ctx, timeseries_conf);

            // disable loader
            $("#loaderContainer").hide();





        })
        // when request failed
        .fail(function (xhr, textStatus, errorThrown) {
            alert("Error creating timeseries chart.");
            //console.log(xhr.responseText);
            //console.log(textStatus);
            //console.log(errorThrown);
        });

})


//#######################################################################################################################################################//
//////////////////////////////////////////////////////////////////////                     ///////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////    COMPARE SCENARIOS   ///////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////                     //////////////////////////////////////////////////////////////////////
//#######################################################################################################################################################//
$("#compareChartButton").on("click", function () {

    // get currently active layer
    let activeLayer = getActiveLayer(Layers);
    //console.log(activeLayer);

    if (!activeLayer) {
        alert("No indicator selected / visible.");
        return;
    }

    if (region_selected_single == "") {
        alert("No region was selected");
        return;
    }


    // open collapsible footer where chart is displayed
    $("#loaderContainer").show();
    $('#collapseOne').collapse("show");


    //console.log("AYAYAYAY!!!!!!!!!!");
    //console.log(admin_selected);
    //console.log(region_selected_single);

    var api = api_url + "/climate";

    var post_data = 'indicator=' + activeLayer.titleNoYear + "&indicator_short=" + activeLayer.indicator + "&type=" + activeLayer.type + "&typeRecent=" + activeLayer.typeRecent + "&typeProjection=" + activeLayer.typeProjection + "&model=" + activeLayer.model + "&scenario=rcp26,rcp85" + "&region=" + region_selected_single + "&admin=" + admin_selected + "&x=0&y=0&plotType=panel";
    //console.log(post_data);
    // create post request with ajax
    // give selected layer, selected region and selected administration type as data with it
    $.ajax({
        type: 'POST',
        dataType: 'json',
        data: post_data,
        url: api,
        crossDomain: true,
        xhrFields: {
            //withCredentials: true,
        },
    })
        // when post request is done
        .done(function (data) {
            //console.log('OUTPUT:');
            //console.log(data);

            //var ctx = document.getElementById('timeseriesChartCanvas');
            var ctx = createCanvas();

            Chart.register(...registerables);

            ////////////////////////////////////////////////////////////////////////////////////////////////////////
            ///////////////// RECENT /////////////////////////////////
            // different charts depending on type of data
            // if recent, just mean
            // if projection, split dataset in 3 (mean, min, max) to display bandwidth
            if (activeLayer.type == "recent") {
                //console.log("recent");

                var data_len = data.length
                //console.log(data_len);

                var data_mean = data
                //console.log(data_mean);

                // create year labels for plot
                var labels = range(1981, 1981 + data_len - 1);
                //console.log(labels);


                var chart_data = {
                    labels: labels,
                    datasets: [{
                        label: "mean",
                        data: data_mean,
                        fill: false,
                        borderColor: '#3469C8',
                        tension: 0.1,
                    }]
                };

                ////////////////////////////////////////////////////////////////////////////////////////////////////////
                ///////////////// Projection /////////////////////////////
            } if (activeLayer.type == "projection") {
                //console.log("projection");

                ////////////////////////////////////////////////////////////////////////////////////////////////////////
                ///////////////// ABSOLUTE ///////////////////////////////
                if (activeLayer.typeProjection == 'abs') {

                    //console.log("ABSOLUTE PLOT");



                    // get length of data for display
                    // min max and mean in one vector -> first mean, then min, then max
                    // have to subtract -4 from the data length, because at the end are four values (signal, trend)
                    // for both scenarios that are not directly shown in the diagram
                    var data_len = (data.length) / 6;
                    var data_len_all = data.length;
                    //console.log(data_len_all);
                    //console.log(data_len);

                    // get data rcp26
                    var data_mean_26 = data.slice(0, data_len);
                    var data_mean_85 = data.slice(data_len, data_len * 2);
                    var data_min_26 = data.slice(data_len * 2, data_len * 3);
                    var data_min_85 = data.slice(data_len * 3, data_len * 4);
                    var data_max_26 = data.slice(data_len * 4, data_len * 5);
                    var data_max_85 = data.slice(data_len * 5, data_len_all);

                    // create year labels for plot
                    var labels = range(1981, 1981 + data_len - 1);
                    //console.log(labels);




                    var chart_data = {
                        labels: labels,
                        datasets: [
                            {
                                label: "ensmean rcp26",
                                data: data_mean_26,
                                fill: false,
                                borderColor: '#3469C8',
                                tension: 0.1,
                            },
                            {
                                label: "ensmean rcp85",
                                data: data_mean_85,
                                fill: false,
                                borderColor: '#E1AE42',
                                tension: 0.1,
                            },
                            {
                                label: "ensmin rcp85",
                                data: data_min_85,
                                backgroundColor: '#f4e1b895',
                                borderColor: '#f4e1b895',
                                fill: false,
                                tension: 0.1,
                            },
                            {
                                label: "ensmax rcp85",
                                data: data_max_85,
                                backgroundColor: '#f4e1b895',
                                borderColor: '#f4e1b895',
                                fill: '-1',
                                tension: 0.1,
                            },
                            {
                                label: "ensmin rcp26",
                                data: data_min_26,
                                backgroundColor: '#b3c7ea95',
                                borderColor: '#b3c7ea95',
                                fill: false,
                                tension: 0.1,
                            },
                            {
                                label: "ensmax rcp26",
                                data: data_max_26,
                                backgroundColor: '#b3c7ea95',
                                borderColor: '#b3c7ea95',
                                fill: '-1',
                                tension: 0.1,
                            },
                        ]
                    };

                    var y_axis_config = {
                        beginAtZero: true
                    }


                }

                ///////////////////////////////////////////////////////////////////////////////////////////////////////
                ///////////////// DIFFERENCE /////////////////////////////
                else if (activeLayer.typeProjection == 'diff') {

                    //console.log("DIFF PLOT");

                    // get length of data for display
                    // min max and mean in one vector -> first mean, then min, then max
                    // have to subtract -4 from the data length, because at the end are four values (signal, trend)
                    // for both scenarios that are not directly shown in the diagram
                    var data_len = (data.length) / 2;
                    var data_len_all = data.length;
                    //console.log(data_len_all);
                    //console.log(data_len);

                    // get data rcp26
                    var data_mean_26 = data.slice(0, data_len);
                    var data_mean_85 = data.slice(data_len, data_len * 2);

                    // create year labels for plot
                    var labels = range(1981, 1981 + data_len - 1);
                    //console.log(labels);


                    // get min and max values so you can create min and max values for the y scale
                    var data_min_26_val = Math.floor(Math.min(...data_mean_26));
                    var data_max_26_val = Math.ceil(Math.max(...data_mean_26));
                    var data_min_85_val = Math.floor(Math.min(...data_mean_85));
                    var data_max_85_val = Math.ceil(Math.max(...data_mean_85));


                    // y scale should be same on both (plus and minus) sides
                    // so first decide which of the both values absolute is larger
                    var data_max_abs = Math.max(...[Math.abs(data_min_26_val), Math.abs(data_max_26_val), Math.abs(data_max_85_val), Math.abs(data_min_85_val)]);
                    //console.log(data_max_abs);

                    var chart_data = {
                        labels: labels,
                        datasets: [{
                            label: "ensmean rcp26",
                            data: data_mean_26,
                            fill: false,
                            borderColor: '#3469C8',

                            tension: 0.1,
                        },
                        {
                            label: "ensmean rcp85",
                            data: data_mean_85,
                            fill: false,
                            borderColor: '#E1AE42',
                            tension: 0.1,
                        },
                        ]
                    };

                    var y_axis_config = {
                        max: data_max_abs,
                        min: data_max_abs * -1
                    }


                }


            }


            var timeseries_conf = createTimeseriesConf();
            //chart needs to be global, so it can be accessed when clicking create chart button
            // so it can be destroyed if it already exists
            timeseries_conf.data = chart_data;
            timeseries_conf.options.plugins.title.text = [activeLayer.getChartTitle(true)[0], activeLayer.getChartTitle(true)[1] + ", " + region_selected];
            timeseries_conf.options.scales.y = y_axis_config;
            window.timeseries_chart = new Chart(ctx, timeseries_conf);

            // disable loader
            $("#loaderContainer").hide();





        })
        // when request failed
        .fail(function (xhr, textStatus, errorThrown) {
            alert("Error creating timeseries chart.");
            //console.log(xhr.responseText);
            //console.log(textStatus);
            //console.log(errorThrown);
        });
})




////////////////////////// CSV DOWNLOAD FUNCTION ///////////////////////////////
function downloadCSV(args) {
    //console.log("converting to csv");
    //console.log(args.type);

    var data, filename, link;
    var csv = "";
    var keys = args.chart.data.labels;
    //var data = args.chart.data.datasets[0].data;

    //console.log(args.chart.data);
    //console.log(args.chart.data.datasets);
    //console.log(args.chart.data.datasets.length);
    //console.log(args.y);
    //console.log(args.x);
    //console.log(keys);
    //console.log(data);


    // define column and line delimiter
    var columnDelimiter = ',';
    var lineDelimiter = '\n';

    // create head of csv with x and y that are the year and name of the indicator
    // if projection -> also min and max in addition to mean
    if (args.type == "recent") {
        var csv = args.x + columnDelimiter + args.indicator + "_mean" + lineDelimiter;
    } else if (args.type == "projection") {
        var csv = args.x + columnDelimiter + args.indicator + "_mean" + columnDelimiter + args.indicator + "_min" + columnDelimiter + args.indicator + "_max" + lineDelimiter;
    }


    //console.log(csv);

    // loop through the data and create csv string
    for (let i = 0; i < args.chart.data.labels.length; i++) {
        //console.log(i);
        //console.log(keys[i]);
        //console.log(data[i]);
        csv += keys[i] + columnDelimiter

        for (let j = 0; j < args.chart.data.datasets.length; j++) {
            let data = args.chart.data.datasets[j].data
            //console.log(j);
            //console.log(data[i]);
            csv += data[i] + columnDelimiter;
        }

        csv += lineDelimiter;
    }

    //console.log("CSV:");
    //console.log(csv);

    if (csv == null) return;

    // get filename
    filename = args.filename || 'chart-data.csv';

    if (!csv.match(/^data:text\/csv/i)) {
        //console.log("NOT MATCHED");
        csv = 'data:text/csv;charset=utf-8,' + csv;
    }

    // encode to csv and get download link
    data = encodeURI(csv);
    link = document.createElement('a');
    link.setAttribute('href', data);
    link.setAttribute('download', filename);
    document.body.appendChild(link); // Required for FF
    link.click();
    document.body.removeChild(link);
}



//#######################################################################################################################################################//
//////////////////////////////////////////////////////////////////////              ///////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////    POPUP     ///////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////              //////////////////////////////////////////////////////////////////////
//#######################################################################################################################################################//

// set class of popup button active / passive to change color and activate popup
$("#popupButton").on("click", function () {

    if ($('#selectRegionButton').attr("class").includes('active')) {
        //console.log("SELECT REGION ACTVE")
        alert("Stop selecting a region before creating a popup by clicking on the \"Select Region\" Button");
    } else {
        //console.log("SELECT REGION PASSIVE")
        if ($(this).attr("class").includes("passive")) {
            $(this).removeClass("passive");
            $(this).addClass("active");
        }
        else if ($(this).attr("class").includes("active")) {
            $(this).removeClass("active");
            $(this).addClass("passive");
        }
    }
})


// Elements that make up the popup.
const container = document.getElementById("popup");
const content = document.getElementById("popup-content");
const closer = document.getElementById("popup-closer");

// Create an overlay to anchor the popup to the map.
const overlay = new Overlay({
    element: container,
});

// Add a click handler to hide the popup.
// @return {boolean} Don't follow the href.
closer.onclick = function () {
    overlay.setPosition(undefined);
    closer.blur();
    return false;
};




var info_btn = document.getElementById("popupButton");
// on map click event
// if info button passive, do nothing only render popup when active
// get coordinates and write them to popup div
// then show popup at coordinate position

//map.on("pointermove", function (evt) {

map.on("click", function (evt) {
    //console.log(info_btn.classList);
    if (info_btn.classList.contains("active")) {

        //console.log("POUPUP CLICK");

        $('.ol-popup').show();

        // get currently active layer
        let activeLayer = getActiveLayer(Layers);
        //console.log(activeLayer);

        // disable chart canvas
        $("#popupChartDiv").hide();
        // show spinner
        $("#loaderContainerPopup").show();

        // test if chart already exists and destroy if so, so new chart can be rendered instead
        if (typeof timeseries_chart_popup !== 'undefined') {
            timeseries_chart_popup.destroy();
            // $("#destroyChartButtonPopup").remove();
            // $("#downloadChartButtonPopup").remove();
            $('.chart-button-popup').remove()

            //$('#trendArrowPopup').remove();
        }


        var startYear;
        var endYear;
        // api depends on current visible active layer
        switch (activeLayer.class) {
            case "climate":
                var api = api_url + "/climate";
                break;
            case "drought":
                var api = api_url + "/compareDrought";
                break;
            case "crop":
                var api = api_url + "/compareCrops";
                break;
            case "season":
                var api = api_url + "/compareRainySeason";
                break;
            case "remote":
                startYear = slider.noUiSlider.get()[0]
                endYear = slider.noUiSlider.get()[1]
                var api = api_url + "/remoteSensing";
                break;
        }

        //console.log(startYear);
        //console.log(endYear);

        var popup_data = '';
        var popup_title = activeLayer.titleReadable;
        var popup_indicator = activeLayer.indicator;


        // get coordinates and coordinates string
        const coordinate = evt.coordinate;
        const hdms = toStringHDMS(coordinate);

        window.x = coordinate[0];
        window.y = coordinate[1];

        //console.log(coordinate);
        //console.log(hdms);

        // get view resolution, necessary for getFeatureInfoUrl
        const viewResolution = /** @type {number} */ (view.getResolution());


        // get layer source
        var src = activeLayer.layerSource;
        //console.log(src);

        // get url with feature info from image source
        var layer_url = src.getFeatureInfoUrl(
            evt.coordinate,
            viewResolution,
            "EPSG:4326",
            { INFO_FORMAT: "application/json" }
        );

        // create popup html
        var popup_html = "";


        //popup_html += activeLayer.indicator + " - " + activeLayer.indicator_long_en + "<br>" + activeLayer.typeProjectionLong_en + '<br>' + Math.round(data) + " " + activeLayer.getUnit() + "<br>" + " lon: " + Math.round(x * 1000) / 1000 + " lat: " + Math.round(y * 1000) / 1000;
        popup_html += "lon: " + Math.round(x * 1000) / 1000 + " lat: " + Math.round(y * 1000) / 1000;

        // finish html and add to popup content
        content.innerHTML = popup_html;


        // // stop here if graph is for crops
        // if (activeLayer.class == "crop") {
        //     alert("Trendline for crop indicators not supported in current prototype version.");
        //     return;
        // }

        //////////////////////////////////////////////////////////////////////////////////////////////
        ///////////////////////////////////////// GRAPH //////////////////////////////////////////////
        //////////////////////////////////////////////////////////////////////////////////////////////


        // api endpoint depending on current visible active layer
        switch (activeLayer.class) {
            case "climate":
                var post_data_popup = 'indicator=' + activeLayer.titleNoYear + "&indicator_short=" + activeLayer.indicator + "&type=" + activeLayer.type + "&typeRecent=" + activeLayer.typeRecent + "&typeProjection=" + activeLayer.typeProjection + "&model=" + activeLayer.model + "&scenario=" + activeLayer.scenario + "&region=&admin=" + "&x=" + x + "&y=" + y + "&plotType=popup";
                break;
            case "drought":
                var post_data_popup = 'indicator=' + activeLayer.titleNoYear + "&indicator_short=" + activeLayer.indicator + "&type=" + activeLayer.type + "&typeRecent=" + activeLayer.typeRecent + "&typeProjection=" + activeLayer.typeProjection + "&model=" + activeLayer.model + "&region=&admin=" + "&scenario=" + activeLayer.scenario + "&drought=" + activeLayer.droughtType + "&x=" + x + "&y=" + y + "&plotType=popup";
                break;
            case "crop":
                //console.log(activeLayer.cropOrg + "_" + activeLayer.cropType);
                var post_data_popup = 'indicator=' + activeLayer.titleNoYear + "&indicator_short=" + activeLayer.indicator + "&type=" + activeLayer.type + "&typeRecent=" + activeLayer.typeRecent + "&typeProjection=" + activeLayer.typeProjection + "&model=" + activeLayer.model + "&scenario=" + activeLayer.scenario + "&region=&admin=" + "&crop=" + activeLayer.cropOrg + "_" + activeLayer.cropType + "&cropType=" + activeLayer.cropType + "&cropStage=" + activeLayer.cropStage + "&x=" + x + "&y=" + y + "&plotType=popup";
                break;
            case "season":
                var post_data_popup = 'indicator=' + activeLayer.titleNoYear + "&indicator_short=" + activeLayer.indicator + "&type=" + activeLayer.type + "&typeRecent=" + activeLayer.typeRecent + "&typeProjection=" + activeLayer.typeProjection + "&model=" + activeLayer.model + "&region=&admin=" + "&scenario=" + activeLayer.scenario + "&season=" + activeLayer.seasonType + "&x=" + x + "&y=" + y + "&plotType=popup";
                break;
            case "remote":
                var post_data_popup = 'indicator=' + activeLayer.titleNoYear + "&region=&admin=&startYear=" + startYear + "&endYear=" + endYear + "&x=" + x + "&y=" + y + "&plotType=popup";
                break;
        }

        //console.log(api);
        //console.log(post_data_popup);

        $.ajax({
            type: 'POST',
            dataType: 'json',
            data: post_data_popup,
            url: api,
            //username: 'user',
            //password: 'pass',
            crossDomain: true,
            xhrFields: {
                //withCredentials: true,
            },
        })
            // when post request is done
            .done(function (data) {
                //console.log('OUTPUT:');
                //console.log(data);

                var ctx = createCanvasPopup();

                Chart.register(...registerables);

                // different charts depending on type of data
                // if recent, just mean
                // if projection, split dataset in 3 (mean, min, max) to display bandwidth
                if (activeLayer.type == "recent") {
                    //console.log("recent");

                    var data_len = data.length
                    //console.log(data_len);

                    var data_mean = data
                    //console.log(data_mean);

                    // create year labels for plot
                    if (activeLayer.class == 'remote') {
                        var labels = generateDateLabels(Number(startYear), Number(endYear));
                    } else {
                        var labels = range(1981, 1981 + data_len - 1);
                    }

                    //console.log(labels);


                    var chart_data = {
                        labels: labels,
                        datasets: [{
                            label: "mean",
                            data: data_mean,
                            fill: false,
                            borderColor: '#3469C8',
                            tension: 0.1,
                        }]
                    };

                } if (activeLayer.type == "projection") {
                    //console.log("projection");

                    if (activeLayer.typeProjection == 'abs') {
                        //console.log("ABSOLUTE PLOT");

                        // get length of data for display
                        // min max and mean in one vector -> first mean, then min, then max
                        // have to subtract -2 from the data length, because at the end are two values (signal, trend)
                        // that are not directly shown in the diagram
                        var data_len = (data.length - 2) / 3;
                        var data_len_all = data.length;
                        //console.log(data_len_all);
                        //console.log(data_len);

                        // get mean data
                        var data_mean = data.slice(0, data_len);
                        var data_min = data.slice(data_len, data_len + data_len);
                        var data_max = data.slice(data_len + data_len, data_len_all - 2);

                        var data_trend = data.slice(-2, -1);
                        var data_signal = data.slice(-1);

                        //console.log(data_mean);
                        //console.log(data_min);
                        //console.log(data_max);

                        //console.log(data_trend);
                        //console.log(data_signal);

                        trend_values = [];
                        trend_values[0] = data_trend;
                        trend_values[1] = data_signal


                        // create year labels for plot
                        var labels = range(1981, 1981 + data_len - 1);
                        //console.log(labels);




                        var chart_data = {
                            labels: labels,
                            datasets: [{
                                label: "ensmean",
                                data: data_mean,
                                fill: false,
                                borderColor: '#3469C8',
                                tension: 0.1,
                            },
                            {
                                label: "ensmin",
                                data: data_min,
                                backgroundColor: '#b3c7ea80',
                                borderColor: '#b3c7ea80',
                                fill: false,
                                tension: 0.1,
                            },
                            {
                                label: "ensmax",
                                data: data_max,
                                backgroundColor: '#b3c7ea80',
                                borderColor: '#b3c7ea80',
                                fill: '-1',
                                tension: 0.1,
                            },
                            ]
                        };

                        var y_axis_config = {
                            beginAtZero: true
                        }
                    }

                    if (activeLayer.typeProjection == 'diff') {
                        //console.log("DIFFERENCE PLOT");
                        // get length of data for display
                        // min max and mean in one vector -> first mean, then min, then max
                        // have to subtract -2 from the data length, because at the end are two values (signal, trend)
                        // that are not directly shown in the diagram
                        var data_len = data.length - 2;
                        var data_len_all = data.length;
                        //console.log(data_len_all);
                        //console.log(data_len);

                        // get mean data
                        var data_mean = data.slice(0, data_len);

                        var data_trend = data.slice(-2, -1);
                        var data_signal = data.slice(-1);

                        //console.log(data_mean);
                        //console.log(data_trend);
                        //console.log(data_signal);

                        trend_values = [];
                        trend_values[0] = data_trend;
                        trend_values[1] = data_signal


                        // create year labels for plot
                        var labels = range(1981, 1981 + data_len - 1);
                        //console.log(labels);

                        // get min and max values so you can create min and max values for the y scale
                        var data_min_val = Math.floor(Math.min(...data_mean));
                        var data_max_val = Math.ceil(Math.max(...data_mean));

                        //console.log(data_min_val);
                        //console.log(data_max_val);

                        // y scale should be same on both (plus and minus) sides
                        // so first decide which of the both values absolute is larger
                        var data_max_abs = Math.max(...[Math.abs(data_min_val), Math.abs(data_max_val)]);
                        //console.log(data_max_abs);



                        var chart_data = {
                            labels: labels,
                            datasets: [{
                                label: "ensmean",
                                data: data_mean,
                                fill: false,
                                borderColor: '#3469C8',
                                tension: 0.1,
                            },
                            ]
                        };


                        var y_axis_config = {
                            max: data_max_abs,
                            min: data_max_abs * -1
                        }


                    }
                }





                // disable loader
                $("#loaderContainerPopup").hide();
                // enable chart canvas
                $("#popupChartDiv").show();

                var timeseries_conf_popup = createTimeseriesConfPopup();

                timeseries_conf_popup.data = chart_data;

                // create year labels for plot
                if (activeLayer.class == 'remote') {
                    timeseries_conf_popup.options.plugins.title.text = [activeLayer.getChartTitle(true)[0], region_selected + " " + popup_html];
                } else {
                    timeseries_conf_popup.options.plugins.title.text = [activeLayer.getChartTitle(true)[0], activeLayer.getChartTitle(true)[1] + ", " + region_selected + " " + popup_html];
                }

                timeseries_conf_popup.options.scales.y = y_axis_config;
                //chart needs to be global, so it can be accessed when clicking create chart button
                // so it can be destroyed if it already exists
                window.timeseries_chart_popup = new Chart(ctx, timeseries_conf_popup);







            })
            // when request failed
            .fail(function (xhr, textStatus, errorThrown) {
                alert("Error creating timeseries chart.");
                //console.log(xhr.responseText);
                //console.log(textStatus);
                //console.log(errorThrown);
            });


        overlay.setPosition(coordinate);
        map.addOverlay(overlay);

        // draw overlay

        //empty data array at end of event
        popup_data = [];
        popup_title = [];

    } else if (info_btn.classList.contains("passive")) {
        //console.log("button inactive, no popup");
    }


    var chartCanvasPopup;

    // if downloadChartButtonPopupPopup is clicked
    $(".chart-wrapper-popup").on("click", "#downloadChartButtonPopup", function () {

        var chartContainer = $(this).closest('.chart-and-button-container-popup');
        chartCanvasPopup = chartContainer.find('.chart-canvas-popup');

        $('#downloadChartModalPopup').modal('toggle');
    });

    ////// actual buttons to download data or image
    $("#downloadChartImageBtnPopup").on("click", function () {

        //console.log(Chart);

        var chartInstance = Chart.getChart(chartCanvasPopup[0]);
        //console.log(chartCanvasPopup);
        //console.log(chartInstance);

        var title = chartInstance.options.plugins.title.text;
        var a = document.createElement('a');
        a.href = chartInstance.toBase64Image();
        a.download = 'timeseries ' + title + '.png';
        a.click();

        try {
            stopPropagation();
        } catch (error) {
        }


        // close modal
        //$('#downloadChartModal').modal('toggle');
    });

});








//#######################################################################################################################################################//
//////////////////////////////////////////////////////////////////////                     ///////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////    COMPARE STAGES   ///////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////                     //////////////////////////////////////////////////////////////////////
//#######################################################################################################################################################//


// check which stages / scenario checkboxes are checked when creating charts for stages
// dont allow every checkbox to be checked, so you only have a maximum of 4 datasets in the chart
$('.form-chart-stage, #compareCropStagesButton').on('change', function () {
    //console.log("CHART FORM CLICK");

    var stages_checked = [];
    var scenario_checked = [];

    stages_checked = $('.form-check-input-stage:checked').map(function () {
        return this.value;
    }).get();
    scenario_checked = $('.form-check-input-stage-scenario:checked').map(function () {
        return this.value;
    }).get();


    var stages_len = stages_checked.length;
    var scenario_len = scenario_checked.length;

    //console.log(stages_len);
    //console.log(scenario_len);

    if (stages_len == 0 && scenario_len == 0) {
        $('#selectCheckCropStages').css('display', 'block');
        $('#selectCheckCropStages').text('Select at least one stage and scenario.')
        $("#createCompareStagesChartButton").addClass('no-pointer-events');
    } else if (stages_len == 0 && scenario_len != 0) {
        $('#selectCheckCropStages').css('display', 'block');
        $('#selectCheckCropStages').text('Select at least one stage.')
        $("#createCompareStagesChartButton").addClass('no-pointer-events');
    } else if (stages_len != 0 && scenario_len == 0) {
        $('#selectCheckCropStages').css('display', 'block');
        $('#selectCheckCropStages').text('Select at least one scenario.')
        $("#createCompareStagesChartButton").addClass('no-pointer-events');
    } else {
        $('#selectCheckCropStages').css('display', 'none');
        $('#selectCheckCropStages').text('')
        $("#createCompareStagesChartButton").removeClass('no-pointer-events');
    }

    let activeLayer = getActiveLayer(Layers);
    var max_dataset_size;
    var max_html;

    if (activeLayer.type == 'projection' && activeLayer.typeProjection == 'abs') {
        max_dataset_size = 2;
        max_html = 'When analyzing absolute projected data, select a maximum of 2 datasets';
    } else {
        max_dataset_size = 4;
        max_html = 'Select a maximum of 4 datasets';
    }

    var num_stages_datasets = stages_len * scenario_len;
    //console.log(num_stages_datasets);

    if (num_stages_datasets > max_dataset_size) {
        //console.log("greater than 4");
        $('#numDatasetsCropStages').css('display', 'block');
        $('#numDatasetsCropStages').text(max_html + ', currently selected: ' + num_stages_datasets);
        $("#createCompareStagesChartButton").addClass('no-pointer-events');
        return; // Exit the function immediately
    } else {
        $('#numDatasetsCropStages').text('');
        $('#numDatasetsCropStages').css('display', 'none');
        $("#createCompareStagesChartButton").removeClass('no-pointer-events');
    }


})


$('#compareCropStagesButton').on('click', function () {
    //console.log("click");

    // get currently active layer
    let activeLayer = getActiveLayer(Layers);
    //console.log(activeLayer);

    if (!activeLayer) {
        alert("No indicator selected / visible.");
        return;
    }

    if (region_selected_single == "") {
        alert("No region was selected");
        return;
    }

    //console.log(activeLayer.typeProjection);
    if (activeLayer.typeProjection == "trend") {
        alert('Trendline cannot be created for data of type "trend"');
        return;
    }

    // hide scenario selection if recent data was selected
    if (activeLayer.type == 'recent') {
        $('stageChartScenarioSelect').hide();
    } else {
        $('stageChartScenarioSelect').show();
    }


    $('#compareStagesModal').modal('toggle');

})


$("#createCompareStagesChartButton").on("click", function () {

    // get currently active layer
    let activeLayer = getActiveLayer(Layers);
    //console.log(activeLayer);

    var api = api_url + "/compareStages";


    var stages_checked = [];
    var scenario_checked = [];

    stages_checked = $('.form-check-input-stage:checked').map(function () {
        return this.value;
    }).get();
    scenario_checked = $('.form-check-input-stage-scenario:checked').map(function () {
        return this.value;
    }).get();

    //console.log(stages_checked);
    //console.log(scenario_checked);



    // open collapsible footer where chart is displayed
    $("#loaderContainer").show();
    $('#collapseOne').collapse("show");

    //console.log(admin_selected);
    //console.log(region_selected_single);


    switch (activeLayer.type) {
        case 'recent':
            var post_data = 'indicator=' + activeLayer.titleNoYear + "&indicator_short=" + activeLayer.indicator + "&type=" + activeLayer.type + "&typeRecent=" + activeLayer.typeRecent + "&typeProjection=" + activeLayer.typeProjection + "&model=" + activeLayer.model + "&scenario=" + "none" + "&region=" + region_selected_single + "&admin=" + admin_selected + "&crop=" + activeLayer.cropOrg + "&cropType=" + activeLayer.cropType + "&cropStage=" + stages_checked + "&x=&y=&plotType=panel";
            break;

        case 'projection':
            var post_data = 'indicator=' + activeLayer.titleNoYear + "&indicator_short=" + activeLayer.indicator + "&type=" + activeLayer.type + "&typeRecent=" + activeLayer.typeRecent + "&typeProjection=" + activeLayer.typeProjection + "&model=" + activeLayer.model + "&scenario=" + scenario_checked + "&region=" + region_selected_single + "&admin=" + admin_selected + "&crop=" + activeLayer.cropOrg + "&cropType=" + activeLayer.cropType + "&cropStage=" + stages_checked + "&x=&y=&plotType=panel";
            break;
    }


    //console.log(post_data);




    //###############################################################################################################///
    /////////////////////////////////////////     AJAX POST DATA         //////////////////////////////////////////////
    //##############################################################################################################//


    $.ajax({
        type: 'POST',
        dataType: 'json',
        data: post_data,
        url: api,
        crossDomain: true,
        xhrFields: {
            //withCredentials: true,
        },
    })
        // when post request is done
        .done(function (data) {
            //console.log('OUTPUT:');
            //console.log(data);

            var ctx = createCanvas();

            Chart.register(...registerables);




            ////////////////////////////////////////////////////////////////////////////////////////////////////////
            ///////////////// RECENT /////////////////////////////
            // different charts depending on type of data
            // if recent, just mean
            // if projection, split dataset in 3 (mean, min, max) to display bandwidth
            if (activeLayer.type == "recent") {
                //console.log("recent");

                ///// CHART DATA
                // intialize chart data
                // create year labels for plot
                var labels = getDatasetLabels(activeLayer);

                var chart_data = {
                    labels: labels,
                    datasets: []
                };

                var data_len = data.length;
                var num_datasets = stages_checked.length;
                //console.log(data_len);
                //console.log(num_datasets);

                var datasets = []
                var dataset_breaks = getDatasetBreaks(activeLayer);


                for (let i = 0; i < stages_checked.length; i++) {
                    //console.log(i)

                    datasets[i] = data.slice(dataset_breaks[i], dataset_breaks[i + 1]);

                    //console.log(datasets);

                    var newDataset = {
                        label: 'mean ' + stages_checked[i],
                        data: data.slice(dataset_breaks[i], dataset_breaks[i + 1]),
                        fill: false,
                        //borderColor: getStageBorderColor(stages_checked[i]),
                        tension: 0.1,
                    }
                    chart_data.datasets.push(newDataset);

                }


                var y_axis_config = {
                    beginAtZero: true
                }

                ////////////////////////////////////////////////////////////////////////////////////////////////////////
                ///////////////// PROJECTION /////////////////////////////
            } if (activeLayer.type == "projection") {
                //console.log("projection");

                ///////////////////////////////////////////////////////////////////////////////////////////////////////
                ///////////////// ABSOLUTE //////////////////////////////
                if (activeLayer.typeProjection == 'abs') {

                    //console.log("ABSOLUTE PLOT");


                    var data_len = data.length;
                    var num_datasets = stages_checked.length * scenario_checked.length;
                    //console.log(data_len);
                    //console.log(num_datasets);

                    var datasets_mean = [];
                    var datasets_min_max = [];

                    var labels = getDatasetLabels(activeLayer);
                    var breaks = getDatasetBreaks(activeLayer, num_datasets);
                    var dataset_breaks_mean = breaks[0];
                    var dataset_breaks_min = breaks[1];
                    var dataset_breaks_max = breaks[2];

                    ///// CHART DATA
                    // intialize chart data
                    // create year labels for plot
                    var chart_data = {
                        labels: labels,
                        datasets: []
                    };

                    //console.log(dataset_breaks_mean);
                    //console.log(dataset_breaks_min);
                    //console.log(dataset_breaks_max);



                    var k = 0;
                    for (let i = 0; i < stages_checked.length; i++) {
                        for (let j = 0; j < scenario_checked.length; j++) {
                            //console.log(k)
                            //console.log(i)
                            //console.log(stages_checked[i])
                            //console.log(j)
                            //console.log(scenario_checked[j])


                            // for display in chart, mean values without fill property must be on top of array that holds the datasets
                            // -> one array for mean values, one for min and max
                            // in the end combine them so e.g. with two layers you get: mean1, mean2, min1, max1, min2, max2

                            datasets_mean[k] = {
                                label: stages_checked[i] + "-" + scenario_checked[j] + " ensmean",
                                data: data.slice(dataset_breaks_mean[k], dataset_breaks_mean[k + 1]),
                                fill: false,
                                borderColor: getCropBorderColorAbsolute(k, 'mean'),
                                //backgroundColor: getCropBorderColorAbsolute(k, 'max'),
                                tension: 0.1,
                            };

                            datasets_min_max[k] = [{
                                label: stages_checked[i] + "-" + scenario_checked[j] + " ensmin",
                                data: data.slice(dataset_breaks_min[k], dataset_breaks_min[k + 1]),
                                fill: false,
                                borderColor: getCropBorderColorAbsolute(k, 'min'),
                                backgroundColor: getCropBorderColorAbsolute(k, 'min'),
                                tension: 0.1,
                            },
                            {
                                label: stages_checked[k] + "-" + scenario_checked[j] + " ensmax",
                                data: data.slice(dataset_breaks_max[k], dataset_breaks_max[k + 1]),
                                fill: '-1',
                                borderColor: getCropBorderColorAbsolute(k, 'max'),
                                backgroundColor: getCropBorderColorAbsolute(k, 'max'),
                                tension: 0.1,
                            }];


                            k++;
                        }
                    }

                    chart_data.datasets.push(datasets_mean);
                    chart_data.datasets.push(datasets_min_max.flat());

                    chart_data.datasets = chart_data.datasets.flat();

                    //console.log(chart_data)



                    var y_axis_config = {
                        beginAtZero: true
                    }

                }

                ////////////////////////////////////////////////////////////////////////////////////////////////////////
                ///////////////// DIFFERENCE /////////////////////////////
                if (activeLayer.typeProjection == 'diff') {
                    //console.log("DIFFERENCE PLOT");

                    var data_len = data.length;
                    var num_datasets = stages_checked.length;
                    //console.log(data_len);
                    //console.log(num_datasets);

                    var datasets = []

                    var dataset_breaks = getDatasetBreaks(activeLayer, num_datasets);
                    var labels = getDatasetLabels(activeLayer);

                    ///// CHART DATA
                    // intialize chart data
                    // create year labels for plot
                    var chart_data = {
                        labels: labels,
                        datasets: []
                    };




                    var k = 0;
                    for (let i = 0; i < stages_checked.length; i++) {
                        for (let j = 0; j < scenario_checked.length; j++) {

                            //console.log(i)
                            //console.log(stages_checked[i])
                            //console.log(j)
                            //console.log(scenario_checked[j])


                            var newDataset = {
                                label: stages_checked[i] + "-" + scenario_checked[j] + ' ensmean',
                                data: data.slice(dataset_breaks[k], dataset_breaks[k + 1]),
                                fill: false,
                                //borderColor: getStageBorderColor(stages_checked[i], scenario_checked[j], '', 'difference'),
                                tension: 0.1,
                            }
                            chart_data.datasets.push(newDataset);

                            k++;
                        }
                    }

                    // get min and max values so you can create min and max values for the y scale
                    var data_min_val = Math.floor(Math.min(...chart_data.datasets.flat()));
                    var data_max_val = Math.ceil(Math.max(...chart_data.datasets.flat()));

                    //console.log(data_min_val);
                    //console.log(data_max_val);

                    // y scale should be same on both (plus and minus) sides
                    // so first decide which of the both values absolute is larger
                    var data_max_abs = Math.max(...[Math.abs(data_min_val), Math.abs(data_max_val)]);
                    //console.log(data_max_abs);



                }

            }

            var timeseries_conf = createTimeseriesConf();

            timeseries_conf.data = chart_data;
            timeseries_conf.options.plugins.title.text = [activeLayer.getChartTitle(true)[0], activeLayer.getChartTitle(true)[1] + ", " + region_selected];
            timeseries_conf.options.scales.y = y_axis_config;
            //console.log(timeseries_conf);

            //chart needs to be global, so it can be accessed when clicking create chart button
            // so it can be destroyed if it already exists
            window.timeseries_chart = new Chart(ctx, timeseries_conf);

            // disable loader
            $("#loaderContainer").hide();





        })
        // when request failed
        .fail(function (xhr, textStatus, errorThrown) {
            alert("Error creating timeseries chart.");
            //console.log(xhr.responseText);
            //console.log(textStatus);
            //console.log(errorThrown);
        });


})





//#######################################################################################################################################################//
//////////////////////////////////////////////////////////////////////                    ///////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////    COMPARE CROPS   ///////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////                    //////////////////////////////////////////////////////////////////////
//#######################################################################################################################################################//


// check which crops / scenario checkboxes are checked when creating charts for stages
// dont allow every checkbox to be checked, so you only have a maximum of 4 datasets in the chart
$('.form-chart-crop, #compareCropsButton').on('change', function () {
    //console.log("CHART FORM CLICK");

    var crops_checked = [];
    var scenario_checked = [];

    crops_checked = $('.form-check-input-crop:checked').map(function () {
        return this.value;
    }).get();
    scenario_checked = $('.form-check-input-crop-scenario:checked').map(function () {
        return this.value;
    }).get();


    var crop_len = crops_checked.length;
    var scenario_len = scenario_checked.length;

    //console.log(crop_len);
    //console.log(scenario_len);

    if (crop_len == 0 && scenario_len == 0) {
        //console.log("var1");
        $('#selectCheckCrops').css('display', 'block');
        $('#selectCheckCrops').text('Select at least one crop and scenario.')
        $("#createCompareCropsChartButton").addClass('no-pointer-events');
    } else if (crop_len == 0 && scenario_len != 0) {
        //console.log("var2");
        $('#selectCheckCrops').css('display', 'block');
        $('#selectCheckCrops').text('Select at least one crop.')
        $("#createCompareCropsChartButton").addClass('no-pointer-events');
    } else if (crop_len != 0 && scenario_len == 0) {
        //console.log("var3");
        $('#selectCheckCrops').css('display', 'block');
        $('#selectCheckCrops').text('Select at least one scenario.')
        $("#createCompareCropsChartButton").addClass('no-pointer-events');
    } else {
        //console.log("else");
        $('#selectCheckCrops').css('display', 'none');
        $('#selectCheckCrops').text('')
        $("#createCompareCropsChartButton").removeClass('no-pointer-events');
    }

    let activeLayer = getActiveLayer(Layers);
    var max_dataset_size;
    var max_html;

    if (activeLayer.type == 'projection' && activeLayer.typeProjection == 'abs') {
        max_dataset_size = 2;
        max_html = 'When analyzing absolute projected data, select a maximum of 2 datasets';
    } else {
        max_dataset_size = 4;
        max_html = 'Select a maximum of 4 datasets';
    }

    var num_crops_datasets = crop_len * scenario_len;
    //console.log(num_crops_datasets);

    if (num_crops_datasets > max_dataset_size) {
        //console.log("greater than 4");
        $('#numDatasetsCrops').css('display', 'block');
        $('#numDatasetsCrops').text(max_html + ', currently selected: ' + num_crops_datasets);
        $("#createCompareCropsChartButton").addClass('no-pointer-events');
        return; // Exit the function immediately
    } else {
        $('#numDatasetsCrops').text('');
        $('#numDatasetsCrops').css('display', 'none');
        $("#createCompareCropsChartButton").removeClass('no-pointer-events');
    }
})

$('#compareCropsButton').on('click', function () {
    // get currently active layer
    let activeLayer = getActiveLayer(Layers);
    //console.log(activeLayer);

    if (!activeLayer) {
        alert("No indicator selected / visible.");
        return;
    }

    if (region_selected_single == "") {
        alert("No region was selected");
        return;
    }

    //console.log(activeLayer.typeProjection);
    if (activeLayer.typeProjection == "trend") {
        alert('Trendline cannot be created for data of type "trend"');
        return;
    }

    // hide scenario selection if recent data was selected
    if (activeLayer.type == 'recent') {
        $('cropChartScenarioSelect').hide();
    } else {
        $('cropChartScenarioSelect').show();
    }

    //console.log("click");
    $('#compareCropsModal').modal('toggle');
})


$("#createCompareCropsChartButton").on("click", function () {

    // get currently active layer
    let activeLayer = getActiveLayer(Layers);
    //console.log(activeLayer);

    var api = api_url + "/compareCrops";


    var crops_checked = [];
    var scenario_checked = [];

    crops_checked = $('.form-check-input-crop:checked').map(function () {
        return this.value;
    }).get();
    scenario_checked = $('.form-check-input-crop-scenario:checked').map(function () {
        return this.value;
    }).get();

    //console.log(crops_checked);
    //console.log(scenario_checked);



    // open collapsible footer where chart is displayed
    $("#loaderContainer").show();
    $('#collapseOne').collapse("show");

    //console.log(admin_selected);
    //console.log(region_selected_single);


    switch (activeLayer.type) {
        case 'recent':
            var post_data = 'indicator=' + activeLayer.titleNoYear + "&indicator_short=" + activeLayer.indicator + "&type=" + activeLayer.type + "&typeRecent=" + activeLayer.typeRecent + "&typeProjection=" + activeLayer.typeProjection + "&model=" + activeLayer.model + "&scenario=" + "none" + "&region=" + region_selected_single + "&admin=" + admin_selected + "&crop=" + crops_checked + "&cropType=" + activeLayer.cropType + "&cropStage=" + activeLayer.cropStage + "&x=&y=&plotType=panel";
            break;

        case 'projection':
            var post_data = 'indicator=' + activeLayer.titleNoYear + "&indicator_short=" + activeLayer.indicator + "&type=" + activeLayer.type + "&typeRecent=" + activeLayer.typeRecent + "&typeProjection=" + activeLayer.typeProjection + "&model=" + activeLayer.model + "&scenario=" + scenario_checked + "&region=" + region_selected_single + "&admin=" + admin_selected + "&crop=" + crops_checked + "&cropType=" + activeLayer.cropType + "&cropStage=" + activeLayer.cropStage + "&x=&y=&plotType=panel";
            break;
    }



    //console.log(post_data);




    //###############################################################################################################///
    /////////////////////////////////////////     AJAX POST DATA         //////////////////////////////////////////////
    //##############################################################################################################//


    $.ajax({
        type: 'POST',
        dataType: 'json',
        data: post_data,
        url: api,
        //username: 'user',
        //password: 'pass',
        crossDomain: true,
        xhrFields: {
            //withCredentials: true,
        },
    })
        // when post request is done
        .done(function (data) {
            //console.log('OUTPUT:');
            //console.log(data);

            var ctx = createCanvas();

            Chart.register(...registerables);





            ////////////////////////////////////////////////////////////////////////////////////////////////////////
            ///////////////// RECENT /////////////////////////////
            // different charts depending on type of data
            // if recent, just mean
            // if projection, split dataset in 3 (mean, min, max) to display bandwidth
            if (activeLayer.type == "recent") {
                //console.log("recent");

                ///// CHART DATA
                // intialize chart data
                // create year labels for plot
                var labels = getDatasetLabels(activeLayer);

                var chart_data = {
                    labels: labels,
                    datasets: []
                };


                var data_len = data.length;
                var num_datasets = crops_checked.length;
                //console.log(data_len);
                //console.log(num_datasets);

                var datasets = []
                var dataset_breaks = getDatasetBreaks(activeLayer);

                for (let i = 0; i < crops_checked.length; i++) {
                    //console.log(i)

                    datasets[i] = data.slice(dataset_breaks[i], dataset_breaks[i + 1]);

                    //console.log(datasets);

                    var newDataset = {
                        label: crops_checked[i] + ' mean',
                        data: data.slice(dataset_breaks[i], dataset_breaks[i + 1]),
                        fill: false,
                        borderColor: getCropBorderColor(crops_checked[i], '', '', 'recent'),
                        //borderColor: getCropBorderColor(crops_checked[i]),
                        tension: 0.1,
                    }
                    chart_data.datasets.push(newDataset);


                }

                //console.log(chart_data);

                var y_axis_config = {
                    beginAtZero: true
                }


                ////////////////////////////////////////////////////////////////////////////////////////////////////////
                ///////////////// PROJECTION /////////////////////////////
            } if (activeLayer.type == "projection") {
                //console.log("projection");

                ///////////////////////////////////////////////////////////////////////////////////////////////////////
                ///////////////// ABSOLUTE //////////////////////////////
                if (activeLayer.typeProjection == 'abs') {


                    //console.log("ABSOLUTE PLOT");


                    var data_len = data.length;
                    var num_datasets = crops_checked.length * scenario_checked.length;
                    //console.log(data_len);
                    //console.log(num_datasets);

                    var datasets_mean = [];
                    var datasets_min_max = [];

                    var labels = getDatasetLabels(activeLayer);
                    var breaks = getDatasetBreaks(activeLayer, num_datasets);
                    var dataset_breaks_mean = breaks[0];
                    var dataset_breaks_min = breaks[1];
                    var dataset_breaks_max = breaks[2];

                    ///// CHART DATA
                    // intialize chart data
                    // create year labels for plot
                    var chart_data = {
                        labels: labels,
                        datasets: []
                    };

                    //console.log(dataset_breaks_mean);
                    //console.log(dataset_breaks_min);
                    //console.log(dataset_breaks_max);


                    k = 0;
                    for (let i = 0; i < crops_checked.length; i++) {
                        for (let j = 0; j < scenario_checked.length; j++) {


                            // for display in chart, mean values without fill property must be on top of array that holds the datasets
                            // -> one array for mean values, one for min and max
                            // in the end combine them so e.g. with two layers you get: mean1, mean2, min1, max1, min2, max2

                            datasets_mean[k] = {
                                label: crops_checked[i] + "-" + scenario_checked[j] + ' ensmean',
                                data: data.slice(dataset_breaks_mean[k], dataset_breaks_mean[k + 1]),
                                fill: false,
                                borderColor: getCropBorderColorAbsolute(k, 'mean'),
                                //backgroundColor: getCropBorderColorAbsolute(k, 'mean'),
                                tension: 0.1,
                            };
                            datasets_min_max[k] = [{
                                label: crops_checked[i] + "-" + scenario_checked[j] + " ensmin",
                                data: data.slice(dataset_breaks_min[k], dataset_breaks_min[k + 1]),
                                fill: false,
                                borderColor: getCropBorderColorAbsolute(k, 'min'),
                                backgroundColor: getCropBorderColorAbsolute(k, 'min'),
                                tension: 0.1,
                            },
                            {
                                label: crops_checked[i] + "-" + scenario_checked[j] + " ensmax",
                                data: data.slice(dataset_breaks_max[k], dataset_breaks_max[k + 1]),
                                fill: '-1',
                                borderColor: getCropBorderColorAbsolute(k, 'max'),
                                backgroundColor: getCropBorderColorAbsolute(k, 'max'),
                                tension: 0.1,
                            }];

                            k++;

                        }
                    }

                    chart_data.datasets.push(datasets_mean);
                    chart_data.datasets.push(datasets_min_max.flat());

                    chart_data.datasets = chart_data.datasets.flat();

                    //console.log(chart_data)

                    var y_axis_config = {
                        beginAtZero: true
                    }


                }
                ////////////////////////////////////////////////////////////////////////////////////////////////////////
                ///////////////// DIFFERENCE /////////////////////////////
                if (activeLayer.typeProjection == 'diff') {
                    //console.log("DIFFERENCE PLOT");

                    var data_len = data.length;
                    var num_datasets = crops_checked.length;
                    //console.log(data_len);
                    //console.log(num_datasets);

                    var datasets = []

                    var dataset_breaks = getDatasetBreaks(activeLayer, num_datasets);
                    var labels = getDatasetLabels(activeLayer);

                    ///// CHART DATA
                    // intialize chart data
                    // create year labels for plot
                    var chart_data = {
                        labels: labels,
                        datasets: []
                    };


                    var k = 0;
                    for (let i = 0; i < crops_checked.length; i++) {
                        for (let j = 0; j < scenario_checked.length; j++) {

                            //console.log(i)
                            //console.log(crops_checked[i])
                            //console.log(j)
                            //console.log(scenario_checked[j])

                            var newDataset = {
                                label: crops_checked[i] + "-" + scenario_checked[j] + ' ensmean',
                                data: data.slice(dataset_breaks[k], dataset_breaks[k + 1]),
                                fill: false,
                                //borderColor: getCropBorderColor(crops_checked[i], scenario_checked[j], '', 'difference'),
                                tension: 0.1,
                            }
                            chart_data.datasets.push(newDataset);

                            //console.log(dataset_breaks[k])
                            //console.log(dataset_breaks[k + 1])
                            //console.log(newDataset);


                            k++;
                        }
                    }

                    //console.log("RICHTIG HIER?")

                    // get min and max values so you can create min and max values for the y scale
                    var data_min_val = Math.floor(Math.min(...chart_data.datasets.flat()));
                    var data_max_val = Math.ceil(Math.max(...chart_data.datasets.flat()));

                    //console.log(data_min_val);
                    //console.log(data_max_val);

                    // y scale should be same on both (plus and minus) sides
                    // so first decide which of the both values absolute is larger
                    var data_max_abs = Math.max(...[Math.abs(data_min_val), Math.abs(data_max_val)]);
                    //console.log(data_max_abs);


                }

            }


            var timeseries_conf = createTimeseriesConf();

            timeseries_conf.data = chart_data;
            timeseries_conf.options.plugins.title.text = [activeLayer.getChartTitle(true)[0], activeLayer.getChartTitle(true)[1] + ", " + region_selected];
            timeseries_conf.options.scales.y = y_axis_config;
            //console.log(timeseries_conf);

            //chart needs to be global, so it can be accessed when clicking create chart button
            // so it can be destroyed if it already exists
            window.timeseries_chart = new Chart(ctx, timeseries_conf);

            // disable loader
            $("#loaderContainer").hide();





        })
        // when request failed
        .fail(function (xhr, textStatus, errorThrown) {
            alert("Error creating timeseries chart.");
            //console.log(xhr.responseText);
            //console.log(textStatus);
            //console.log(errorThrown);
        });





})









//#######################################################################################################################################################//
//////////////////////////////////////////////////////////////////////                    ///////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////    COMPARE DROUGHT   ///////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////                    //////////////////////////////////////////////////////////////////////
//#######################################################################################################################################################//
$('.form-chart-drought, #compareDroughtTypesButton').on('change', function () {
    //console.log("CHART FORM CLICK");

    var drought_checked = [];
    var scenario_checked = [];

    drought_checked = $('.form-check-input-drought:checked').map(function () {
        return this.value;
    }).get();
    scenario_checked = $('.form-check-input-scenario:checked').map(function () {
        return this.value;
    }).get();

    //console.log(drought_checked);
    //console.log(scenario_checked);

    var drought_len = drought_checked.length;
    var scenario_len = scenario_checked.length;

    if (drought_len == 0 && scenario_len == 0) {
        //console.log("var1");
        $('#selectCheckDrought').css('display', 'block');
        $('#selectCheckDrought').text('Select at least one drought type and scenario.')
        $("#createCompareDroughtChartButton").addClass('no-pointer-events');
    } else if (drought_len == 0 && scenario_len != 0) {
        //console.log("var2");
        $('#selectCheckDrought').css('display', 'block');
        $('#selectCheckDrought').text('Select at least one drought type.')
        $("#createCompareDroughtChartButton").addClass('no-pointer-events');
    } else if (drought_len != 0 && scenario_len == 0) {
        //console.log("var3");
        $('#selectCheckDrought').css('display', 'block');
        $('#selectCheckDrought').text('Select at least one scenario.')
        $("#createCompareDroughtChartButton").addClass('no-pointer-events');
    } else {
        //console.log("else");
        $('#selectCheckDrought').css('display', 'none');
        $('#selectCheckDrought').text('')
        $("#createCompareDroughtChartButton").removeClass('no-pointer-events');
    }

    let activeLayer = getActiveLayer(Layers);
    var max_dataset_size;
    var max_html;

    if (activeLayer.type == 'projection' && activeLayer.typeProjection == 'abs') {
        max_dataset_size = 2;
        max_html = 'When analyzing absolute projected data, select a maximum of 2 datasets';
    } else {
        max_dataset_size = 4;
        max_html = 'Select a maximum of 4 datasets';
    }

    var num_drought_datasets = drought_len * scenario_len;
    //console.log(num_drought_datasets);

    if (num_drought_datasets > max_dataset_size) {
        //console.log("greater than 4");
        $('#numDatasetsDrought').css('display', 'block');
        $('#numDatasetsDrought').text(max_html + ', currently selected: ' + num_drought_datasets);
        $("#createCompareDroughtChartButton").addClass('no-pointer-events');
        return; // Exit the function immediately
    } else {
        $('#numDatasetsDrought').text('');
        $('#numDatasetsDrought').css('display', 'none');
        $("#createCompareDroughtChartButton").removeClass('no-pointer-events');
    }
})


$('#compareDroughtTypesButton').on('click', function () {
    // get currently active layer
    let activeLayer = getActiveLayer(Layers);
    //console.log(activeLayer);

    if (!activeLayer) {
        alert("No indicator selected / visible.");
        return;
    }

    if (region_selected_single == "") {
        alert("No region was selected");
        return;
    }

    // hide scenario selection if recent data was selected
    if (activeLayer.type == 'recent') {
        $('droughtChartScenarioSelect').hide();
    } else {
        $('droughtChartScenarioSelect').show();
    }


    //console.log("click");
    $('#compareDroughtModal').modal('toggle');
})



$("#createCompareDroughtChartButton").on("click", function () {

    // get currently active layer
    let activeLayer = getActiveLayer(Layers);
    //console.log(activeLayer);

    var api = api_url + "/compareDrought";


    var drought_checked = [];
    var scenario_checked = [];

    drought_checked = $('.form-check-input-drought:checked').map(function () {
        return this.value;
    }).get();
    scenario_checked = $('.form-check-input-scenario:checked').map(function () {
        return this.value;
    }).get();

    //console.log(drought_checked);
    //console.log(scenario_checked);




    // open collapsible footer where chart is displayed
    $("#loaderContainer").show();
    $('#collapseOne').collapse("show");

    //console.log(admin_selected);
    //console.log(region_selected_single);

    switch (activeLayer.type) {
        case 'recent':
            var post_data = 'indicator=' + activeLayer.titleNoYear + "&indicator_short=" + activeLayer.indicator + "&type=" + activeLayer.type + "&typeRecent=" + activeLayer.typeRecent + "&typeProjection=" + activeLayer.typeProjection + "&model=" + activeLayer.model + "&region=" + region_selected_single + "&admin=" + admin_selected + "&scenario=" + 'none' + "&drought=" + drought_checked + "&x=&y=&plotType=panel";
            break;

        case 'projection':
            var post_data = 'indicator=' + activeLayer.titleNoYear + "&indicator_short=" + activeLayer.indicator + "&type=" + activeLayer.type + "&typeRecent=" + activeLayer.typeRecent + "&typeProjection=" + activeLayer.typeProjection + "&model=" + activeLayer.model + "&region=" + region_selected_single + "&admin=" + admin_selected + "&scenario=" + scenario_checked + "&drought=" + drought_checked + "&x=&y=&plotType=panel";
            break;
    }

    //console.log(post_data);








    //###############################################################################################################///
    /////////////////////////////////////////     AJAX POST DATA         //////////////////////////////////////////////
    //##############################################################################################################//


    $.ajax({
        type: 'POST',
        dataType: 'json',
        data: post_data,
        url: api,
        //username: 'user',
        //password: 'pass',
        crossDomain: true,
        xhrFields: {
            //withCredentials: true,
        },
    })
        // when post request is done
        .done(function (data) {
            //console.log('OUTPUT:');
            //console.log(data);

            var ctx = createCanvas();

            Chart.register(...registerables);




            ////////////////////////////////////////////////////////////////////////////////////////////////////////
            ///////////////// RECENT /////////////////////////////
            // different charts depending on type of data
            // if recent, just mean
            // if projection, split dataset in 3 (mean, min, max) to display bandwidth
            if (activeLayer.type == "recent") {
                //console.log("recent");

                ///// CHART DATA
                // intialize chart data
                // create year labels for plot
                var labels = getDatasetLabels(activeLayer);

                var chart_data = {
                    labels: labels,
                    datasets: []
                };

                var data_len = data.length;
                var num_datasets = drought_checked.length;
                //console.log(data_len);
                //console.log(num_datasets);

                var datasets = []
                var dataset_breaks = getDatasetBreaks(activeLayer);


                for (let i = 0; i < drought_checked.length; i++) {
                    //console.log(i)

                    datasets[i] = data.slice(dataset_breaks[i], dataset_breaks[i + 1]);

                    //console.log(datasets);

                    var newDataset = {
                        label: drought_checked[i] + ' mean',
                        data: data.slice(dataset_breaks[i], dataset_breaks[i + 1]),
                        fill: false,
                        borderColor: getDroughtBorderColor(drought_checked[i], '', '', 'recent'),
                        tension: 0.1,
                    }
                    chart_data.datasets.push(newDataset);

                }

                var y_axis_config = {}



                ////////////////////////////////////////////////////////////////////////////////////////////////////////
                ///////////////// PROJECTION /////////////////////////////
            } if (activeLayer.type == "projection") {
                //console.log("projection");

                ///////////////////////////////////////////////////////////////////////////////////////////////////////
                ///////////////// ABSOLUTE //////////////////////////////
                if (activeLayer.typeProjection == 'abs') {

                    //console.log("ABSOLUTE PLOT");


                    var data_len = data.length;
                    var num_datasets = scenario_checked.length * drought_checked.length;
                    //console.log(data_len);
                    //console.log(num_datasets);

                    var datasets_mean = [];
                    var datasets_min_max = [];

                    var labels = getDatasetLabels(activeLayer);
                    var breaks = getDatasetBreaks(activeLayer, num_datasets);
                    var dataset_breaks_mean = breaks[0];
                    var dataset_breaks_min = breaks[1];
                    var dataset_breaks_max = breaks[2];





                    ///// CHART DATA
                    // intialize chart data
                    // create year labels for plot
                    var chart_data = {
                        labels: labels,
                        datasets: []
                    };

                    //console.log(dataset_breaks_mean);
                    //console.log(dataset_breaks_min);
                    //console.log(dataset_breaks_max);


                    var k = 0;
                    for (let i = 0; i < drought_checked.length; i++) {
                        for (let j = 0; j < scenario_checked.length; j++) {



                            //console.log(i)
                            //console.log(drought_checked[i])
                            //console.log(j)
                            //console.log(scenario_checked[j])


                            // for display in chart, mean values without fill property must be on top of array that holds the datasets
                            // -> one array for mean values, one for min and max
                            // in the end combine them so e.g. with two layers you get: mean1, mean2, min1, max1, min2, max2

                            datasets_mean[k] = {
                                label: drought_checked[i] + "-" + scenario_checked[j] + ' ensmean',
                                data: data.slice(dataset_breaks_mean[k], dataset_breaks_mean[k + 1]),
                                fill: false,
                                borderColor: getDroughtBorderColor(drought_checked[i], scenario_checked[j], 'mean', 'absolute'),
                                tension: 0.1,
                            };

                            datasets_min_max[k] = [{
                                label: drought_checked[i] + "-" + scenario_checked[j] + " ensmin",
                                data: data.slice(dataset_breaks_min[k], dataset_breaks_min[k + 1]),
                                fill: false,
                                backgroundColor: getDroughtBorderColor(drought_checked[i], scenario_checked[j], 'min', 'absolute'),
                                borderColor: getDroughtBorderColor(drought_checked[i], scenario_checked[j], 'min', 'absolute'),
                                tension: 0.1,
                            },
                            {
                                label: drought_checked[i] + "-" + scenario_checked[j] + " ensmax",
                                data: data.slice(dataset_breaks_max[k], dataset_breaks_max[k + 1]),
                                fill: '-1',
                                backgroundColor: getDroughtBorderColor(drought_checked[i], scenario_checked[j], 'max', 'absolute'),
                                borderColor: getDroughtBorderColor(drought_checked[i], scenario_checked[j], 'max', 'absolute'),
                                tension: 0.1,
                            }];


                            k++;
                        }
                    }

                    chart_data.datasets.push(datasets_mean);
                    chart_data.datasets.push(datasets_min_max.flat());

                    chart_data.datasets = chart_data.datasets.flat();

                    //console.log(chart_data)



                    var y_axis_config = {
                        beginAtZero: true
                    }

                }

                ////////////////////////////////////////////////////////////////////////////////////////////////////////
                ///////////////// DIFFERENCE /////////////////////////////
                if (activeLayer.typeProjection == 'diff') {
                    //console.log("DIFFERENCE PLOT");

                    var data_len = data.length;
                    //console.log(data_len);

                    var datasets = []
                    var dataset_breaks = getDatasetBreaks(activeLayer);
                    var labels = getDatasetLabels(activeLayer);
                    //console.log(dataset_breaks);

                    ///// CHART DATA
                    // intialize chart data
                    // create year labels for plot
                    var chart_data = {
                        labels: labels,
                        datasets: []
                    };




                    var k = 0;
                    for (let i = 0; i < drought_checked.length; i++) {
                        for (let j = 0; j < scenario_checked.length; j++) {



                            //console.log(i)
                            //console.log(drought_checked[i])
                            //console.log(j)
                            //console.log(scenario_checked[j])

                            var newDataset = {
                                label: drought_checked[i] + "-" + scenario_checked[j] + ' ensmean',
                                data: data.slice(dataset_breaks[k], dataset_breaks[k + 1]),
                                fill: false,
                                borderColor: getDroughtBorderColor(drought_checked[i], scenario_checked[j], '', 'difference'),
                                tension: 0.1,
                            };
                            chart_data.datasets.push(newDataset);


                            k++;
                        }
                    }


                    //console.log(chart_data);

                    var y_axis_config = {}

                }

            }


            var timeseries_conf = createTimeseriesConf();

            timeseries_conf.data = chart_data;
            timeseries_conf.options.plugins.title.text = [activeLayer.getChartTitle(true)[0], activeLayer.getChartTitle(true)[1] + ", " + region_selected];
            timeseries_conf.options.scales.y = y_axis_config;
            //console.log(timeseries_conf);


            //chart needs to be global, so it can be accessed when clicking create chart button
            // so it can be destroyed if it already exists
            window.timeseries_chart = new Chart(ctx, timeseries_conf);

            // disable loader
            $("#loaderContainer").hide();





        })
        // when request failed
        .fail(function (xhr, textStatus, errorThrown) {
            alert("Error creating timeseries chart.");
            //console.log(xhr.responseText);
            //console.log(textStatus);
            //console.log(errorThrown);
        });

})














//#######################################################################################################################################################//
//////////////////////////////////////////////////////////////////////                    ///////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////    COMPARE RAINY SEASON   ///////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////                    //////////////////////////////////////////////////////////////////////
//#######################################################################################################################################################//


$('.form-chart-rainySeason, #compareRainySeasonButton').on('change', function () {
    //console.log("CHART FORM CLICK");

    var season_checked = [];
    var scenario_checked = [];

    season_checked = $('.form-check-input-season:checked').map(function () {
        return this.value;
    }).get();
    scenario_checked = $('.form-check-input-season-scenario:checked').map(function () {
        return this.value;
    }).get();


    var season_len = season_checked.length;
    var scenario_len = scenario_checked.length;

    //console.log(season_len);
    //console.log(scenario_len);

    if (season_len == 0 && scenario_len == 0) {
        //console.log("var1");
        $('#selectCheckRainySeason').css('display', 'block');
        $('#selectCheckRainySeason').text('Select at least one stage and scenario.')
        $("#createRainySeasonChartButton").addClass('no-pointer-events');
    } else if (season_len == 0 && scenario_len != 0) {
        //console.log("var2");
        $('#selectCheckRainySeason').css('display', 'block');
        $('#selectCheckRainySeason').text('Select at least one stage.')
        $("#createRainySeasonChartButton").addClass('no-pointer-events');
    } else if (season_len != 0 && scenario_len == 0) {
        //console.log("var3");
        $('#selectCheckRainySeason').css('display', 'block');
        $('#selectCheckRainySeason').text('Select at least one scenario.')
        $("#createRainySeasonChartButton").addClass('no-pointer-events');
    } else {
        //console.log("else");
        $('#selectCheckRainySeason').css('display', 'none');
        $('#selectCheckRainySeason').text('')
        $("#createRainySeasonChartButton").removeClass('no-pointer-events');
    }

    let activeLayer = getActiveLayer(Layers);
    var max_dataset_size;
    var max_html;

    if (activeLayer.type == 'projection' && activeLayer.typeProjection == 'abs') {
        max_dataset_size = 2;
        max_html = 'When analyzing absolute projected data, select a maximum of 2 datasets';
    } else {
        max_dataset_size = 4;
        max_html = 'Select a maximum of 4 datasets';
    }

    var num_season_datasets = season_len * scenario_len;
    //console.log(num_season_datasets);

    if (num_season_datasets > max_dataset_size) {
        //console.log("greater than 4");
        $('#numDatasetsRainySeason').css('display', 'block');
        $('#numDatasetsRainySeason').text(max_html + ', currently selected: ' + num_season_datasets);
        $("#createRainySeasonChartButton").addClass('no-pointer-events');
        return; // Exit the function immediately
    } else {
        $('#numDatasetsRainySeason').text('');
        $('#numDatasetsRainySeason').css('display', 'none');
        $("#createRainySeasonChartButton").removeClass('no-pointer-events');
    }

})



$('#compareRainySeasonButton').on('click', function () {
    // get currently active layer
    let activeLayer = getActiveLayer(Layers);
    //console.log(activeLayer);

    if (!activeLayer) {
        alert("No indicator selected / visible.");
        return;
    }

    if (region_selected_single == "") {
        alert("No region was selected");
        return;
    }


    // hide scenario selection if recent data was selected
    if (activeLayer.type == 'recent') {
        $('seasonChartScenarioSelect').hide();
    } else {
        $('seasonChartScenarioSelect').show();
    }


    //console.log("click");
    $('#compareRainySeasonModal').modal('toggle');
})


$("#createRainySeasonChartButton").on("click", function () {

    // get currently active layer
    let activeLayer = getActiveLayer(Layers);
    //console.log(activeLayer);

    var api = api_url + "/compareRainySeason";


    var season_checked = [];
    var scenario_checked = [];

    season_checked = $('.form-check-input-season:checked').map(function () {
        return this.value;
    }).get();
    scenario_checked = $('.form-check-input-season-scenario:checked').map(function () {
        return this.value;
    }).get();

    //console.log(season_checked);
    //console.log(scenario_checked);



    // open collapsible footer where chart is displayed
    $("#loaderContainer").show();
    $('#collapseOne').collapse("show");

    //console.log(admin_selected);
    //console.log(region_selected_single);

    switch (activeLayer.type) {
        case 'recent':
            var post_data = 'indicator=' + activeLayer.titleNoYear + "&indicator_short=" + activeLayer.indicator + "&type=" + activeLayer.type + "&typeRecent=" + activeLayer.typeRecent + "&typeProjection=" + activeLayer.typeProjection + "&model=" + activeLayer.model + "&region=" + region_selected_single + "&admin=" + admin_selected + "&scenario=" + 'none' + "&season=" + season_checked + "&x=&y=&plotType=panel";
            break;

        case 'projection':
            var post_data = 'indicator=' + activeLayer.titleNoYear + "&indicator_short=" + activeLayer.indicator + "&type=" + activeLayer.type + "&typeRecent=" + activeLayer.typeRecent + "&typeProjection=" + activeLayer.typeProjection + "&model=" + activeLayer.model + "&region=" + region_selected_single + "&admin=" + admin_selected + "&scenario=" + scenario_checked + "&season=" + season_checked + "&x=&y=&plotType=panel";
            break;
    }

    //console.log(post_data);








    //###############################################################################################################///
    /////////////////////////////////////////     AJAX POST DATA         //////////////////////////////////////////////
    //##############################################################################################################//


    $.ajax({
        type: 'POST',
        dataType: 'json',
        data: post_data,
        url: api,
        //username: 'user',
        //password: 'pass',
        crossDomain: true,
        xhrFields: {
            //withCredentials: true,
        },
    })
        // when post request is done
        .done(function (data) {
            //console.log('OUTPUT:');
            //console.log(data);

            var ctx = createCanvas();

            Chart.register(...registerables);




            ////////////////////////////////////////////////////////////////////////////////////////////////////////
            ///////////////// RECENT /////////////////////////////
            // different charts depending on type of data
            // if recent, just mean
            // if projection, split dataset in 3 (mean, min, max) to display bandwidth
            if (activeLayer.type == "recent") {
                //console.log("recent");

                var labels = getDatasetLabels(activeLayer);

                var chart_data = {
                    labels: labels,
                    datasets: []
                };

                var data_len = data.length;
                var num_datasets = season_checked.length;
                //console.log(data_len);
                //console.log(num_datasets);

                var datasets = []
                var dataset_breaks = getDatasetBreaks(activeLayer);



                for (let i = 0; i < season_checked.length; i++) {

                    datasets[i] = data.slice(dataset_breaks[i], dataset_breaks[i + 1]);
                    //console.log(i)
                    //console.log(datasets);

                    var newDataset = {
                        label: season_checked[i] + ' mean',
                        data: data.slice(dataset_breaks[i], dataset_breaks[i + 1]),
                        fill: false,
                        borderColor: getSeasonBorderColor(season_checked[i], '', '', 'recent'),
                        tension: 0.1,
                    }
                    chart_data.datasets.push(newDataset);

                }


                var y_axis_config = {
                    beginAtZero: true
                }


                ////////////////////////////////////////////////////////////////////////////////////////////////////////
                ///////////////// PROJECTION /////////////////////////////
            } if (activeLayer.type == "projection") {
                //console.log("projection");

                ///////////////////////////////////////////////////////////////////////////////////////////////////////
                ///////////////// ABSOLUTE //////////////////////////////
                if (activeLayer.typeProjection == 'abs') {

                    //console.log("ABSOLUTE PLOT");

                    // // test if one or two scenarios were selected for comparison
                    // if (scenario_checked.length == 1) {

                    // } else if (scenario_checked.length == 2) {

                    // }

                    var data_len = data.length;
                    var num_datasets = scenario_checked.length * season_checked.length;
                    //console.log(data_len);
                    //console.log(num_datasets);

                    var datasets_mean = [];
                    var datasets_min_max = [];

                    var labels = getDatasetLabels(activeLayer);
                    var breaks = getDatasetBreaks(activeLayer, num_datasets);
                    var dataset_breaks_mean = breaks[0];
                    var dataset_breaks_min = breaks[1];
                    var dataset_breaks_max = breaks[2];




                    ///// CHART DATA
                    // intialize chart data
                    // create year labels for plot
                    var chart_data = {
                        labels: labels,
                        datasets: []
                    };

                    //console.log(dataset_breaks_mean);
                    //console.log(dataset_breaks_min);
                    //console.log(dataset_breaks_max);


                    var k = 0;
                    for (let i = 0; i < season_checked.length; i++) {
                        for (let j = 0; j < scenario_checked.length; j++) {



                            //console.log(i)
                            //console.log(season_checked[i])
                            //console.log(j)
                            //console.log(scenario_checked[j])

                            // for display in chart, mean values without fill property must be on top of array that holds the datasets
                            // -> one array for mean values, one for min and max
                            // in the end combine them so e.g. with two layers you get: mean1, mean2, min1, max1, min2, max2

                            datasets_mean[k] = {
                                label: season_checked[i] + "-" + scenario_checked[j] + ' ensmean',
                                data: data.slice(dataset_breaks_mean[k], dataset_breaks_mean[k + 1]),
                                fill: false,
                                borderColor: getSeasonBorderColor(season_checked[i], scenario_checked[j], 'mean', 'absolute'),
                                tension: 0.1,
                            };

                            datasets_min_max[k] = [{
                                label: season_checked[i] + "-" + scenario_checked[j] + " ensmin",
                                data: data.slice(dataset_breaks_min[k], dataset_breaks_min[k + 1]),
                                fill: false,
                                backgroundColor: getSeasonBorderColor(season_checked[i], scenario_checked[j], 'min', 'absolute'),
                                borderColor: getSeasonBorderColor(season_checked[i], scenario_checked[j], 'min', 'absolute'),
                                tension: 0.1,
                            },
                            {
                                label: season_checked[i] + "-" + scenario_checked[j] + " ensmax",
                                data: data.slice(dataset_breaks_max[k], dataset_breaks_max[k + 1]),
                                fill: '-1',
                                backgroundColor: getSeasonBorderColor(season_checked[i], scenario_checked[j], 'max', 'absolute'),
                                borderColor: getSeasonBorderColor(season_checked[i], scenario_checked[j], 'max', 'absolute'),
                                tension: 0.1,
                            }];


                            k++;
                        }
                    }

                    chart_data.datasets.push(datasets_mean);
                    chart_data.datasets.push(datasets_min_max.flat());

                    chart_data.datasets = chart_data.datasets.flat();

                    //console.log(chart_data)

                    var y_axis_config = {
                        beginAtZero: true
                    }


                }

                ////////////////////////////////////////////////////////////////////////////////////////////////////////
                ///////////////// DIFFERENCE /////////////////////////////
                if (activeLayer.typeProjection == 'diff') {
                    //console.log("DIFFERENCE PLOT");

                    var data_len = data.length;

                    var datasets = []
                    var dataset_breaks = getDatasetBreaks(activeLayer);
                    var labels = getDatasetLabels(activeLayer);

                    ///// CHART DATA
                    // intialize chart data
                    // create year labels for plot
                    var chart_data = {
                        labels: labels,
                        datasets: []
                    };




                    var k = 0;
                    for (let i = 0; i < season_checked.length; i++) {
                        for (let j = 0; j < scenario_checked.length; j++) {

                            //console.log(i)
                            //console.log(season_checked[i])
                            //console.log(j)
                            //console.log(scenario_checked[j])

                            var newDataset = {
                                label: season_checked[i] + "-" + scenario_checked[j] + ' ensmean',
                                data: data.slice(dataset_breaks[k], dataset_breaks[k + 1]),
                                fill: false,
                                borderColor: getSeasonBorderColor(season_checked[i], scenario_checked[j], '', 'difference'),
                                tension: 0.1,
                            };
                            chart_data.datasets.push(newDataset);


                            k++;
                        }
                    }






                    // get min and max values so you can create min and max values for the y scale
                    var data_min_val = Math.floor(Math.min(...chart_data.datasets.flat()));
                    var data_max_val = Math.ceil(Math.max(...chart_data.datasets.flat()));

                    //console.log(data_min_val);
                    //console.log(data_max_val);

                    // y scale should be same on both (plus and minus) sides
                    // so first decide which of the both values absolute is larger
                    var data_max_abs = Math.max(...[Math.abs(data_min_val), Math.abs(data_max_val)]);
                    //console.log(data_max_abs);



                }

            }

            var timeseries_conf = createTimeseriesConf();

            timeseries_conf.data = chart_data;
            timeseries_conf.options.plugins.title.text = [activeLayer.getChartTitle(true)[0], activeLayer.getChartTitle(true)[1] + ", " + region_selected];
            timeseries_conf.options.scales.y = y_axis_config;
            //console.log(timeseries_conf);

            //chart needs to be global, so it can be accessed when clicking create chart button
            // so it can be destroyed if it already exists
            window.timeseries_chart = new Chart(ctx, timeseries_conf);

            // disable loader
            $("#loaderContainer").hide();

        })
        // when request failed
        .fail(function (xhr, textStatus, errorThrown) {
            alert("Error creating timeseries chart.");
            //console.log(xhr.responseText);
            //console.log(textStatus);
            //console.log(errorThrown);
        });

})




//#######################################################################################################################################################//
//////////////////////////////////////////////////////////////////////                           ///////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////            REMOTE SENSING               ///////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////        INDICATORS         //////////////////////////////////////////////////////////////////////
//#######################################################################################################################################################//



$("#compareNdviButton").on("click", function () {

    if (region_selected_single == "") {
        alert("No region was selected");
        return;
    }
    // get currently active layer
    let activeLayer = getActiveLayer(Layers);
    //console.log(activeLayer);


    var api = api_url + "/remoteSensing";


    // open collapsible footer where chart is displayed
    $("#loaderContainer").show();
    $('#collapseOne').collapse("show");


    //console.log(admin_selected);
    //console.log(region_selected_single);

    var startYear = slider.noUiSlider.get()[0]
    var endYear = slider.noUiSlider.get()[1]

    //console.log(startYear)
    //console.log(endYear)

    var post_data = 'indicator=' + activeLayer.titleNoYear + "&region=" + region_selected_single + "&admin=" + admin_selected + "&startYear=" + startYear + "&endYear=" + endYear + "&x=&y=&plotType=panel";

    //console.log(post_data);


    //###############################################################################################################///
    /////////////////////////////////////////     AJAX POST DATA         //////////////////////////////////////////////
    //##############################################################################################################//


    $.ajax({
        type: 'POST',
        dataType: 'json',
        data: post_data,
        url: api,
        //username: 'user',
        //password: 'pass',
        crossDomain: true,
        xhrFields: {
            //withCredentials: true,
        },
    })
        // when post request is done
        .done(function (data) {
            //console.log('OUTPUT:');
            //console.log(data);

            var ctx = createCanvas();

            Chart.register(...registerables);


            var data_len = data.length
            //console.log(data_len);

            var data_mean = data
            //console.log(data_mean);

            // create year labels for plot
            //console.log(Number(startYear))
            //console.log(Number(endYear))

            //var labels = range(Number(startYear), Number(endYear) + data_len - 1);

            var labels = generateDateLabels(Number(startYear), Number(endYear))
            //console.log(labels);


            var chart_data = {
                labels: labels,
                datasets: [{
                    label: "mean",
                    data: data_mean,
                    fill: false,
                    borderColor: '#3469C8',
                    tension: 0.1,
                }]
            };


            var y_axis_config = {
                beginAtZero: true
            }




            var timeseries_conf = createTimeseriesConf();

            timeseries_conf.data = chart_data;
            timeseries_conf.options.plugins.title.text = [activeLayer.getChartTitle()[0], region_selected];
            timeseries_conf.options.scales.y = y_axis_config;
            //console.log(timeseries_conf);

            //chart needs to be global, so it can be accessed when clicking create chart button
            // so it can be destroyed if it already exists
            window.timeseries_chart = new Chart(ctx, timeseries_conf);

            // disable loader
            $("#loaderContainer").hide();

        })
        // when request failed
        .fail(function (xhr, textStatus, errorThrown) {
            alert("Error creating timeseries chart.");
            //console.log(xhr.responseText);
            //console.log(textStatus);
            //console.log(errorThrown);
        });

})



//#######################################################################################################################################################//
//////////////////////////////////////////////////////////////////////                    ///////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////        CHART BUTTONS  ///////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////                    //////////////////////////////////////////////////////////////////////
//#######################################################################################################################################################//

$(".chart-wrapper").on("click", ".destroy-chart-button", function () {
    //console.log("destory chart");
    //console.log(this);

    var chartContainer = $(this).closest('.chart-and-button-container');
    var chartCanvas = chartContainer.find('.chart-canvas');

    //console.log(chartContainer);
    //console.log(chartCanvas);

    // Remove the entire container
    chartContainer.remove();
    chartCanvas.remove();
})

// declare global variable, to save which chart was clicked
// to give these information to modal buttons
var chartCanvas;
// if downloadChartButton is clicked
$(".chart-wrapper").on("click", "#downloadChartButton", function () {

    var chartContainer = $(this).closest('.chart-and-button-container');
    chartCanvas = chartContainer.find('.chart-canvas');

    $('#downloadChartModal').modal('toggle');
});


////// actual buttons to download data or image
$("#downloadChartImageBtn").on("click", function () {
    //console.log("download Chart")

    var chartInstance = Chart.getChart(chartCanvas[0]);
    //console.log(chartCanvas);
    //console.log(chartInstance);

    var title = chartInstance.options.plugins.title.text;
    var a = document.createElement('a');
    a.href = chartInstance.toBase64Image();
    a.download = 'timeseries ' + title + '.png';
    a.click();
    try {
        stopPropagation();
    } catch (error) {
    }

});

