// import DataTable from 'datatables.net-dt';
import $ from "jquery";
//import DataTable from 'datatables.net-dt';
import { changeSelectionDisplay, indicator_class_json } from "../main";


function loadIndicatorTable() {

    // load external json with units for legend display via ajax
    var climate_indicator_table_fr_html = (function () {
        var html = null;
        $.ajax({
            async: false,
            global: false,
            url: "../html/climate_indicator_table_fr.html",
            dataType: "html",
            success: function (data) {
                html = data;
            },
        });
        return html;
    })();

    // load external json with units for legend display via ajax
    var crop_indicator_table_fr_html = (function () {
        var html = null;
        $.ajax({
            async: false,
            global: false,
            url: "../html/crop_indicator_table_fr.html",
            dataType: "html",
            success: function (data) {
                html = data;
            },
        });
        return html;
    })();

    var rs_indicator_table_fr_html = (function () {
        var html = null;
        $.ajax({
            async: false,
            global: false,
            url: "../html/rs_indicator_table_fr.html",
            dataType: "html",
            success: function (data) {
                html = data;
            },
        });
        return html;
    })();

    // load external json with units for legend display via ajax
    var climate_indicator_table_html = (function () {
        var html = null;
        $.ajax({
            async: false,
            global: false,
            url: "../html/climate_indicator_table.html",
            dataType: "html",
            success: function (data) {
                html = data;
            },
        });
        return html;
    })();

    // load external json with units for legend display via ajax
    var crop_indicator_table_html = (function () {
        var html = null;
        $.ajax({
            async: false,
            global: false,
            url: "../html/crop_indicator_table.html",
            dataType: "html",
            success: function (data) {
                html = data;
            },
        });
        return html;
    })();

    var rs_indicator_table_html = (function () {
        var html = null;
        $.ajax({
            async: false,
            global: false,
            url: "../html/rs_indicator_table.html",
            dataType: "html",
            success: function (data) {
                html = data;
            },
        });
        return html;
    })();

    //$("#indicatorSelectWrapper, #selectIndicatorButton").on("click", function () {
    //console.log(current_language);

    // load different html tables, dependend on currently selected language that is coming from translate.js
    if (current_language == "en") {


        //empty div at first
        $("#tab011").empty();
        $("#tab021").empty();
        $("#tab031").empty();

        $("#tab011").append(climate_indicator_table_html);
        $("#tab021").append(crop_indicator_table_html);
        $("#tab031").append(rs_indicator_table_html);

        // make indicator table a bootstrap data table
        //$('#indicatorTable').DataTable();

        $('#climateIndicatorTable').DataTable({
            // order by category
            order: [[4, 'asc']],
            pageLength: 15,
            lengthMenu: [15, 20, 30, 50],
            // disable so width can set manually
            autoWidth: false
            // select: {
            //    info: true,
            //    style: 'multi',
            //    selector: 'td:nth-child(2)'
            // }
        });

        $('#cropIndicatorTable').DataTable({
            // order by category
            order: [[2, 'asc']],
            pageLength: 15,
            lengthMenu: [15, 20, 30, 50],
            // disable so width can set manually
            autoWidth: false
            // select: {
            //    info: true,
            //    style: 'multi',
            //    selector: 'td:nth-child(2)'
            // }
        });

        $('#rsIndicatorTable').DataTable({
            // order by category
            order: [[2, 'asc']],
            pageLength: 15,
            lengthMenu: [15, 20, 30, 50],
            // disable so width can set manually
            autoWidth: false
            // select: {
            //    info: true,
            //    style: 'multi',
            //    selector: 'td:nth-child(2)'
            // }
        });

        // when page or layout of table is changed in data table, new rows like the ones on the second
        // page dont get catched by normal on click -> event propagating, delegating
        // https://learn.jquery.com/events/event-delegation/

        // set table row style on click
        $(".indicator-table").on("click", ".table-row", function () {
            //console.log("table click");
            //console.log($(this).find("th").text());
            //console.log($(this).attr("class"));

            // if row is already active and clicked, make inactive, remove background color
            if ($(this).attr("class").includes("clicked")) {
                //console.log("row clicked");
                $(this).removeClass("clicked");
                $(this).addClass("inactive");
                // remove background color
                $(this).children("th, td").css("background-color", "");
                $(this).children("th, td").css("color", "#000000");
                //console.log($(this).attr("class"));
                // if row is inactive, add clicked class and add background color
            } else if ($(this).attr("class").includes("inactive")) {
                // remove all backgrounds and clicked from other already clicked rows
                $("tr.clicked").each(function () {
                    //console.log($(this));
                    $(this).removeClass("clicked");
                    $(this).addClass("inactive");
                    $(this).children("th, td").css("background-color", "");
                    $(this).children("th, td").css("color", "#000000");
                });

                //console.log("row inactive");
                $(this).removeClass("inactive");
                $(this).addClass("clicked");
                // change background color
                $(this).children("th, td").css("background-color", "#CA3D6F");
                $(this).children("th, td").css("color", "#f8f9fa");
                //console.log($(this).attr("class"));
            }
        });

    } else if (current_language == "fr") {


        //empty div at first
        $("#tab011").empty();
        $("#tab021").empty();
        $("#tab031").empty();

        $("#tab011").append(climate_indicator_table_fr_html);
        $("#tab021").append(crop_indicator_table_fr_html);
        $("#tab031").append(rs_indicator_table_fr_html);

        // make indicator table a bootstrap data table
        //$('#indicatorTable').DataTable();

        $('#climateIndicatorTable').DataTable({
            // order by category
            order: [[4, 'asc']],
            pageLength: 15,
            lengthMenu: [15, 20, 30, 50],
            // disable so width can set manually
            autoWidth: false
            // select: {
            //    info: true,
            //    style: 'multi',
            //    selector: 'td:nth-child(2)'
            // }
        });

        $('#cropIndicatorTable').DataTable({
            // order by category
            order: [[2, 'asc']],
            pageLength: 15,
            lengthMenu: [15, 20, 30, 50],
            // disable so width can set manually
            autoWidth: false
            // select: {
            //    info: true,
            //    style: 'multi',
            //    selector: 'td:nth-child(2)'
            // }
        });

        $('#rsIndicatorTable').DataTable({
            // order by category
            order: [[2, 'asc']],
            pageLength: 15,
            lengthMenu: [15, 20, 30, 50],
            // disable so width can set manually
            autoWidth: false
            // select: {
            //    info: true,
            //    style: 'multi',
            //    selector: 'td:nth-child(2)'
            // }
        });

        // when page or layout of table is changed in data table, new rows like the ones on the second
        // page dont get catched by normal on click -> event propagating, delegating
        // https://learn.jquery.com/events/event-delegation/

        // set table row style on click
        $(".indicator-table").on("click", ".table-row", function () {
            //console.log("table click");
            //console.log($(this).find("th").text());
            //console.log($(this).attr("class"));

            // if row is already active and clicked, make inactive, remove background color
            if ($(this).attr("class").includes("clicked")) {
                //console.log("row clicked");
                $(this).removeClass("clicked");
                $(this).addClass("inactive");
                // remove background color
                $(this).children("th, td").css("background-color", "");
                $(this).children("th, td").css("color", "#000000");
                //console.log($(this).attr("class"));
                // if row is inactive, add clicked class and add background color
            } else if ($(this).attr("class").includes("inactive")) {
                // remove all backgrounds and clicked from other already clicked rows
                $("tr.clicked").each(function () {
                    //console.log($(this));
                    $(this).removeClass("clicked");
                    $(this).addClass("inactive");
                    $(this).children("th, td").css("background-color", "");
                    $(this).children("th, td").css("color", "#000000");
                });

                //console.log("row inactive");
                $(this).removeClass("inactive");
                $(this).addClass("clicked");
                // change background color
                $(this).children("th, td").css("background-color", "#CA3D6F");
                $(this).children("th, td").css("color", "#f8f9fa");
                //console.log($(this).attr("class"));
            }
        });
    }

    $('#indicatorModal').modal('toggle');
    //});



    // action when clicking on save changes button in layer selection modal
    $("#modalIndicatorSaveButton").on("click", function () {

        // get short and long form of selected indicator from indicator table
        var selected_indicator = $("tr.clicked").find("td")[0].textContent;
        var selected_indicator_long = $("tr.clicked").find("td")[1].textContent;

        // determine class of selected indicator, and then change which selection containers are shown
        // Show or hide crop chart section in analysis card
        changeSelectionDisplay(indicator_class_json[selected_indicator])


        // set selected indicator to input text field
        $("#indicatorSelectInput").val(selected_indicator + " - " + selected_indicator_long);


    });
}



  $("#indicatorSelectWrapper, #selectIndicatorButton").on("click", function () {

    loadIndicatorTable();

  });




export { loadIndicatorTable };
