import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/light.css';
import 'tippy.js/themes/light-border.css';
import 'tippy.js/themes/material.css';
import 'tippy.js/themes/translucent.css';
import $ from "jquery";
import DataTable from 'datatables.net-dt';

import { Layers, getActiveLayer } from '../main';


function setSidebarHeight() {

  var map_height = $(window).height() - 100;
  //console.log(map_height);
  $(".sidebar-left").css("max-height", map_height);
}


function isConstrained() {
  return $("div.mid").width() == $(window).width();
}

function applyInitialUIState() {
  // if width of window is smaller than mid div, start collapsed
  if (isConstrained()) {
    $(".sidebar-left .sidebar-body").fadeOut('slide');
    $(".sidebar-right .sidebar-body").fadeOut('slide');
    $('.mini-submenu-left').fadeIn();
    $('.mini-submenu-right').fadeIn();
  }

}


//////////////// Translate function /////////////////////////

function Translate(attribute, lang) {

  // load language json
  var LngObject = (function () {
    var json = null;
    $.ajax({
      async: false,
      global: false,
      url: "./json/" + lang + ".json",
      dataType: "json",
      success: function (data) {
        json = data;
      },
    });
    return json;
  })();

  // get all dom elements
  //var allDom = document.getElementsByTagName("*");
  var allDom = document.querySelectorAll("[lang]");

  ////console.log(allDom);

  // loop them and replace innerhtml if attribute is in json
  for (var i = 0; i < allDom.length; i++) {
    var elem = allDom[i];
    var key = elem.getAttribute(attribute);

    if (key != null) {
      ////console.log(key);
      elem.innerHTML = LngObject[key];
    }
  }

}

// set selected language
window.current_language = "en";

$(function () {


  /////////////////////// INDICATOR MODAL TAB FUNCTIONALITY ////////////////////
  $(".tabs").on("click", function () {
    //console.log("tab click");
    var current_fs, next_fs;

    $(".tabs").removeClass("active");
    $(".tabs h5").removeClass("font-weight-bold");
    $(".tabs h5").addClass("text-muted");
    $(this).children("h5").removeClass("text-muted");
    $(this).children("h5").addClass("font-weight-bold");
    $(this).addClass("active");

    current_fs = $(".active");

    next_fs = $(this).attr('id');
    next_fs = "#" + next_fs + "1";

    $("fieldset").removeClass("show");
    $(next_fs).addClass("show");

    current_fs.animate({}, {
      step: function () {
        current_fs.css({
          'display': 'none',
          'position': 'relative'
        });
        next_fs.css({
          'display': 'block'
        });
      }
    });
  });

  /////////////////////// DOWNLOAD MODAL TAB FUNCTIONALITY ////////////////////
  $(".tabsDownload").on("click", function () {

    var current_fs, next_fs;

    $(".tabsDownload").removeClass("active");
    $(".tabsDownload h5").removeClass("font-weight-bold");
    $(".tabsDownload h5").addClass("text-muted");
    $(this).children("h5").removeClass("text-muted");
    $(this).children("h5").addClass("font-weight-bold");
    $(this).addClass("active");

    current_fs = $(".active");

    next_fs = $(this).attr('id');
    next_fs = "#" + next_fs + "1";

    $("fieldset").removeClass("show");
    $(next_fs).addClass("show");

    current_fs.animate({}, {
      step: function () {
        current_fs.css({
          'display': 'none',
          'position': 'relative'
        });
        next_fs.css({
          'display': 'block'
        });
      }
    });
  });

  // when clicking on submenu retract button for layer menu
  $('.sidebar-left .slide-submenu').on('click', function () {
    var thisEl = $(this);
    // fade out sidebar body
    // fade in mini-submenus
    thisEl.closest('.sidebar-body').fadeOut('slide', function () {
      $('.mini-submenu-left').fadeIn();
      //applyMargins();
    });
  });

  // when clickung on mini submenus for layers
  $('.mini-submenu-left').on('click', function () {
    var thisEl = $(this);
    // slide in sidebar body
    $('.sidebar-left .sidebar-body').toggle('slide');
    // hide mini-submenus
    thisEl.hide();
    //applyMargins();
  });

  $('.sidebar-right .slide-submenu').on('click', function () {
    var thisEl = $(this);
    thisEl.closest('.sidebar-body').fadeOut('slide', function () {
      $('.mini-submenu-right').fadeIn();
      //applyMargins();
    });
  });

  $('.mini-submenu-right').on('click', function () {
    var thisEl = $(this);
    $('.sidebar-right .sidebar-body').toggle('slide');
    thisEl.hide();
    //applyMargins();
  });


  // code to apply border radius to cards when they expanded
  // also apply shadow when expanded
  $(".card-title-link").on("click", function () {

    if ($(this).attr("class").includes("clicked")) {
      $(this).removeClass("clicked");
      $(this).addClass("inactive");
      $(this).parent().parent().attr('style', 'border-radius: 0.25rem !important');
      $(this).parent().parent().parent().css({ "box-shadow": "" });
    } else if ($(this).attr("class").includes("inactive")) {
      $(this).removeClass("inactive");
      $(this).addClass("clicked");
      $(this).parent().parent().attr('style', 'border-radius: 0.25rem 0.25rem 0 0 !important')
      $(this).parent().parent().parent().attr('style', 'box-shadow: 0 0px 10px #293956');
    }
  });



  // toggle imgmodal when clicking on indicator select button or wrapper div around it
  $(".img-container").on("click", function () {
    //console.log($(this).html());
    var width = $("imgModal").width();
    var img = $(this).clone();
    img = img.width(780);
    $("#modalImgContainer").prepend(img);
    $('#imgModal').modal('toggle');
  });





  // $(window).on("resize", applyMargins);

  applyInitialUIState();
  setSidebarHeight();
  // applyMargins();




  /////////////// Logic to show / hide input forms for indicator selection based on user selection


  // load external json with info, if indicator has data for chirps available
  // true == chirps is available
  var ind_chirps = (function () {
    var json = null;
    $.ajax({
      async: false,
      global: false,
      url: "./json/indicator_chirps_era5.json",
      dataType: "json",
      success: function (data) {
        json = data;
      },
    });
    return json;
  })();

  // string to append to div when chirps data is available
  var html_chirps = '<select class="custom-select" id="typeRecentInput"><option value="chirps">CHIRPS</option><option value="era5" selected>ERA5-Land</option></select>'

  // string to append to div when chirps data is NOT available
  var html_nochirps = '<select class="custom-select" id="typeRecentInput"><option value="era5" selected>ERA5-Land</option></select>'

  // make changes depending on selected indicator type (recent or projected)
  // 23-03-22: also adjust dateslider range dependent on selection
  $("#indicatorTypeInput").on("input", function () {
    var indicator_type = $(this).val();

    switch (indicator_type) {
      // if recent, show forms for recent data
      case "recent":
        //console.log("Type is RECENT");
        $("#typeRecentContainer").slideDown();
        $("#typeProjectionContainer").hide();
        $("#projectionModelContainer").hide();
        $("#scenarioContainer").hide();
        $("#timespanValue").hide();
        // adjust dateslider values
        $("#dateSlider").attr({ "min": 1981, "max": 2010 });
        $("#dateSlider").val('1995');
        break;
      // if projection, show other forms
      case "projection":
        //console.log("Type is PROJECTION");
        $("#typeProjectionContainer").slideDown();
        $("#projectionModelContainer").slideDown();
        $("#typeRecentContainer").hide();
        $("#scenarioContainer").slideDown();
        $("#timespanValue").show();
        // adjust dateslider values
        $("#dateSlider").attr({ "min": 1995, "max": 2085 });
        $("#dateSlider").val('2024');
        break;
    }

  })

  // make changes depending on what type of projected data is selected
  // hide date for signal and trend -> jsut one layer for whole timespan available
  $("#typeProjectionInput").on("input", function () {
    var projectionType = $(this).val();

    switch (projectionType) {
      case "abs":
        $("#selectDateContainer").show();
        $("#selectSignalContainer").hide();
        break;
      case "diff":
        $("#selectDateContainer").show();
        $("#selectSignalContainer").hide();
        break;
      case "trend":
        $("#selectDateContainer").hide();
        $("#selectSignalContainer").show();
        break;
    }

  })

  $('#indicatorTypeInput').on('input', function () {
    var indicatorType = $(this).val();

    switch (indicatorType) {
      case "recent":
        $("#selectSignalContainer").hide();
        break;
      // case "projection":
      //   $("#selectSignalContainer").show();
      //   break;
    }

  })


  // when indicator is selected, test if chirps data is available and change 
  // type formular accordingly
  $("#modalIndicatorSaveButton").on("click", function () {

    setTimeout(function () {

      var indicator = $("#indicatorSelectInput").val();
      //console.log(indicator);
      //console.log(ind_chirps[indicator]);

      if (ind_chirps[indicator]) {
        $("#typeRecentDiv").html(html_chirps);
      } else {
        $("#typeRecentDiv").html(html_nochirps);
      }

    }, 500);



  })



  /////////// image larger onclick
  $(".chart").on("click", function () {
    //console.log("image clicked");
  })




  /////////////////////////////////// Tippy Tooltips ////////////////////////////

  Translate("lang", "en");
  current_language = "en";

  // left sidebar
  tippy('#indicatorTooltip', {
    content: 'Select one of the indicators from the list.',
    theme: 'blue-dark',
  });

  tippy('#typeTooltip', {
    content: 'Select from either recent or projected datasets.',
    theme: 'blue-dark',
  });

  tippy('#legendTooltip', {
    content: 'Only the legend of the uppermost visible raster layer from the "Layers" card is displayed.',
    theme: 'blue-dark',
  });

  // right sidebar
  tippy('#popupTooltip', {
    content: 'Investigate and compare visualized indicators on the map by clicking the button below.',
    theme: 'blue-dark',
  });

  tippy('#selectRegionTooltip', {
    content: 'Select a region from the available administrative boundaries for further analysis.',
    theme: 'blue-dark',
  });

  tippy('#chartTooltip', {
    content: 'Create a trendline chart from the selected indicator and region or compare different scenarios.',
    theme: 'blue-dark',
  });

  tippy('#downloadTooltip', {
    content: 'Download currently selected indicator as GeoTIFF.',
    theme: 'blue-dark',
  });


  ////////////////////////// download selection ////////////////////////////////
  tippy('#dd_download', {
    content: 'Annual number of dry days',
    theme: 'blue-dark',
    placement: 'top-start',
  });

  tippy('#cwdi_download', {
    content: 'Cold wave duration index',
    theme: 'blue-dark',
    placement: 'top-start',
  });

  tippy('#etr_download', {
    content: 'Extreme temperature range',
    theme: 'blue-dark',
    placement: 'top-start',
  });

  tippy('#hwdi_download', {
    content: 'Heat wave duration index',
    theme: 'blue-dark',
    placement: 'top-start',
  });

  tippy('#hwfi_download', {
    content: 'Warm spell days',
    theme: 'blue-dark',
    placement: 'top-start',
  });

  tippy('#tn10p_download', {
    content: '	Extreme cold nights',
    theme: 'blue-dark',
    placement: 'top-start',
  });

  tippy('#tx35_download', {
    content: 'Hot days',
    theme: 'blue-dark',
    placement: 'top-start',
  });

  tippy('#tn90p_download', {
    content: 'Warm nights',
    theme: 'blue-dark',
    placement: 'top-start',
  });

  tippy('#tx90p_download', {
    content: 'Extreme warm days',
    theme: 'blue-dark',
    placement: 'top-start',
  });

  tippy('#rx1day_download', {
    content: 'Annual max 1-day rainfall',
    theme: 'blue-dark',
    placement: 'top-start',
  });

  tippy('#r99p_download', {
    content: 'Extreme daily rainfall intensity',
    theme: 'blue-dark',
    placement: 'top-start',
  });

  tippy('#rtot_download', {
    content: '	Total rainfall amount from wet days',
    theme: 'blue-dark',
    placement: 'top-start',
  });

  tippy('#rd_download', {
    content: 'Number of wet days',
    theme: 'blue-dark',
    placement: 'top-start',
  });

  tippy('#cddrs_download', {
    content: 'Consecutive dry days in rainy season',
    theme: 'blue-dark',
    placement: 'top-start',
  });

  tippy('#cwdrs_download', {
    content: 'Consecutive wet days in rainy season',
    theme: 'blue-dark',
    placement: 'top-start',
  });

  tippy('#ddrs_download', {
    content: 'Number of dry days in rainy season',
    theme: 'blue-dark',
    placement: 'top-start',
  });

  tippy('#r99p_download', {
    content: 'Extreme daily rainfall intensity during rainy season',
    theme: 'blue-dark',
    placement: 'top-start',
  });

  tippy('#rtotrs_download', {
    content: 'Total rainfall amount from wet days in rainy season',
    theme: 'blue-dark',
    placement: 'top-start',
  });


  ////////////////// imprint copyright packages table

  $('#packagesTable').DataTable({
    // order by category
    order: [[4, 'asc']],
    pageLength: 30,
    lengthMenu: [15, 20, 30, 50],
    // disable so width can set manually
    autoWidth: false
    // select: {
    //    info: true,
    //    style: 'multi',
    //    selector: 'td:nth-child(2)'
    // }
  });


  ///////////////////// feedback ///////////////////////
  $("#feedback").on("click", function () {
    $("#feedbackModal").modal('toggle');

  })

  ////////////////////// TRANSLATE /////////////////////
  // translate when page is loaded


  // translate when translate button is clicked
  // also reactiva TIPPY, as referenced elements changed in dom
  $(".translate_fr").on("click", function () {
    //console.log("translating to french");
    Translate("lang", "fr");
    current_language = "fr";

    // change link of the "ABOUT" button on the header
    // depending on the current language
    $('#aboutLink').attr('href', 'about_fr.html');

    /////////////////////////////////// Tippy Tooltips ////////////////////////////


    // left sidebar
    tippy('#indicatorTooltip', {
      content: 'Select one of the indicators from the list.',
      theme: 'blue-dark',
    });

    tippy('#typeTooltip', {
      content: 'Select from either recent or projected datasets.',
      theme: 'blue-dark',
    });

    tippy('#legendTooltip', {
      content: 'Only the legend of the uppermost visible raster layer from the "Layers" card is displayed.',
      theme: 'blue-dark',
    });

    // right sidebar
    tippy('#popupTooltip', {
      content: 'Investigate and compare visualized indicators on the map by clicking the button below.',
      theme: 'blue-dark',
    });

    tippy('#selectRegionTooltip', {
      content: 'Select a region from the available administrative boundaries for further analysis.',
      theme: 'blue-dark',
    });

    tippy('#chartTooltip', {
      content: 'Create a trendline chart from the selected indicator and region or compare different scenarios.',
      theme: 'blue-dark',
    });

    tippy('#downloadTooltip', {
      content: 'Download currently selected indicator as GeoTIFF.',
      theme: 'blue-dark',
    });
  });

  $(".translate_en").on("click", function () {
    //console.log("translating to english");
    Translate("lang", "en");
    current_language = "en";

    // change link of the "ABOUT" button on the header
    // depending on the current language
    $('#aboutLink').attr('href', 'about_en.html');

    /////////////////////////////////// Tippy Tooltips ////////////////////////////


    // left sidebar
    tippy('#indicatorTooltip', {
      content: 'Select one of the indicators from the list.',
      theme: 'blue-dark',
    });

    tippy('#typeTooltip', {
      content: 'Select from either recent or projected datasets.',
      theme: 'blue-dark',
    });

    tippy('#legendTooltip', {
      content: 'Only the legend of the uppermost visible raster layer from the "Layers" card is displayed.',
      theme: 'blue-dark',
    });

    // right sidebar
    tippy('#popupTooltip', {
      content: 'Investigate and compare visualized indicators on the map by clicking the button below.',
      theme: 'blue-dark',
    });

    tippy('#selectRegionTooltip', {
      content: 'Select a region from the available administrative boundaries for further analysis.',
      theme: 'blue-dark',
    });

    tippy('#chartTooltip', {
      content: 'Create a trendline chart from the selected indicator and region.',
      theme: 'blue-dark',
    });

    tippy('#downloadTooltip', {
      content: 'Download currently selected indicator as GeoTIFF.',
      theme: 'blue-dark',
    });
  });







  // when footer is expanding / collapsing
  // change chevron direction on right side
  $('#collapseOne').on('show.bs.collapse hide.bs.collapse', function (e) {
    if (e.type == 'show') {
      $('#footerIconRight').addClass('fa-chevron-down');
      $('#footerIconRight').removeClass('fa-chevron-up');

    } else {
      $('#footerIconRight').addClass('fa-chevron-up');
      $('#footerIconRight').removeClass('fa-chevron-down');

    }
  })







  ///////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////// ADJUST GRAPH MAKING MODALS ///////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////
  // the modals for making graphs needs to be adjusted based on the selected dataset
  // e.g. when selecting a recent layer, no scenario should be choosable -> will be grayed out


  $("#loadLayerButton").on("click", function () {
    let activeLayer = getActiveLayer(Layers);

    //console.log("HALLOHALLO?")
    //console.log(activeLayer);
    //console.log(activeLayer.class)

    switch (activeLayer.class) {
      ///////// CLIMATE LAYER ////////////
      case 'climate':
        //console.log("CLIMATE");
        break;
      ///////// DROUGHT LAYER ////////////
      case 'drought':
        //console.log("DROUGHT");
        if (activeLayer.type == 'recent') {
          //console.log('recent');
          $('#droughtChartScenarioSelect').css('opacity', '0.5');
          $('#droughtChartScenarioSelect').css('pointer-events', 'none');
        } else if (activeLayer.type == 'projection') {
          //console.log('projection');
          $('#droughtChartScenarioSelect').css('opacity', '1');
          $('#droughtChartScenarioSelect').css('pointer-events', 'auto');
        }
      ///////// SEASON LAYER ////////////
      case 'season':
        //console.log("SEASON");
        if (activeLayer.type == 'recent') {
          //console.log('recent');
          $('#seasonChartScenarioSelect').css('opacity', '0.5');
          $('#seasonChartScenarioSelect').css('pointer-events', 'none');
        } else if (activeLayer.type == 'projection') {
          //console.log('projection');
          $('#seasonChartScenarioSelect').css('opacity', '1');
          $('#seasonChartScenarioSelect').css('pointer-events', 'auto');
        }
        break;
      ///////// CROP LAYER ////////////
      case 'crop':
        //console.log("CROP");
        if (activeLayer.type == 'recent') {
          //console.log('recent');
          $('#stageChartScenarioSelect').css('opacity', '0.5');
          $('#stageChartScenarioSelect').css('pointer-events', 'none');
          $('#cropChartScenarioSelect').css('opacity', '0.5');
          $('#cropChartScenarioSelect').css('pointer-events', 'none');
        } else if (activeLayer.type == 'projection') {
          //console.log('projection');
          $('#stageChartScenarioSelect').css('opacity', '1');
          $('#stageChartScenarioSelect').css('pointer-events', 'auto');
          $('#cropChartScenarioSelect').css('opacity', '1');
          $('#cropChartScenarioSelect').css('pointer-events', 'auto');
        }
        break;
    }


    // opacity: 0.5;
    // pointer-events: none;

  });


});