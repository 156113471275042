import { Boarding } from "boarding.js";
import "boarding.js/styles/main.css";
// optionally include the base theme
import "boarding.js/styles/themes/basic.css";

import $ from "jquery";

//import { loadedSuccess, regionSelectedExport } from "../main";

var loadedSuccess = true;
var regionSelectedExport = true;


var intervalId;

// logic for flashing elements for better visibility for users
// when going through the intro and having to click somewhere
function startFlashing(elem) {
  // check if interval has been already set up
  if (!intervalId) {
    intervalId = setInterval(function () { flashElement(elem) }, 750);
  }
}

function flashElement(element) {
  element.toggleClass('flash')
}

function stopFlashing(elem) {
  // set class to standard
  elem.removeClass('flash')
  clearInterval(intervalId);
  // release intervalId from var
  intervalId = null;
}

$(function () {

  ////////////////////////// boarding SETUP ///////////////////////////////
  const boarding = new Boarding({
    opacity: 0.5,
    // dont allow closing when clicking outside
    allowClose: false,
    // dont allow keyboard control
    keyboardControl: false,

    // onHighlighted: (el) => {
    //   $('.boarding-prev-btn').hide();
    // },

    onReset: (Element) => {
      //console.log("END");
      boarding.preventMove();
      stopFlashing($('#loadLayerButton'));

      if (confirm("Do you want to quit the intro?") == true) {
        // enable all clicks again that may be still disabled in course of the intro
        $("#collapseCardsLeft").css('pointer-events', '');
        $("#loadLayerDisableIntro").css('pointer-events', '');
        $("#filterLink").css('pointer-events', '');
        $("#collapseCardsRight").css('pointer-events', '');
        $("#analysisDisableIntro").css("pointer-events", "");
        $("#analysisCardContainer").css("pointer-events", "");
        $("#popupButton").css("pointer-events", "");
        $("#timeseriesChartButton").css("pointer-events", "");
        $("#selectRegionButton").css("pointer-events", "");
        $("#selectRegionModalBody").css("pointer-events", "");
        $("#selectRegionModalHeader").css("pointer-events", "");
        $("#selectRegionModalClose").css("pointer-events", "");
        $("#footerDiv").css("pointer-events", "");
        $("#myChart").css("pointer-events", "");
        $("#layers").css('pointer-events', '');
        $("#downloadChartButton").css("pointer-events", "");
        $(".card-section").css("pointer-events", "");
        $('#analysisLink #popupButton #selectRegionButton #timeseriesChartButton').css('pointer-events', '');
        stopFlashing($('#loadLayerButton'));

      } else {
        boarding.continue();
      }
    },

  });

  const boarding_fr = new Boarding({
    opacity: 0.5,
    // dont allow closing when clicking outside
    allowClose: true,
    // dont allow keyboard control
    keyboardControl: false,

    // onHighlighted: (el) => {
    //   $('.boarding-prev-btn').hide();
    // },

    onReset: (Element) => {
      //console.log("END");
      boarding_fr.preventMove();
      stopFlashing($('#loadLayerButton'));

      if (confirm("Do you want to quit the intro?") == true) {
        // enable all clicks again that may be still disabled in course of the intro
        $("#collapseCardsLeft").css('pointer-events', '');
        $("#loadLayerDisableIntro").css('pointer-events', '');
        $("#filterLink").css('pointer-events', '');
        $("#collapseCardsRight").css('pointer-events', '');
        $("#analysisDisableIntro").css("pointer-events", "");
        $("#analysisCardContainer").css("pointer-events", "");
        $("#popupButton").css("pointer-events", "");
        $("#timeseriesChartButton").css("pointer-events", "");
        $("#selectRegionButton").css("pointer-events", "");
        $("#selectRegionModalBody").css("pointer-events", "");
        $("#selectRegionModalHeader").css("pointer-events", "");
        $("#selectRegionModalClose").css("pointer-events", "");
        $("#footerDiv").css("pointer-events", "");
        $("#myChart").css("pointer-events", "");
        $("#layers").css('pointer-events', '');
        $("#downloadChartButton").css("pointer-events", "");
        $(".card-section").css("pointer-events", "");
        $('#analysisLink #popupButton #selectRegionButton #timeseriesChartButton').css('pointer-events', '');
        stopFlashing($('#loadLayerButton'));

      } else {

        boarding_fr.continue();
      }
    },

  });


  //#######################################################################################################################################################//
  //////////////////////////////////////////////////////////////////////                     ///////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////    BOARDING ENGLISH   ///////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////                     //////////////////////////////////////////////////////////////////////
  //#######################################################################################################################################################//


  boarding.defineSteps([
    ///////////////////////////////// 1 - LAYERS //////////////////////////////////
    {
      element: '#layersCard',
      popover: {
        title: 'Layers (1/15)',
        description: 'Here you can select and deselect layers that appear on the map. Click on the <b>"Layers"</b> tab to open the available layers.',
        prefferedPosition: 'right',
        alignment: "center",
      },

      onHighlighted: (el) => {
        // disable clicking on collapse arrow so menu cannot be minimized
        $("#collapseCardsLeft").css('pointer-events', 'none');

        //console.log("STEP 1");
        // hide button
        //$('.boarding-next-btn').hide();
        $('.boarding-prev-btn').hide();
        // move to next step when card is clicked
        $("#layerLink").on("click", function () {
          // go only to next step if boarding is already activated
          // otherwise boarding will be activated out of nowhere if this button is clicked
          if (boarding.isActivated) {
            setTimeout(function () {
              boarding.next();
            }, 50);
          }
        });
      },

      onNext: (el) => {
        setTimeout(function () {
          $("#layers").collapse("show");
        }, 50);
      },
    },
    ////////////////////////////// 1.1 - LAYERS ////////////////////////////////////
    {
      element: '#layersCardContainer',
      popover: {
        title: 'Layers (2/15)',
        description: 'Here you can select and deselect layers that appear on the map or remove them.',
        prefferedPosition: 'right',
      },
      onHighlighted: (el) => {
        //console.log("STEP 2");
        $('.boarding-prev-btn').hide();

        // disable clicking on layers
        $("#layers").css('pointer-events', 'none');

      },
      // collapse layers card when going back
      onPrevious: () => {
        $("#layers").collapse('hide')
      },
      // collapse layers card when going to next step
      onNext: (el) => {
        setTimeout(function () {
          $("#layers").collapse('hide')
        }, 50);

        // enable clicking collapse arrow again
        $("#layers").css('pointer-events', '');
        $("#collapseCardsLeft").css('pointer-events', '');
      }
    },
    ////////////////////////////// 2 - INDICATORS ////////////////////////////////////
    {
      element: '#filterCard',
      popover: {
        title: 'Select Indicator  (3/15)',
        description: 'Here you can query different climate indicators and base data. Click on <b>"Select Data"</b> to open the menu.',
        prefferedPosition: 'right',
      },

      onHighlighted: (el) => {
        //console.log("STEP 3");
        // hide button
        $('.boarding-prev-btn').hide();
        // go to next step when card is clicked
        $("#filterLink").on("click", function () {
          if (boarding.isActivated) {
            setTimeout(function () {
              boarding.next();
            }, 250);
          }
        });
      },
      onNext: (el) => {
        setTimeout(function () {
          // show select data tab for next step
          $("#loadLayer").collapse("show");
          // start flashing the layer button to get attention
          $('#loadLayerButton').addClass('flash');
          startFlashing($('#loadLayerButton'));

        }, 50);
      }
    },
    ////////////////////////////// 2.1 - INDICATORS ////////////////////////////////////
    {
      element: '#filterCardContainer',
      popover: {
        title: 'Select Indicator  (4/15)',
        description: `You can select any indicators by following these steps: <br><b>
        Select an Indicator</b><br><b>
        Select the type of data</b> you want to investigate (Observational / Projected)<br><b>
        Select the date</b> (The year associated with projected data does not indicate a precise moment, but a 30-year moving average.). 
        <br><br>Now Load the preselected indicator by clicking on the <b>"Show Indicator on Map"</b> Button`,
        prefferedPosition: 'right',
      },
      onHighlighted: (el) => {
        // hide next button
        $('.boarding-next-btn').hide();
        $('.boarding-prev-btn').hide();

        // disable clicking on other parts of the card, so only "load layer" is clickable
        $("#loadLayerDisableIntro").css('pointer-events', 'none');
        // also disable clicking on card closing link so card cannot be closed
        $("#filterLink").css('pointer-events', 'none');


        // when clicking on "load indicator" button, test if loading was sucessful and then move next
        $("#loadLayerButton").on("click", function () {
          if (loadedSuccess) {
            if (boarding.isActivated) {
              setTimeout(function () {
                boarding.next();
              }, 250);
            }
          } else {

          }
        });
      },
      onPrevious: () => {
        $("#loadLayer").collapse('hide');
      },
      onNext: (el) => {
        $("#loadLayer").collapse('hide');
        // make clickable again
        $("#loadLayerDisableIntro").css('pointer-events', '');
        $("#filterLink").css('pointer-events', '');
        // stop flashing button
        stopFlashing($('#loadLayerButton'));

      }
    },
    ////////////////////////////// 3 - LEGEND ////////////////////////////////////
    // {
    //   element: '#legendCard',
    //   popover: {
    //     title: 'View Legend  (5/15)',
    //     description: 'Here you can view the legend for the topmost layer that is visible on the map.',
    //     prefferedPosition: 'right'
    //   },
    //   onHighlighted: (el) => {
    //     // hide next button
    //     $('.boarding-prev-btn').hide();
    //     // when clicking on "load indicator" button, test if loading was sucessful and then move next
    //     $("#legendLink").on("click", function () {
    //       if (boarding.isActivated) {
    //         setTimeout(function () {
    //           boarding.next();
    //         }, 250);
    //       }
    //     });
    //   },
    //   onNext: (el) => {
    //     setTimeout(function () {
    //       $("#properties").collapse("show");
    //     }, 50);
    //   },
    // },
    ////////////////////////////// 3.1 - LEGEND ////////////////////////////////////
    {
      element: '#legendCardContainer',
      popover: {
        title: 'View Legend (5/15)',
        description: 'Here you can view the legend for the topmost layer that is visible on the map.',
        prefferedPosition: 'right'
      },
      onHighlighted: (el) => {
        $('.boarding-prev-btn').hide();
      },
      onNext: (el) => {
        //$("#properties").collapse('hide');
      }
    },
    ////////////////////////////// 4 - Analysis ////////////////////////////////////
    {
      element: '#analysisCard',
      popover: {
        title: 'Analyze Data (6/15)',
        description: 'Here you can analyze the selected data.',
        prefferedPosition: 'left'
      },
      onHighlighted: (el) => {
        $('.boarding-prev-btn').hide();
        // disable clicking collapse arrow
        $("#collapseCardsRight").css('pointer-events', 'none');

        // move to next step when card is clicked
        $("#analysisLink").on("click", function () {
          // go only to next step if boarding is already activated
          // otherwise boarding will be activated out of nowhere if this button is clicked
          if (boarding.isActivated) {
            setTimeout(function () {
              boarding.next();
            }, 50);
          }
        });


      },
      onNext: (el) => {
        setTimeout(function () {
          $("#taskpane").collapse('show');
        }, 50);
      }
    },
    {
      element: '#analysisCardContainer',
      popover: {
        title: 'Analyze Data (7/15)',
        description: 'The Indicator that is currently visible on the map can be analyzed in this part of the webportal.',
        prefferedPosition: 'left'
      },
      onHighlighted: (el) => {
        //console.log(el);
        $('#analysisLink, #popupButton, #selectRegionButton, #timeseriesChartButton').css('pointer-events', 'none');
        $('#popupIntroClick').css('pointer-events', 'none');
        $('.boarding-prev-btn').hide();

      },
      onNext: (el) => {
        $('#analysisLink, #popupButton, #selectRegionButton, #timeseriesChartButton').css('pointer-events', '');
      }
    },
    ////////////////////////////// 4.1 - Analysis ////////////////////////////////////
    {
      element: '#popupButton',
      popover: {
        title: 'Analyze Data - Single Points (8/15)',
        description: 'Clicking on this button will give you the possibility to <b>investigate single points</b> of your currently selected indicators on the map.',
        prefferedPosition: 'left'
      },
      onHighlighted: (el) => {
        $('#analysisLink, #popupButton, #selectRegionButton, #timeseriesChartButton').css('pointer-events', 'none');
        $('#popupIntroClick').css('pointer-events', 'none');
        $('.boarding-prev-btn').hide();

      },
      onNext: () => {
        startFlashing($('#selectRegionButton'));
      }
    },
    ////////////////////////////// 4.2 - Analysis ////////////////////////////////////
    {
      element: '#selectRegionButton',
      popover: {
        title: 'Analyze Data - Select Region (9/15)',
        description: 'You can <b>select any of the administrative Regions</b> activated in the <b>"Layers"</b> Menu. Start by clicking on the <b>"Select Region"</b> button.',
        prefferedPosition: 'left'
      },
      onHighlighted: (el) => {
        $('.boarding-prev-btn').hide();
        $('.boarding-next-btn').hide();
        // disable clicking on anything than the "Select Region" Button
        $("#popupButton").css("pointer-events", "none");
        $("#timeseriesChartButton").css("pointer-events", "none");

        // move to next step when button is clicked
        $("#selectRegionButton").on("click", function () {
          if (boarding.isActivated) {
            setTimeout(function () {
              boarding.next();
            }, 50);
          }
        });
      },
      onNext: () => {
        $("#popupButton").css("pointer-events", "");
        $("#timeseriesChartButton").css("pointer-events", "");
        stopFlashing($('#selectRegionButton'));
        startFlashing($('#modalRegionSaveButton'));
      }
    },
    ////////////////////////////// 4.3 - Analysis ////////////////////////////////////
    {
      element: '#selectRegionModalContent',
      popover: {
        title: 'Analyze Data - Select Region (10/15)',
        description: 'You can now choose which type of administrative boundaries you want to select. Notice that only in "Layers" menu selected boundaries (currently only "Countries") are available. Continue by clicking on the <b>"Save Changes"</b> Button. ',
        prefferedPosition: 'left'
      },
      onHighlighted: (el) => {
        $('.boarding-prev-btn').hide();
        $('.boarding-next-btn').hide();

        // disable clicking on anything than "save changes" button
        $("#selectRegionModalBody").css("pointer-events", "none");
        $("#selectRegionModalHeader").css("pointer-events", "none");
        $("#selectRegionModalClose").css("pointer-events", "none");

        // move to next step when save button is clicked
        $("#modalRegionSaveButton").on("click", function () {
          if (boarding.isActivated) {
            setTimeout(function () {
              boarding.next();
            }, 50);
          }
        });
      },
      onNext: () => {
        // disable clicking on anything than "save changes" button
        $("#selectRegionModalBody").css("pointer-events", "");
        $("#selectRegionModalHeader").css("pointer-events", "");
        $("#selectRegionModalClose").css("pointer-events", "");
        stopFlashing($('#modalRegionSaveButton'));
      }
    },
    ////////////////////////////// 4.3 - Analysis ////////////////////////////////////
    {
      element: '#map',
      popover: {
        title: 'Analyze Data - Select Region (11/15)',
        description: '<h4><b>Click on a single country to select it.</b></h4>',
      },
      onHighlighted: (el) => {
        $('.boarding-prev-btn').hide();
        $('.boarding-next-btn').hide();

        // when clicking on map, check if a single country was selected, 
        // if yes, move to next step
        $("#map").on("click", function () {
          if (boarding.isActivated) {
            if (regionSelectedExport) {
              setTimeout(function () {
                boarding.next();
              }, 1000);

            }

            // setTimeout(function () {
            //   boarding.next();
            // }, 50);
          }
        })
      },
      onNext: () => {
        startFlashing($('#selectRegionButton'));
      }
    },
    ////////////////////////////// 4.3 - Analysis ////////////////////////////////////
    {
      element: '#selectRegionButton',
      popover: {
        title: 'Analyze Data - Select Region (12/15)',
        description: 'Click on the <b>"Stop Selecting"</b> button to finish the selection.',
      },
      onHighlighted: (el) => {
        $('.boarding-prev-btn').hide();
        $('.boarding-next-btn').hide();

        // disable clicking on anything than the "Stop Selecting" Button
        $("#popupButton").css("pointer-events", "none");
        $("#timeseriesChartButton").css("pointer-events", "none");

        // when clicking on map, check if a single country was selected, 
        // if yes, move to next step
        $("#selectRegionButton").on("click", function () {
          if (boarding.isActivated) {
            setTimeout(function () {
              //boarding.next();
            }, 50);
          }
        })
      },
      onNext: () => {
        $("#popupButton").css("pointer-events", "");
        $("#timeseriesChartButton").css("pointer-events", "");
        stopFlashing($('#selectRegionButton'));
      }
    },
    ////////////////////////////// 4.3 - Analysis ////////////////////////////////////
    {
      element: '#timeseriesChartButton',
      popover: {
        title: 'Analyze Data - Select Region (13/15)',
        description: 'Click on the <b>"Trendline"</b> button to continue creating a chart. Notice that the calculation of the chart may take a few seconds. To get a chart of a specific indicator, you must select it in the "Select Indicator" menu on the left.',
      },
      onHighlighted: (el) => {
        $('.boarding-prev-btn').hide();
        $('.boarding-next-btn').hide();

        // disable clicking on anything than the "Create Timeseries Chart" Button
        $("#popupButton").css("pointer-events", "none");
        $("#selectRegionButton").css("pointer-events", "none");

        // when clicking on map, check if a single country was selected, 
        // if yes, move to next step
        $("#timeseriesChartButton").on("click", function () {
          if (boarding.isActivated) {
            setTimeout(function () {
              boarding.next();
            }, 50);
          }
        })
      },
      onNext: () => {
        $("#popupButton").css("pointer-events", "");
        $("#selectRegionButton").css("pointer-events", "");
      }
    },
    ////////////////////////////// 5 - Output ////////////////////////////////////
    {
      element: '#footerDiv',
      popover: {
        title: 'View Output (14/15)',
        description: 'Here you can view and download charts as output of your analysis. When selecting projected data min and max values of the model ensemble will be shown alongside the running mean of the region.',
        prefferedPosition: 'top'
      },
      onHighlighted: () => {
        $('.boarding-prev-btn').hide();
        $('#panelChartWrapper').css('pointer-events', 'none');

        // disable clicking
        $("#footerDiv").css("pointer-events", "none");
      },
      onNext: () => {
        $("#footerDiv").css("pointer-events", "");
        $(".chart-and-button-container").css("pointer-events", "none");
      }
    },
    ////////////////////////////// 5 - Output ////////////////////////////////////
    {
      element: '#downloadChartButton',
      popover: {
        title: 'View Output 2 (15/15)',
        description: 'By clicking on the highlighted download button you will be able to download the data from the chart as well as the chart as an image itself.',
        prefferedPosition: 'top'
      },
      onHighlighted: () => {
        $('.boarding-prev-btn').hide();
        // disable clicking
        $("#footerDiv").css("pointer-events", "none");
        $("#myChart").css("pointer-events", "none");
        $(".chart-and-button-container").css("pointer-events", "none");



      },
      onNext: () => {
        $("#footerDiv").css("pointer-events", "");
        $("#myChart").css("pointer-events", "");
        $("#downloadChartButton").css("pointer-events", "");
      }
    },
    ////////////////////////////// 5 - Output ////////////////////////////////////
    {
      element: '#map',
      popover: {
        title: 'End',
        description: '<h6>Good Luck trying the different functionalities and using the data to learn about climate change in West Africa!</h6>',
        prefferedPosition: 'top'
      },
      onHighlighted: () => {
        $('.boarding-prev-btn').hide();
        $('#panelChartWrapper').css('pointer-events', '');
      },
      onNext: () => {

      }
    },
  ])



  //#######################################################################################################################################################//
  //////////////////////////////////////////////////////////////////////                     ///////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////    BOARDING FRENCH   ///////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////                     //////////////////////////////////////////////////////////////////////
  //#######################################################################################################################################################//


  boarding_fr.defineSteps([
    ///////////////////////////////// 1 - LAYERS //////////////////////////////////
    {
      element: '#layersCard',
      popover: {
        title: 'Couches (1/15)',
        description: 'Vous pouvez ici sélectionner et désélectionner les couches qui apparaissent sur la carte. Cliquez sur l`onglet <b>"Couches"</b> pour ouvrir les couches disponibles.',
        prefferedPosition: 'right',
        alignment: "center",
      },

      onHighlighted: (el) => {
        // disable clicking on collapse arrow
        $("#collapseCardsLeft").css('pointer-events', 'none');

        //console.log("STEP 1");
        // hide button
        //$('.boarding_fr-next-btn').hide();
        $('.boarding_fr-prev-btn').hide();
        // move to next step when card is clicked
        $("#layerLink").on("click", function () {
          // go only to next step if boarding_fr is already activated
          // otherwise boarding_fr will be activated out of nowhere if this button is clicked
          if (boarding_fr.isActivated) {
            setTimeout(function () {
              boarding_fr.next();
            }, 50);
          }
        });
      },

      onNext: (el) => {
        setTimeout(function () {
          $("#layers").collapse("show");
        }, 50);
      },
    },
    ////////////////////////////// 1.1 - LAYERS ////////////////////////////////////
    {
      element: '#layersCardContainer',
      popover: {
        title: 'Couches (2/15)',
        description: 'Vous pouvez ici sélectionner et désélectionner les couches qui apparaissent sur la carte et supprimer tous les indicateurs chargés.',
        prefferedPosition: 'right',
      },
      onHighlighted: (el) => {
        //console.log("STEP 2");
        $('.boarding_fr-prev-btn').hide();

        // disable clicking
        $("#layers").css('pointer-events', 'none');

      },
      // collapse layers card when going back
      onPrevious: () => {
        $("#layers").collapse('hide')
      },
      // collapse layers card when going to next step
      onNext: (el) => {
        setTimeout(function () {
          $("#layers").collapse('hide')
        }, 50);

        $("#layers").css('pointer-events', '');
        // enable clicking collapse arrow
        $("#collapseCardsLeft").css('pointer-events', '');
      }
    },
    ////////////////////////////// 2 - INDICATORS ////////////////////////////////////
    {
      element: '#filterCard',
      popover: {
        title: 'Choisir un indicateur  (3/15)',
        description: 'Vous pouvez y demander différents indicateurs climatiques et données de base. Cliquez sur <b>"Choisir Données"</b> pour ouvrir le menu.',
        prefferedPosition: 'right',
      },

      onHighlighted: (el) => {
        //console.log("STEP 3");
        // hide button
        $('.boarding_fr-prev-btn').hide();
        // go to next step when card is clicked
        $("#filterLink").on("click", function () {
          if (boarding_fr.isActivated) {
            setTimeout(function () {
              boarding_fr.next();
            }, 250);
          }
        });
      },
      onNext: (el) => {
        setTimeout(function () {
          $("#loadLayer").collapse("show");
        }, 50);
      }
    },
    ////////////////////////////// 2.1 - INDICATORS ////////////////////////////////////
    {
      element: '#filterCardContainer',
      popover: {
        title: 'Choisir un indicateur (4/15)',
        description: `Vous pouvez sélectionner n'importe quel indicateur en suivant les étapes suivantes: <br><b>
        1. Choisir un indicateur</b><br><b>
        2. Sélectionnez le type de données</b> que vous souhaitez analyser (Observation / Projection)<br><b>
        4. Choisir la date</b> (L'année associée aux données projetées n'indique pas un moment précis, mais représente plutôt une moyenne mobile sur 30 ans.). 
        <br><br>Chargez maintenant l'indicateur présélectionné en cliquant sur le bouton <b>"Afficher l'indicateur en carte"</b>.`,
        prefferedPosition: 'right',
      },
      onHighlighted: (el) => {
        // hide next button
        $('.boarding_fr-next-btn').hide();
        $('.boarding_fr-prev-btn').hide();

        // disable clicking on other parts of the card, so only "load layer" is clickable
        $("#loadLayerDisableIntro").css('pointer-events', 'none');
        // also disable clicking on card closing link so card cannot be closed
        $("#filterLink").css('pointer-events', 'none');


        // when clicking on "load indicator" button, test if loading was sucessful and then move next
        $("#loadLayerButton").on("click", function () {
          if (loadedSuccess) {
            if (boarding_fr.isActivated) {
              setTimeout(function () {
                boarding_fr.next();
              }, 250);
            }
          } else {

          }
        });
      },
      onPrevious: () => {
        $("#loadLayer").collapse('hide');
      },
      onNext: (el) => {
        $("#loadLayer").collapse('hide');
        // make clickable again
        $("#loadLayerDisableIntro").css('pointer-events', '');
        $("#filterLink").css('pointer-events', '');
      }
    },
    ////////////////////////////// 3 - LEGEND ////////////////////////////////////
    {
      element: '#legendCard',
      popover: {
        title: 'Afficher la légende (5/15)',
        description: 'Vous pouvez ici afficher la légende des couches visibles sur la carte. Cliquez sur <b>"Légende"</b> pour jeter un coup d`œil.',
        prefferedPosition: 'right'
      },
      onHighlighted: (el) => {
        // hide next button
        $('.boarding_fr-prev-btn').hide();
        // when clicking on "load indicator" button, test if loading was sucessful and then move next
        $("#legendLink").on("click", function () {
          if (boarding_fr.isActivated) {
            setTimeout(function () {
              boarding_fr.next();
            }, 250);
          }
        });
      },
      onNext: (el) => {
        setTimeout(function () {
          $("#properties").collapse("show");
        }, 50);
      },
    },
    ////////////////////////////// 3.1 - LEGEND ////////////////////////////////////
    {
      element: '#legendCardContainer',
      popover: {
        title: 'Afficher la légende (6/15)',
        description: 'Les légendes de toutes les couches actuellement chargées sont affichées ici.',
        prefferedPosition: 'right'
      },
      onHighlighted: (el) => {
        $('.boarding_fr-prev-btn').hide();
      },
      onNext: (el) => {
        //$("#properties").collapse('hide');
      }
    },
    ////////////////////////////// 4 - Analysis ////////////////////////////////////
    {
      element: '#analysisCard',
      popover: {
        title: 'Analyse des données (7/15)',
        description: 'Ici, vous pouvez analyser les données.',
        prefferedPosition: 'left'
      },
      onHighlighted: (el) => {
        $('.boarding_fr-prev-btn').hide();
        // disable clicking collapse arrow
        $("#collapseCardsRight").css('pointer-events', 'none');

        // move to next step when card is clicked
        $("#analysisLink").on("click", function () {
          // go only to next step if boarding_fr is already activated
          // otherwise boarding_fr will be activated out of nowhere if this button is clicked
          if (boarding_fr.isActivated) {
            setTimeout(function () {
              boarding_fr.next();
            }, 50);
          }
        });


      },
      onNext: (el) => {
        setTimeout(function () {
          $("#taskpane").collapse('show');
        }, 50);
      }
    },
    ////////////////////////////// 4.1 - Analysis ////////////////////////////////////
    {
      element: '#popupButton',
      popover: {
        title: 'Analyse des données - pixel unique (8/15)',
        description: 'En cliquant sur ce bouton, vous pourrez <b>investir des pixels individuels</b> de vos indicateurs sélectionnés sur la carte. Nous allons sauter cette étape pour l`instant, mais n`hésitez pas à essayer cette fonction plus tard.',
        prefferedPosition: 'left'
      },
      onHighlighted: (el) => {
        $('.boarding_fr-prev-btn').hide();
        $("#analysisDisableIntro").css("pointer-events", "none");
        $("#denyClickBoardingn").css("pointer-events", "none");
      },
      onNext: () => {
        $("#analysisDisableIntro").css("pointer-events", "");
        $("#denyClickBoarding").css("pointer-events", "");
      }
    },
    ////////////////////////////// 4.2 - Analysis ////////////////////////////////////
    {
      element: '#selectRegionButton',
      popover: {
        title: 'Analyse des données - Choisir une région (9/15)',
        description: 'Vous pouvez sélectionner n`importe laquelle des régions activées dans le menu <b>"Couches"</b>. Commencez par cliquer sur le bouton <b>"Choisir Région"</b>.',
        prefferedPosition: 'left'
      },
      onHighlighted: (el) => {
        $('.boarding_fr-prev-btn').hide();
        $('.boarding_fr-next-btn').hide();
        // disable clicking on anything than the "Select Region" Button
        $("#popupButton").css("pointer-events", "none");
        $("#timeseriesChartButton").css("pointer-events", "none");

        // move to next step when button is clicked
        $("#selectRegionButton").on("click", function () {
          if (boarding_fr.isActivated) {
            setTimeout(function () {
              boarding_fr.next();
            }, 50);
          }
        });
      },
      onNext: () => {
        $("#popupButton").css("pointer-events", "");
        $("#timeseriesChartButton").css("pointer-events", "");
      }
    },
    ////////////////////////////// 4.3 - Analysis ////////////////////////////////////
    {
      element: '#selectRegionModalContent',
      popover: {
        title: 'Analyse des données - Choisir Région (10/15)',
        description: 'Vous pouvez maintenant choisir le type de limites administratives que vous souhaitez sélectionner. Notez que seules les limites administratives dans le menu "Couches" (actuellement seulement "Pays") sont disponibles. Continuez en cliquant sur le bouton <b>"Sauver les modifications"</b>. ',
        prefferedPosition: 'left'
      },
      onHighlighted: (el) => {
        $('.boarding_fr-prev-btn').hide();
        $('.boarding_fr-next-btn').hide();

        // disable clicking on anything than "save changes" button
        $("#selectRegionModalBody").css("pointer-events", "none");
        $("#selectRegionModalHeader").css("pointer-events", "none");
        $("#selectRegionModalClose").css("pointer-events", "none");

        // move to next step when save button is clicked
        $("#modalRegionSaveButton").on("click", function () {
          if (boarding_fr.isActivated) {
            setTimeout(function () {
              boarding_fr.next();
            }, 50);
          }
        });
      },
      onNext: () => {
        // disable clicking on anything than "save changes" button
        $("#selectRegionModalBody").css("pointer-events", "");
        $("#selectRegionModalHeader").css("pointer-events", "");
        $("#selectRegionModalClose").css("pointer-events", "");
      }
    },
    ////////////////////////////// 4.3 - Analysis ////////////////////////////////////
    {
      element: '#map',
      popover: {
        title: 'Analyse des données - Choisir Région (11/15)',
        description: 'Cliquez sur un pays pour le sélectionner.',
      },
      onHighlighted: (el) => {
        $('.boarding_fr-prev-btn').hide();
        $('.boarding_fr-next-btn').hide();

        // when clicking on map, check if a single country was selected, 
        // if yes, move to next step
        $("#map").on("click", function () {
          if (boarding_fr.isActivated) {
            if (regionSelectedExport) {
              setTimeout(function () {
                boarding_fr.next();
              }, 1000);

            }

            // setTimeout(function () {
            //   boarding_fr.next();
            // }, 50);
          }
        })
      },
      onNext: () => {
      }
    },
    ////////////////////////////// 4.3 - Analysis ////////////////////////////////////
    {
      element: '#selectRegionButton',
      popover: {
        title: 'Analyse des données - Choisir Région (12/15)',
        description: 'Cliquez sur le bouton "Arrêter Sélection" pour terminer la sélection.',
      },
      onHighlighted: (el) => {
        $('.boarding_fr-prev-btn').hide();
        $('.boarding_fr-next-btn').hide();

        // disable clicking on anything than the "Stop Selecting" Button
        $("#popupButton").css("pointer-events", "none");
        $("#timeseriesChartButton").css("pointer-events", "none");

        // when clicking on map, check if a single country was selected, 
        // if yes, move to next step
        $("#selectRegionButton").on("click", function () {
          if (boarding_fr.isActivated) {
            setTimeout(function () {
              //boarding_fr.next();
            }, 50);
          }
        })
      },
      onNext: () => {
        $("#popupButton").css("pointer-events", "");
        $("#timeseriesChartButton").css("pointer-events", "");
      }
    },
    ////////////////////////////// 4.3 - Analysis ////////////////////////////////////
    {
      element: '#timeseriesChartButton',
      popover: {
        title: 'Analyse des données - Choisir Région (13/15)',
        description: 'Cliquez sur le bouton <b>"Courbe de Tendence"</b> pour continuer à créer un graphique. Notez que le calcul du graphique peut prendre quelques secondes. Pour obtenir un graphique d`un indicateur spécifique, vous devez le sélectionner dans le menu "Select Indicator" à gauche.',
      },
      onHighlighted: (el) => {
        $('.boarding_fr-prev-btn').hide();
        $('.boarding_fr-next-btn').hide();

        // disable clicking on anything than the "Create Timeseries Chart" Button
        $("#popupButton").css("pointer-events", "none");
        $("#selectRegionButton").css("pointer-events", "none");

        // when clicking on map, check if a single country was selected, 
        // if yes, move to next step
        $("#timeseriesChartButton").on("clicl", function () {
          if (boarding_fr.isActivated) {
            setTimeout(function () {
              boarding_fr.next();
            }, 50);
          }
        })
      },
      onNext: () => {
        $("#popupButton").css("pointer-events", "");
        $("#selectRegionButton").css("pointer-events", "");
      }
    },
    ////////////////////////////// 5 - Output ////////////////////////////////////
    {
      element: '#footerDiv',
      popover: {
        title: 'Voir le résultat (14/15)',
        description: 'Vous pouvez ici visualiser et télécharger les graphiques issus de votre analyse. Lors de la sélection des données projetées, les valeurs minimales et maximales de l`ensemble du modèle seront affichées à côté de la moyenne courante de la région.',
        prefferedPosition: 'top'
      },
      onHighlighted: () => {
        $('.boarding_fr-prev-btn').hide();

        // disable clicking
        $("#footerDiv").css("pointer-events", "none");
      },
      onNext: () => {
        $("#footerDiv").css("pointer-events", "");
      }
    },
    ////////////////////////////// 5 - Output ////////////////////////////////////
    {
      element: '#downloadChartButton',
      popover: {
        title: 'Voir le résultat 2 (15/15)',
        description: 'En cliquant sur le bouton de téléchargement en surbrillance, vous pourrez télécharger les données du graphique ainsi que le graphique en tant qu`image.',
        prefferedPosition: 'top'
      },
      onHighlighted: () => {
        $('.boarding_fr-prev-btn').hide();
        // disable clicking
        $("#footerDiv").css("pointer-events", "none");
        $("#myChart").css("pointer-events", "none");
        $("#downloadChartButton").css("pointer-events", "none");


      },
      onNext: () => {
        $("#footerDiv").css("pointer-events", "");
        $("#myChart").css("pointer-events", "");
        $("#downloadChartButton").css("pointer-events", "");
      }
    },
    ////////////////////////////// 5 - Output ////////////////////////////////////
    {
      element: '#map',
      popover: {
        title: 'Fin',
        description: 'Bonne chance pour essayer les différentes fonctionnalités et utiliser les données pour en savoir plus sur le changement climatique en Afrique de l`Ouest !',
        prefferedPosition: 'top'
      },
      onHighlighted: () => {
        $('.boarding_fr-prev-btn').hide();
      },
      onNext: () => {

      }
    },
  ])

  ///////////////////////////////////////////////// ACTIVATE AGAIN!!!!!!!!! ///////////////////////////////////////////
  // is this heeerreee?
  $('#introModal').modal('toggle');

  // if clicking on start introduction button
  $('#startTour').on("click", function (event) {

    // start boarding in english or french
    if (current_language == "en") {
      // 1 second delay
      setTimeout(function () {
        event.stopPropagation();
        // start boarding
        boarding.start();
      }, 500);

    } else if (current_language == "fr") {
      setTimeout(function () {
        event.stopPropagation();
        // start boarding
        boarding_fr.start();
      }, 500);
    }



  });





});
